import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { updatePasSecond } from '../../../redux/actions/actions'
import { ToastContainer } from "react-toastify"

const ConfirmChangePass = ({updatePasSecond, newPass, errors, data}) => {
    const {handleSubmit, register} = useForm()

    const onSubmit = data  => {
        const {code, emailCode} = data
    
        updatePasSecond(code, emailCode, newPass)
    }

    return(
        <form
        onSubmit={handleSubmit(onSubmit)}
        >
             <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="form-row">
                {data?.needPhoneConfirmation ?
                    <div className="form-group col-md-6 col-lg-5">
                        <label>Код из смс</label>
                        <input
                        required
                        {...register('code')} 
                        type="text" 
                        className="form-control" 
                        placeholder=""/>
                        <div className="text-danger small">
                        {errors?.code?.map(item => item)}
                        </div>
                    </div> : ''
                }
                 
                {data?.needEmailConfirmation  ?
                <div className="form-group col-md-6 col-lg-5">
                     <label>Код с почты</label>
                     <input
                     required
                      {...register('emailCode')} 
                     type="text" 
                     className="form-control" 
                     placeholder=""/>
                     <div className="text-danger small">
                        {errors?.emailCode?.map(item => item)}
                     </div>
                    
                 </div> : ''
                }

                
             </div> 
             <button 
                className="btn btn-primary"
            >Подтвердить</button>
        </form>
    )
}

const mapStateToProps = state => ({
    updatePasFirstData: state.user.updataPasFitst,
    newPass: state.user.newPass,
    errors: state.user.updatePasswordErrors,
    data: state.user.updatePasData
})


const mapDispatchToProps = dispatch => {
    return{
        updatePasSecond: (code, emailCode, password) => {
        dispatch(updatePasSecond(code, emailCode, password))
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmChangePass)