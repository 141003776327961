import React, { useContext, useEffect } from "react";


/// React router dom
import { BrowserRouter as Router} from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav"
import Footer from "./layouts/Footer"
/// Pages
import { ThemeContext } from "../context/ThemeContext"
import { useRoutes } from "../routes/routes"
import { connect } from "react-redux"
import { getProfile } from "../redux/actions/actions"


const Markup = ({tokken}) => {
  const { menuToggle } = useContext(ThemeContext);
  let path = window.location.pathname;
  path = path.split("/")
  path = path[path.length - 1]


  let pagePath = path.split("-").includes("page")

  return (
    <Router basename="/">
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {tokken && <Nav/>}
        <div className={`${tokken ? "content-body" : ""}`}>
          <div
            className={`${tokken ? "container-fluid" : ""}`}
            style={!tokken ? { minHeight: window.screen.height - 130 } :  { height: 'auto' }}
          >
           {useRoutes(tokken)}
          </div>
        </div>
        {tokken && <Footer />}
      </div>
    </Router>
  );
  
};

const mapStateToProps = state => ({
  tokken: localStorage.getItem('tokken'),
  loading: state.user.loading
})

const mapDispatchToProps = {
  getProfile: getProfile
}


export default connect(mapStateToProps, mapDispatchToProps)(Markup)