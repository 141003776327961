import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form'

// image
import logo from "../../images/rsi-imgs/logo.svg"
import { connect } from "react-redux"
import { signup } from "../../redux/actions/actions"
import ConfirmSignUp from "../components/ConfirmSignup/ConfirmSignup"
import Loader from "../components/Loader/Loader"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Register = ({signup, signupErrors, signupFirst, loading}) => {
  <ToastContainer
  position="top-right"
  autoClose={1500}
  hideProgressBar={true}
  newestOnTop
  closeOnClick
  rtl={false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
/>
  const {handleSubmit, register} = useForm()
  const [passwordError, setPasswordError] = useState('')

  const onSubmit = data => {
    if(data.password !== data.repeatPassword) {
      setPasswordError('Пароли должны совпадать')
    }

    if(data.password === data.repeatPassword) {
      signup(data.username, data.password, data.ref)
    }
    
  }



  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                        <img className="w-50" src={logo} alt="" />
                    </div>
                    {!signupFirst ? 
                       <form 
                       onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Номер телефона или почта<span className="text-danger">*</span></strong>
                          </label>
                          <input
                            required
                            {...register('username')}
                            type="text"
                            className="form-control"
                          />
                          <div className="text-danger small">
                            {signupErrors?.username_phone?.map(item => item)}
                            {signupErrors?.username?.map(item => item)}
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Реферальный код пригласителя</strong>
                          </label>
                          <input
                             {...register('email')}
                            type="text"
                            className="form-control"
                          />
                           <div className="text-danger small">
                            {signupErrors?.ref?.map(item => item)}
                           </div>
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Пароль<span className="text-danger">*</span></strong>
                          </label>
                          <input
                            required
                            {...register('password')}
                            type="password"
                            className="form-control"
                          />
                           <div className="text-danger small">
                            {signupErrors?.password?.map(item => item)}
                           </div>
                          <div className="text-danger small">{passwordError && passwordError}</div>
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Повторите пароль<span className="text-danger">*</span></strong>
                          </label>
                          <input
                            required
                           {...register('repeatPassword')}
                            type="password"
                            className="form-control"
                          />
                          <div className="text-danger small">
                            {signupErrors?.password?.map(item => item)}
                          </div>
                          <div className="text-danger small">{passwordError && passwordError}</div>
                        </div>
                        <div className="text-center mt-4">
                          {loading ? 
                            <Loader/> :
                            <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Зарегистрироваться
                          </button>
                          }
                        </div>
                      </form> : <ConfirmSignUp/>
                    }
                   
                    <div className="new-account mt-3">
                      <p className="">
                        У вас уже есть аккаунт?{" "}
                        <Link className="text-primary" to="/page-login">
                          Войти
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  signupErrors: state.user.signupErrors,
  signupFirst: state.user.signupFirst,
  loading: state.user.loading,
})

const mapDispatchToProps = dispatch => {
  return{
      signup: (username_phone, password, email, code, ref, emailCode) => {
          dispatch(signup(username_phone, password, email, code, ref, emailCode))
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
