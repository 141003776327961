import React from 'react'
import { Dropdown } from "react-bootstrap"
import { useForm } from 'react-hook-form'
import SvgIcon from '../SvgIcon/SvgIcon'

const StakingWalet = ({balance, name, address, wallets}) => {
    const {
        register,
        formState: {
            errors,
        },
        handleSubmit
    } = useForm()

    const onSubmit = data => {
        alert(JSON.stringify(data))
    }

    const handleClickDropItem = e => {
        if(e.target.textContent === 'ETH') {
            document.querySelector('.dot[value="0"]').click()
        }
        if(e.target.textContent === 'BTC') {
            document.querySelector('.dot[value="2"]').click()
        }
        if(e.target.textContent === 'USDR') {
            document.querySelector('.dot[value="3"]').click()
        }
        if(e.target.textContent === 'USDT') {
            document.querySelector('.dot[value="1"]').click()
        }
    }


    return(
        <div className='card p-4 position-relative'>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="position-absolute choice">Выбор кошелька</div>
            <div className="d-flex justify-content-center">
                <span className='mr-1'>{balance}</span>
                <span>{name}</span>
            </div>
            <div className="fz-28 d-flex justify-content-center pt-4 pb-3 mb-4">
            <Dropdown className="dropdown custom-dropdown d-block mb-0 w-50">
                                <Dropdown.Toggle
                                    variant=""
                                    as="div"
                                    className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                                    data-toggle="dropdown"
                                >
                                    <SvgIcon id={name}/>
                                    <div className="text-left ml-3">
                                    <span className="d-block fs-16 text-black">
                                        {name}
                                    </span>
                                    </div>
                                    <i className="fa fa-angle-down scale5 ml-auto" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    alignRight={true}
                                    className="dropdown-menu dropdown-menu-right"
                                >
                                    {wallets.map(item => {
                                        if(item.name !== name) {
                                            return(
                                                <div
                                                key={item.name}
                                                onClick={handleClickDropItem}
                                                type="button"
                                                className="dropdown-item">
                                                    {item.name}
                                                </div>
                                            )
                                        }
                                    })}
                                </Dropdown.Menu>
                        </Dropdown>
            </div>
            <div className="h4 ta-l">Срок</div>
            <div className="form-group position-relative">
                <Dropdown className="dropdown custom-dropdown d-block position-absolute dropdown-curency">
                    <Dropdown.Toggle
                        variant=""
                        as="div"
                        className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                        data-toggle="dropdown"
                    >
                        <div className="text-left ml-3">
                        <span className="d-block fs-16 text-black pr-10-px">
                            Месяца
                        </span>
                        </div>
                        <i className="fa fa-angle-down scale5 ml-auto" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        alignRight={true}
                        className="dropdown-menu dropdown-menu-right"
                        alignRight={true}
                    >
                        <div
                        type="button"
                        className="dropdown-item">
                        Года
                        </div>
                        <div 
                        type="button"
                        className="dropdown-item">
                        Дня
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                <input 
                 {...register('time', {
                    required: 'Поле обязательно к заполнению'
                })}
                type="number" 
                className="form-control h-50-px" />
            </div>
            <div className="error mb-4">
                {errors?.time && <p className='text-danger fz-12'>{errors?.time?.message || 'Ошибка'}</p>}
            </div>
            <div className="h4 ta-l">Сумма</div>
            <div className="form-group position-relative">
                <Dropdown className="dropdown custom-dropdown d-block position-absolute dropdown-curency">
                    <Dropdown.Toggle
                        variant=""
                        as="div"
                        className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                        data-toggle="dropdown"
                    >
                        <div className="text-left ml-3">
                        <span className="d-block fs-16 text-black pr-10-px">
                            Евро
                        </span>
                        </div>
                        <i className="fa fa-angle-down scale5 ml-auto" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        alignRight={true}
                        className="dropdown-menu dropdown-menu-right"
                    >
                        <div
                        type="button"
                        className="dropdown-item">
                        Рубли
                        </div>
                        <div 
                        type="button"
                        className="dropdown-item">
                        Долары
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                <input 
                 {...register('amount', {
                    required: 'Поле обязательно к заполнению'
                })}
                type="number" 
                className="form-control h-50-px" />
            </div>
            <div className="error">
                {errors?.amount && <p className='text-danger fz-12'>{errors?.amount?.message || 'Ошибка'}</p>}
            </div>
            <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary">Выполнить</button>
            </div>
            </form>
        </div>
    )
}

export default StakingWalet