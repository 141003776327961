import React from 'react'
import img1 from '../../../../images/card/1.png'
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Staking = () => {
    return(
        <p>Раздел в разработке</p>
        // <div className="row">
        //     <div className="col-lg-6 col-sm-12">
        //         <Card className="mb-3">
        //             <img
        //             className="card-img-top img-fluid"
        //             src={img1}
        //             alt=""
        //             />
        //             <Card.Header>
        //             <Card.Title>
        //                 <Link to='/' className='d-flex align-items-center card-link'>
        //                     <span className='mr-3 tt-init'>Стейкинг на остаток</span>
        //                     <span><svg className="arrow-right" version="1.1" viewBox="0 0 15.698 8.706"  xmlns="http://www.w3.org/2000/svg"><polygon points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353 "/></svg></span>
        //                 </Link>
        //             </Card.Title>
        //             </Card.Header>
        //             <Card.Body>
        //             <Card.Text>
        //                 This is a wider card with supporting text below as a natural
        //                 lead-in to additional content. This content is a little bit
        //                 longer.
        //             </Card.Text>
        //             <Card.Text className=" text-dark">
        //                 Last updated 3 mins ago
        //             </Card.Text>
        //             </Card.Body>
        //         </Card>
        //     </div>
        //     <div className="col-lg-6 col-sm-12">
        //         <Card className="mb-3">
        //             <img
        //             className="card-img-top img-fluid"
        //             src={img1}
        //             />
        //             <Card.Header>
        //             <Card.Title>
        //                  <Link to='staking-lok' className='d-flex align-items-center card-link'>
        //                     <span className='mr-3 tt-init'>Стейкинг локированный</span>
        //                     <span>
        //                     <svg className="arrow-right" version="1.1" viewBox="0 0 15.698 8.706" xmlns="http://www.w3.org/2000/svg"><polygon points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353 "/></svg>
        //                     </span>
        //                  </Link>
        //             </Card.Title>
        //             </Card.Header>
        //             <Card.Body>
        //             <Card.Text>
        //                 This is a wider card with supporting text below as a natural
        //                 lead-in to additional content. This content is a little bit
        //                 longer.
        //             </Card.Text>
        //             <Card.Text className=" text-dark">
        //                 Last updated 3 mins ago
        //             </Card.Text>
        //             </Card.Body>
        //         </Card>
        //     </div>
        // </div>
    )
}

export default Staking