export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_LOADER = 'LOGIN_LOADER'
export const PASSWORD_ERROR = 'PASSWORD_ERROR'
export const LOGOUT = 'LOGOUT'

export const LOGIN_NEED_CONFIRM = 'LOGIN_NEED_CONFIRM'

export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_LOAD = 'GET_PROFILE_LOAD'

export const GET_WALLETS = 'GET_WALLETS'
export const GET_WALLETS_LOAD = 'GET_WALLETS_LOAD'

export const SET_CURRENT_BALANCE = 'SET_CURRENT_BALANCE'

export const GET_HISTORY = 'GET_HISTORY'
export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR'
export const GET_HISTORY_LOAD = 'GET_HISTORY_LOAD'
export const GET_HISTORY_LAST_PAGE = 'GET_HISTORY_LAST_PAGE'
export const GET_HISTORY_SET_CURRENT_PAGE = 'GET_HISTORY_SET_CURRENT_PAGE'
export const GET_HISTORY_SET_ICON = 'GET_HISTORY_SET_ICON'

export const EXCHANGE = 'EXCHANGE'
export const EXCHANGE_LOAD = 'EXCHANGE_LOAD'

export const AUTH = 'AUTH'

export const UPDATE_PASSWORD_FIRST = 'UPDATE_PASSWORD_FIRST'
export const UPDATE_PASSWORD_SECOND = 'UPDATE_PASSWORD_SECOND'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const UPDATE_AVATAR = 'UPDATE_AVATAR'

export const UPDATE_SOCIAL_ERROR = 'UPDATE_SOCIAL_ERROR'

export const GET_HISTORY_SUPPORT = 'GET_HISTORY_SUPPORT'
export const GET_HISTORY_SUPPORT_LOAD = 'GET_HISTORY_SUPPORT_LOAD'
export const GET_HISTORY_SUPPORT_ERROR = 'GET_HISTORY_SUPPORT_ERROR'
export const GET_HISTORY_SUPPORT_LAST_PAGE = 'GET_HISTORY_SUPPORT_LAST_PAGE'
export const GET_HISTORY_SUPPORT_SET_CURRENT_PAGE = 'GET_HISTORY_SUPPORT_SET_CURRENT_PAGE'

export const CREATE_SUPPORT_MESSAGE = 'CREATE_SUPPORT_MESSAGE'

export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'

export const GET_COMMISSION_SUCCESS = 'GET_COMMISSION_SUCCESS'

export const SEND_SUCCESS = 'SEND_SUCCESS'
export const SEND_ERROR = 'SEND_ERROR'
export const SEND_ERROR_CLEAR = 'SEND_ERROR_CLEAR'
export const SEND_CONFIRM = 'SEND_CONFIRM'

export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SIGNUP_SUCCESS_FIRST = 'SIGNUP_SUCCESS_FIRST'
export const SIGNUP_SUCCESS_SECOND = 'SIGNUP_SUCCESS_SECOND'

export const GET_WALET = 'GET_WALET'
export const GET_WALET_LOAD = 'GET_WALET_LOAD'

export const OPEN_TOGGLE_MENU = 'OPEN_TOGGLE_MENU'

export const FORGOT_PASSWORD_FIRST = 'FORGOT_PASSWORD_FIRST'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_SECOND = 'FORGOT_PASSWORD_SECOND'

export const GET_CHANGE_REQUEST = 'GET_CHANGE_REQUEST'
export const GET_CHANGE_SUCCESS = 'GET_CHANGE_SUCCESS'
export const GET_CHANGE_ERROR = 'GET_CHANGE_ERROR'
export const CHANGE_BUY_CURRENCY = 'CHANGE_BUY_CURRENCY'
export const CHANGE_SOLD_CURRENCY = 'CHANGE_SOLD_CURRENCY'

export const GET_CHANGE_PRICE_REQUEST = 'GET_CHANGE_PRICE_REQUEST'
export const GET_CHANGE_PRICE_ERROR = 'GET_CHANGE_PRICE_ERROR'
export const GET_CHANGE_PRICE_SUCCESS = 'GET_CHANGE_PRICE_SUCCESS'

export const CHANGE_REVERSE = 'CHANGE_REVERSE'

export const EXCHANGE_RESULT = 'EXCHANGE_RESULT'
export const EXCHANGE_RESULT_CLEAR = 'EXCHANGE_RESULT_CLEAR'

export const CHANGE_REQUEST = 'CHANGE_REQUEST'
export const CHANGE_SUCCESS = 'CHANGE_SUCCESS'
export const CHANGE_ERROR = 'CHANGE_ERROR'

export const GET_FIAT_WALLETS_REQUEST = 'GET_FIAT_WALLETS_REQUEST'
export const GET_FIAT_WALLETS_SUCCESS = 'GET_FIAT_WALLETS_SUCCESS'
export const GET_FIAT_WALLETS_FAIL = 'GET_FIAT_WALLETS_FAIL'

export const TWO_FACTOR_AUTHTEFICATION = 'TWO_FACTOR_AUTHTEFICATION'

export const FIAT_INPUT_ERROR = 'FIAT_INPUT_ERROR'
export const FIAT_INPUT_SUCCESS = 'FIAT_INPUT_SUCCESS'
export const FIAT_INPUT_CLEAR_ERRORS = 'FIAT_INPUT_CLEAR_ERRORS'

export const FIAT_OUTPUT_ERROR = 'FIAT_OUTPUT_ERROR'
export const FIAT_OUTPUT_SUCCESS = 'FIAT_OUTPUT_SUCCESS'

export const GET_BANK_CARDS_LIST = 'GET_BANK_CARDS_LIST'
export const GET_BANK_CARDS_LIST_REQUEST = 'GET_BANK_CARDS_LIST_REQUEST'

export const GET_CARDS_REQUEST = 'GET_CARDS_REQUEST'
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS'

export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS'

export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD'

export const GET_HISTORY_APPLICATIONS_SUCCESS = 'GET_HISTORY_APPLICATIONS_SUCCESS'
export const GET_HISTORY_APPLICATIONS_REQUEST = 'GET_HISTORY_APPLICATIONS_REQUEST'
export const GET_HISTORY_APPLICATIONS_ERROR = 'GET_HISTORY_APPLICATIONS_ERROR'

export const SET_EXCHANGE_INPUT = 'SET_EXCHANGE_INPUT'

export const CLEAR_OUTPUT_ERRORS = 'CLEAR_OUTPUT_ERRORS'

export const CLEAR_EXCHANGE_RESULT = 'CLEAR_EXCHANGE_RESULT'

export const GET_TOTAL_BALANCE_REQEST = 'GET_TOTAL_BALANCE_REQEST'
export const GET_TOTAL_BALANCE_SUCCESS = 'GET_TOTAL_BALANCE_SUCCESS'
export const GET_TOTAL_BALANCE_FAIL = 'GET_TOTAL_BALANCE_FAIL'

export const GET_TOTAL_WALLETS_SUCCES = 'GET_TOTAL_WALLETS_SUCCES'
export const GET_TOTAL_WALLETS_REQUEST = 'GET_TOTAL_WALLETS_REQUEST'
// обмен
export const GET_SOLD_CURRENCIES_REQUEST = 'GET_SOLD_CURRENCIES_REQUEST'
export const GET_SOLD_CURRENCIES_SUCCESS = 'GET_SOLD_CURRENCIES_SUCCESS'

export const GET_BUY_CURRENCIES_REQUEST = 'GET_BUY_CURRENCIES_REQUEST'
export const GET_BUY_CURRENCIES_SUCCESS = 'GET_BUY_CURRENCIES_SUCCESS'

export const GET_CHANGE_PRICE_LOAD = 'GET_CHANGE_PRICE_LOAD'
export const GET_CHANGE_PRICE = 'GET_CHANGE_PRICE'

export const SET_SOLD_CURRENCY = 'SET_SOLD_CURRENCY'
export const SET_BUY_CURRENCY = 'SET_BUY_CURRENCY'

export const SET_SOLD_BALANCE = 'SET_SOLD_BALANCE'
export const SET_SOLD_INPUT_VALUE = 'SET_SOLD_INPUT_VALUE'
export const SET_CHANGE_AMOUNT = 'SET_CHANGE_AMOUNT'

export const CHANGE_CLEAR = 'CHANGE_CLEAR'
export const AMOUNT_CLEAR = 'AMOUNT_CLEAR'

export const SEND_CLEAR = 'SEND_CLEAR'

export const NEED_GA = 'NEED_GA'
export const GA_SUCCESS = 'GA_SUCCESS'
export const GA_REQUEST = 'GA_REQUEST'
export const GA_FAIL = 'GA_FAIL'

export const SET_ADDRESS = 'SET_ADDRESS'

export const CHANGE_FAIL_CLEAR = 'CHANGE_FAIL_CLEAR'

export const SET_OUTPUT_AMOUNT = 'SET_OUTPUT_AMOUNT'

export const SET_ACTIVE_CARD_EXCHANGER = 'SET_ACTIVE_CARD_EXCHANGER'
export const SET_ACTIVE_CARD_EXCHANGER_LOAD = 'SET_ACTIVE_CARD_EXCHANGER_LOAD'