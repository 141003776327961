import React, { useState } from "react"
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import Loader from '../components/Loader/Loader'
// image
import logo from "../../images/rsi-imgs/logo.svg"
import { forgotPassword } from "../../redux/actions/actions"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ForgotPasswordConfirm from '../components/ForgotPasswordConfirm/ForgotPasswordConfirm'

const ForgotPassword = ({forgotPassword, errors, forgotPasswordFirst, loading}) => {
  const {handleSubmit, register} = useForm()
  const [errorRepeat, setErrorRepeat] = useState(false)

  const onSubmit = data  => {
    const {username, password, passwordRepeat} = data

    if(password == passwordRepeat) {
      forgotPassword(username, password)
    } else {
      setErrorRepeat(true)
    }

    
  }

  return (
    <div className="authincation h-100 p-meddle">
          <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <img className="w-50" src={logo} alt="" />
                    </div>
                        <form
                        onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="form-group">
                            <label className="">
                              <strong>Email или телефон</strong>
                            </label>
                            <input
                              readOnly={forgotPasswordFirst}
                              required
                              {...register('username')}
                              type="text"
                              className="form-control"
                            />
                              <div className="text-danger small">
                              {errors?.email?.map(item => item)}
                              {errors?.phone?.map(item => item)}
                              </div>
                          </div>
                          <div className="form-group">
                            <label className="">
                              <strong>Новый пароль</strong>
                            </label>
                            <input
                              readOnly={forgotPasswordFirst}
                              required
                              {...register('password')}
                              type="password"
                              className="form-control"
                            />
                            <div className="text-danger small">
                                {errors?.password?.map(item => item)}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="">
                              <strong>Повторить новый пароль</strong>
                            </label>
                            <input
                              readOnly={forgotPasswordFirst}
                              required
                              {...register('passwordRepeat')}
                              type="password"
                              className="form-control"
                            />
                            <div className="text-danger small">
                                {errorRepeat ? 'Пароли не совпадают' : ''}
                            </div>
                          </div>
                          <div className="text-center">
                            {loading? <Loader/> : 
                              <button
                              disabled={forgotPasswordFirst}
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Отправить
                            </button>
                            }
                          </div>
                        </form> : ''
                    {forgotPasswordFirst ? 
                      <ForgotPasswordConfirm/> : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  errors: state.user.forgotPasswordErrors,
  forgotPasswordFirst: state.user.forgotPasswordFirst,
  loading: state.user.loading
})

const mapDispatchToProps = {
  forgotPassword: forgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)