import React, { useEffect, useState } from 'react'
import { Dropdown } from "react-bootstrap"
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getHistory, searchHistory } from '../../../../redux/actions/actions'
import HistoryList from '../../HistoryList/HistoryList'
import Loader from '../../Loader/Loader'
import SvgIcon from '../../SvgIcon/SvgIcon'
import { getUrlParams } from '../../../../utils/getUrlParams'
import { isEmpty } from '../../../../utils/isEmpty'
import { useHistory } from 'react-router-dom'
import { getQueryStringFromObject } from '../../../../utils/setUrlParams'
import { formatDate } from '../../../../utils/formatDate'

const HistoryPage = ( {historyLoad, history, getHistory, lastPage, currentPage, searchHistory} ) => {
    const [type, setType] = useState('')
    const [period, setPeriod] = useState('')
    const [viewCalendar, setViewCalendar] = useState(false)
    const [activeHistoryBtn, setActiveHistoryBtn] = useState('ETH')
    const historyRout = useHistory()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    

    const handleChangeSearch = e => {
        let params = getUrlParams()
        delete params.page
        params.comment = e.target.value
        searchHistory(params)
    }

    const getDataCustomPeriod = (startDate, endDate) => {
        let params = getUrlParams()
        params.periodStart = startDate
        params.periodEnd = endDate

        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }

    const onChange = (dates) => {
      const [start, end] = dates

      setStartDate(start)
      setEndDate(end)
      if(end != null) {
           setPeriod(`${new Date(start).toLocaleDateString()} - ${new Date(end).toLocaleDateString()}`)
           getDataCustomPeriod(new Date(start).toLocaleDateString(), new Date(end).toLocaleDateString())
      }
    }

    const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
    const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

    const locale = {
        localize: {
            day: n => days[n],
            month: n => months[n]
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    }
    
    const getDataPeriod = period => {
        const endDate = formatDate(new Date())

        const weekMilliseconds = period*24*60*60*1000
        let currentDate = new Date()
        currentDate.setTime(currentDate.getTime() - weekMilliseconds)
        const startDate = formatDate(currentDate)

        let params = getUrlParams()
        params.periodStart = startDate
        params.periodEnd = endDate

        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }

    const handleClick = e => {
        if(e.target.dataset.id === 'type') {
            setType(e.target.textContent)

            let params = getUrlParams()
            params.type = e.target.textContent === 'Поступление' ? 1 : 2
            getHistory(params)
            const search = getQueryStringFromObject(params)
            historyRout.replace({ search })
        }

        if(e.target.dataset.id === 'period') {
            setPeriod(e.target.textContent)

            if(e.target.textContent === 'Неделя') {
                getDataPeriod(7)
            }
            if(e.target.textContent === 'Месяц') {
                getDataPeriod(30)
            }
            if(e.target.textContent === 'Год') {
                getDataPeriod(365)
            }

        }
    }

    const handleClear = () => {
        setType('')
        let params = getUrlParams()
        delete params.type
        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }
    const handleClearDate = () => {
        setPeriod('')
        let params = getUrlParams()
        delete params.periodStart
        delete params.periodEnd
        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }


    document.querySelector('body').addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(document.querySelector('.react-datepicker'))
        const withinBoundaries2 = e.composedPath().includes(document.querySelector('.dropdown-item--period'))

        if(! withinBoundaries && !withinBoundaries2) {
            setViewCalendar(false)
        }
    })

    useEffect(() => {
        let params = getUrlParams()
        if(isEmpty(params)) {
            params = {name: 'ETH'}
        }
        setActiveHistoryBtn(params.name)
        getHistory(params)
    }, [])

    const hadnleClickInHistoryBtn = e => {
        setActiveHistoryBtn(e.target.textContent)
        setType('')
        setPeriod('')
        getHistory({name: e.target.textContent, limit: 10})
        const search = getQueryStringFromObject({name: e.target.textContent, limit: 10})
        historyRout.replace({ search })
    }

    const handleClickNextPage = e => {
        let params = getUrlParams()
        params.page = currentPage + 1
        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }

    const handleClickPrevPage = e => {
        let params = getUrlParams()
        params.page = currentPage - 1
        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }

    const handleClickPaginate = e => {
        let params = getUrlParams()
        params.page = Number(e.target.textContent)
        getHistory(params)
        const search = getQueryStringFromObject(params)
        historyRout.replace({ search })
    }

    let pages = []

    for(let i = 1; i <= lastPage; i++) {
        pages.push(i)
    }

    return(
        <div className="card p-4">
            <div className="row mb-4">
                <div className="col-lg-4 col-sm-12">
                    <div className="input-group filter-input-group position-relative">
                        <Dropdown className="input-group-prepend">
                        <Dropdown.Toggle
                            variant=""
                            className="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                        >
                            Тип операции
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                            onClick={handleClick}
                            data-id='type'
                            className="dropdown-item">
                            Поступление
                            </Dropdown.Item>
                            <Dropdown.Item
                            onClick={handleClick}
                            data-id='type'
                            className="dropdown-item">
                            Списание
                            </Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                        <input 
                        disabled
                        value={type} 
                        type="text" className="form-control" />
                        <div 
                        onClick={handleClear}
                        className={`close-filter ${!type ? 'd-none' : ''}`}>
                            <SvgIcon id='CLOSE' size='20'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <div className="input-group filter-input-group position-relative">
                        <Dropdown className="input-group-prepend">
                        <Dropdown.Toggle
                            variant=""
                            className="btn btn-primary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                        >
                            Период
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item
                            onClick={handleClick}
                            data-id='period' 
                            className="dropdown-item" to="#">
                            Неделя
                            </Dropdown.Item>
                            <Dropdown.Item
                            onClick={handleClick}
                            data-id='period' 
                            className="dropdown-item" to="#">
                            Месяц
                            </Dropdown.Item>
                            <Dropdown.Item
                            onClick={handleClick}
                            data-id='period' 
                            className="dropdown-item" to="#">
                            Год
                            </Dropdown.Item>
                            <div
                            role="separator"
                            className="dropdown-divider"
                            ></div>
                            <Dropdown.Item
                            onClick={() => setViewCalendar(true)}
                            className="dropdown-item dropdown-item--period" to="#">
                            За период
                            </Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                        <input 
                        disabled
                        value={period}
                        type="text"
                        className="form-control" />
                        <div 
                        onClick={handleClearDate}
                        className={`close-filter ${!period ? 'd-none' : ''}`}>
                            <SvgIcon id='CLOSE' size='20'/>
                        </div>
                        {viewCalendar && <DatePicker
                            locale={locale}
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            format='mm/dd/yyyy'
                        />}
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                <div className="form-group filter-input-group">
                    <input
                      onChange={handleChangeSearch}
                      type="text"
                      className="form-control input-default "
                      placeholder="Поиск"
                    />
                  </div>
                </div>
            </div>
            <div className="card-title mb-3">История операций</div>
            <div className="mt-2 mb-4 hisory-btns">
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'ETH' ? 'active' : ''}`}>ETH</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'BTC' ? 'active' : ''}`}>BTC</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'USDR' ? 'active' : ''}`}>USDR</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'USDT (ERC20)' || activeHistoryBtn === 'USDT+(ERC20)' ? 'active' : ''}`}>USDT (ERC20)</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'USDT (TRC20)' || activeHistoryBtn === 'USDT+(TRC20)' ? 'active' : ''}`}>USDT (TRC20)</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'FIAT RUB' || activeHistoryBtn === 'FIAT+RUB' ? 'active' : ''}`}>FIAT RUB</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary ${activeHistoryBtn === 'FIAT USD' || activeHistoryBtn === 'FIAT+USD' ? 'active' : ''}`}>FIAT USD</button>
                </div>
            {historyLoad ? <Loader/> : <HistoryList list={history} />}
            {lastPage > 1 ? 
                 <div className='pagination-nav d-flex justify-content-end'>
                     {currentPage !== 1 ? 
                        <span
                        onClick={handleClickPrevPage}
                        className='pagination-nav__circle'>
                            <i className="la la-angle-left" />
                        </span>
                        : ''
                    }
                    {pages.map((item, index) =>  <span 
                    key={index}
                    onClick={handleClickPaginate}
                    className={`${currentPage == item ? 'pagination-nav__circle--active' : ''} pagination-nav__circle`}>{item}</span>)}
                    
                    {currentPage === lastPage ?
                         ''
                        : <span
                        onClick={handleClickNextPage} 
                        className='pagination-nav__circle'>
                            <i className="la la-angle-right" />
                        </span>
                    }
                </div> : ''
            }
           
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        getHistory: name => {
          dispatch(getHistory(name))
        },
        searchHistory: (name, string) => {
            dispatch(searchHistory(name, string))
        }
      }
}


const mapStateToProps = state => ({
    history: state.history.data,
    historyLoad: state.history.loading,
    lastPage: state.history.lastPage,
    currentPage: state.history.currentPage,
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage)