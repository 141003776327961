import { GET_HISTORY_SUPPORT_SET_CURRENT_PAGE, GET_HISTORY_SUPPORT, GET_HISTORY_SUPPORT_ERROR, GET_HISTORY_SUPPORT_LAST_PAGE, GET_HISTORY_SUPPORT_LOAD } from "../types"

const initialState = {
    data: [],
    loading: false,
    error: '',
    lastPage: null,
    currentPage: null
}

export const supportReducer = (state = initialState, action) => {
    const {type, payload} = action

    switch(type) {
        case GET_HISTORY_SUPPORT_LOAD:
            return{
                ...state,
                loading: true
            }
        case GET_HISTORY_SUPPORT:
            return{
                ...state,
                loading: false,
               data: payload
            }
        case GET_HISTORY_SUPPORT_LAST_PAGE:
            return{
                ...state,
                lastPage: payload
            }
        case GET_HISTORY_SUPPORT_SET_CURRENT_PAGE:
            return{
                ...state,
                currentPage: payload
            }
        case GET_HISTORY_SUPPORT_ERROR:
            return{
                ...state,
                error: payload,
            }
        default: return state
    }
}