/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { OPEN_TOGGLE_MENU } from "../../../redux/types";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const dispatch = useDispatch()
  const {
    openMenuToggle,
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  const handleTouchMove = e => {
    document.getElementById('main-wrapper').classList.remove('menu-toggle')
    document.getElementsByClassName('hamburger')[0].classList.remove('is-active')
  }
  path = path.split("/");
  path = path[path.length - 1];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar 
      onTouchMove={handleTouchMove}
      className="deznav-scroll">
        <MM className="metismenu" id="menu">
        <li className={`${window.location.pathname === "/" ? "mm-active" : ""}`}>
            <Link className={`${window.location.pathname === "/" ? "mm-active" : ""}`} to="/">
              <i className="flaticon-381-home"></i>
              <span className="nav-text">Главная</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/exchange' ? "mm-active" : ""}`}>
            <Link to="exchange" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-repeat-1"></i>
              <span className="nav-text">Обмен</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/topup' ||  window.location.pathname === '/topup-card' ||  window.location.pathname === '/topup-cash' || window.location.pathname === '/topup-cashFIAT%20RUB' || window.location.pathname === '/topup-cashFIAT%20USD' || window.location.pathname === '/topup-cardFIAT%20RUB' || window.location.pathname === '/topup-cardFIAT%20USD' ? "mm-active" : ""}`}>
              <Link
                className={`${window.location.pathname === "/topup" || window.location.pathname === '/topup-card' ||  window.location.pathname === '/topup-cash' ? "mm-active" : ""}`}
                to="/topup"
              >
              <i className="flaticon-381-add-2"></i>
              <span className="nav-text">Пополнение</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/output' || window.location.pathname === '/get-cash' || window.location.pathname === "/output-crypto" || window.location.pathname === "/output-cryptoUSDT" || window.location.pathname === '/output-cryptoBTC' || window.location.pathname === '/output-cryptoUSDT%20(TRC20)' || window.location.pathname === '/output-cryptoUSDT%20(ERC20)' || window.location.pathname === '/output-cryptoETH' || window.location.pathname.includes('output') ? "mm-active" : ""}`}>
              <Link
                className={`${window.location.pathname === "/output" || window.location.pathname === '/get-cash' ? "mm-active" : ""}`}
                to="/output"
              >
              <i className="flaticon-381-exit-2"></i>
              <span className="nav-text">Вывод</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/transfer'  ? "mm-active" : ""}`}>
              <Link
                className={`${window.location.pathname === "/transfer" ? "mm-active" : ""}`}
                to="/transfer"
              >
              <i className="flaticon-381-exit-2"></i>
              <span className="nav-text">Перевод</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/address/history' ? "mm-active" : ""}`}>
              <Link
                className={`${window.location.pathname === "/address/history" ? "mm-active" : ""}`}
                to="/address/history?name=ETH"
              >
              <i className="flaticon-381-stopclock"></i>
              <span className="nav-text">История операций</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/staking' || window.location.pathname === '/staking-lok' ? "mm-active" : ""}`}>
              <Link
                className={`${window.location.pathname === '/staking' || window.location.pathname === '/staking-lok' ? "mm-active" : ""}`}
                to="/staking"
              >
              <i className="flaticon-381-windows-1"></i>
              <span className="nav-text">Стейкинг</span>
            </Link>
          </li>
          <li className={`${window.location.pathname === '/support' ? "mm-active" : ""}`}>
              <Link
                className={`${path === "/support" ? "mm-active" : ""}`}
                to="/support"
              >
              <i className="flaticon-381-user-8"></i>
              <span className="nav-text">Поддержка</span>
            </Link>
          </li>
        </MM>
           <span style={{display: "none"}} className="heart" />
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar