import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'

const VerifyAlert = ({status, statusText}) => {
    
        return(
            <div className="card">
                <div className="d-flex justify-content-between align-items-center p-4">
                    
                    {status == 5
                    ? <Fragment>
                        <div className="card-title pr-2 verification-tittle tt-init">{statusText}</div>
                        <Link className='btn btn-success'  to='verify'>Верифицировать</Link>
                        </Fragment>
                    : status == 0 || status == 2 || status == 1
                    ?   <Fragment>
                            <div className="card-title pr-2 verification-tittle tt-init">Пройдите верификацию</div>
                            <Link className='btn btn-success' to='verify'>Верифицировать</Link>
                        </Fragment>
                    : status == null ?
                        <Fragment>
                            <div className="card-title pr-2 verification-tittle tt-init">Пройдите верификацию</div>
                            <Link className='btn btn-success' to='verify'>Верифицировать</Link>
                        </Fragment>
                    : 
                        <Fragment>
                            <div className="card-title pr-2 verification-tittle tt-init">Загружены паспортные данные</div>
                        </Fragment>
                    }
                </div>
            </div>
        )
}



export default VerifyAlert