import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { forgotPasswordConfirm } from '../../../redux/actions/actions'
import Loader from '../Loader/Loader'

const ForgotPasswordConfirm = ({data, errors, forgotPasswordConfirm, password, username, loading}) => {
    const {handleSubmit, register} = useForm()
    const onSubmit = data  => {
        forgotPasswordConfirm(password, username, data.code, data.emailCode)
    }
    return (
        <form
        onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                {data?.needPhoneConfirmation ?
                    <div className="form-group col-md-12 col-lg-12 mb-3">
                        <label>Код из смс</label>
                        <input
                        required
                        {...register('code')} 
                        type="text" 
                        className="form-control" 
                        placeholder=""/>
                        <div className="text-danger small">
                        {errors?.code?.map(item => item)}
                        </div>
                    </div> : ''
                }
                 
                {data?.needEmailConfirmation  ?
                <div className="form-group col-md-12 col-lg-12">
                     <label>Код с почты</label>
                     <input
                     required
                      {...register('emailCode')} 
                     type="text" 
                     className="form-control" 
                     placeholder=""/>
                     <div className="text-danger small">
                        {errors?.emailCode?.map(item => item)}
                     </div>
                    
                 </div> : ''
                }

                
             </div>
             <div className="d-flex justify-content-between align-items-center">
                <button 
                    className="btn btn-primary"
                >Подтвердить</button>
               {loading ? <Loader/> : <Link to='/login'>Авторизоваться</Link>}
             </div>
        </form>
    )
}

const mapStateToProps = state => ({
    errors: state.user.forgotPasswordErrors,
    data: state.user.forgotPasswordData.data,
    password: state.user.forgotPasswordData.pas,
    username: state.user.forgotPasswordData.username,
    loading: state.user.loading,
})

const mapDispatchToProps = dispatch => {
    return{
        forgotPasswordConfirm: (password, username, code, emailCode) => {
            dispatch(forgotPasswordConfirm(password, username, code, emailCode))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordConfirm)