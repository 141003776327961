import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import OutputWalletItem from '../../OutputWalletItem/OutputWalletItem'
import {getWallets, getCards, setActiveCard, fiatOutput} from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import { Carousel } from 'react-responsive-carousel'
import { Dropdown } from "react-bootstrap"
import {useParams} from 'react-router-dom'
import Table from '../../Table/Table'
import SvgIcon from '../../SvgIcon/SvgIcon'
import { CLEAR_OUTPUT_ERRORS } from '../../../../redux/types'
import Modal from '../../Modal/Modal'

const OutputCard = ({wallets, loading, getWallets, cards, cardsLoading, getCards, activeCard, setActiveCard, fiatOutput, outputAmout}) => {
    let {id} = useParams()
    const [outputError, setOutputError] = useState('')
    const [isModal, setModal] = useState(false);
    const [currency, setCurrency] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        getWallets('FIAT RUB,FIAT USD')
        getCards({status: 2, sideFilter: 1})
        dispatch({type: CLEAR_OUTPUT_ERRORS})
    }, [])
    
    const handleSubmit = e => {
        e.preventDefault()
        if(!document.querySelector('li.selected .sendAmount').value || Number(document.querySelector('li.selected .sendAmount').value) <= 0) {
            setOutputError('Заполните поле корректно')
        }
        setCurrency(document.querySelector('li.selected .card').dataset.type == 'FIAT RUB' ? 'рублей' : 'долларов')
        setModal(true)
    }

    const handleSend = e => {
        e.preventDefault()
        
        const data = {
            amount: Number(document.querySelector('li.selected .sendAmount').value),
            currency: document.querySelector('li.selected .card').dataset.type == 'FIAT RUB' ? 103 : 104,
            method: 2,
            bankCardId: activeCard.id,
       }

       fiatOutput(data)
       setModal(false)
    }

    if(loading) {
        return <Loader/>
    }

    return(
        <Fragment>
             <Modal
            border={false}
            isVisible={isModal}
            title={`Вывод на карту ${activeCard.bankText} **** **** **** ${String(activeCard.number).substr(String(activeCard.number).length - 4, String(activeCard.number).length)} сумму в размере ${outputAmout} ${currency}.`}
            content={
                <form 
                className='d-flex align-items-center justify-content-between'
                onSubmit={handleSend}>
                    <div className="form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id="check1"
                        value=""
                        required
                        />
                        <label className="form-check-label" htmlFor="check1">Подтверждаю</label>
                    </div>
                    <button className="btn-primary btn">Создать заявку</button>
                </form>
            }
            onClose={() => setModal(false)}
            />
             <form onSubmit={handleSubmit} className='top-up-page'>
                <Carousel emulateTouch={true}>
                    {wallets.map(item => <OutputWalletItem error={outputError} key={item.name} wallets={wallets} balance={Number(item.balance).toFixed(2)} name={item.name} address={item.address} />)}
                </Carousel>
                    <div className="card p-4 mt-minus">
                        <div className="h4 ta-l">Выберите карту</div>
                        <div className="form-group">
                            {cardsLoading ? <Loader/> :
                                <Fragment>
                                    <Dropdown className="dropdown custom-dropdown d-block mb-0 w-100">
                                        <Dropdown.Toggle
                                            variant=""
                                            as="div"
                                            className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                                            data-toggle="dropdown"
                                        >
                                        {`${activeCard?.bankText} **** **** **** ${String(activeCard?.number).substr(String(activeCard.number).length - 4, String(activeCard.number).length)}`}
                                        <i className="fa fa-angle-down scale5 ml-auto" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            alignRight={true}
                                            className="dropdown-menu dropdown-menu-right"
                                        >
                                            {cards.map(card => {
                                                // if(!card.isGarantCard){
                                                    return(
                                                        <Dropdown.Item 
                                                        onClick={() => setActiveCard(card)}
                                                        key={card.id}
                                                        data-bank-id={card.id}>
                                                        {`${card.bankText} **** **** **** ${String(card.number).substr(String(card.number).length - 4, String(card.number).length)}`}
                                                        </Dropdown.Item>
                                                    )
                                                // }
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Fragment>
                            }
                            </div>
                            <div className="d-flex justify-content-between mt-2 align-items-center">
                                <div onClick={() => window.history.back()} className="back-btn">
                                    <SvgIcon size={30} id='BACK'/>
                                </div>
                                <button className="btn btn-primary">Вывести средства</button>
                            </div>
                    </div>
                    <Table type='2'/>
            </form>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    cardsLoading: state.wallets.cardsLoading,
    cards: state.wallets.cards,
    activeCard: state.wallets.activeCard,
    outputAmout: state.wallets.outputAmount
})

const mapDispatchToProps = dispatch => {
    return{
        getWallets: wallets => dispatch(getWallets(wallets)),
        getCards: (id, type) => dispatch(getCards(id, type)),
        setActiveCard: card => dispatch(setActiveCard(card)),
        fiatOutput: data => dispatch(fiatOutput(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutputCard)