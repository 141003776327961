import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import img from '../../../../icons/tinkoff.svg'
import SvgIcon from '../../SvgIcon/SvgIcon'
import {getWallets, getCards} from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'

const Output = ({wallets, loading, getWallets, cardsLoading, cards, getCards}) => {
    useEffect(() => {
        getWallets()
        getCards({status: 2, sideFilter: 1})
    }, [])

    return(
        <div className="card p-4">
            <h3 className='mb-4 text-black'>Выберите способ вывода</h3>
            <div className="text-black fz-22-px mb-3">Криптовалюта</div>
            <div className="row mb-4">
                {loading ? 
                <Loader/> :
                wallets.map(item => {
                            if(item.name != 'USDR') {
                                return(
                                    <Link key={item.name} to={`/output-crypto${item.name}`} className="col-lg-3 col-md-4 col-sm-6 pointer">
                                        <div className="card overflow-hidden pb-4 card-output">
                                            <div className="card-header align-items-start border-0 pb-0 justify-content-between">
                                                <div className="mr-auto">
                                                    <div className="text-black mb-0 font-w600">{item.name}</div>
                                                </div>
                                                <SvgIcon id={item.name}/>
                                            </div>
                                            <div className="output-balance">{item.name == 'ETH' || item.name == 'BTC' ? String(item.balance).slice(0, 12) : (parseInt((item.balance) * 100)) / 100}</div>
                                        </div>
                                    </Link>
                                )
                            }
                        }
                    )
                }
            </div>
            <div className="text-black fz-22-px mb-3">Банковский перевод</div>
            <div className="row mb-4">
                {cardsLoading ? <Loader/> :
                cards.length === 0 ? <div className='p-4'>У вас нет активных карт заведите карту в <Link to='profile' className='text-info'>профиле</Link></div> :
                    cards.map(card => 
                        <Link key={card.id} to={`output-card${card.id}`} className="col-lg-3 col-md-4 col-sm-6 pointer position-relative">
                            <div className="card overflow-hidden pb-4 card-output">
                                <div className="card-header align-items-start border-0 pb-0">
                                    <div className="mr-auto">
                                        <div className="text-black mb-0 font-w600">{card.bankText}</div>
                                    </div>
                                    {card.bank === 1 ? <SvgIcon id='SBER'/> :  <img className='w-70-px' src={img} alt="" />}
                                </div>
                                <span
                                className='card-numbers small'
                                >{`**** **** **** ${card.number.substr(card.number.length - 4, card.number.length)}`}</span>
                            </div>
                        </Link>
                    )
                }
            </div>

            <div className="text-black fz-22-px mb-3">Получение наличных</div>
            <div className="row mb-4">
                    <div className="col-lg-3 col-md-4 col-sm-6 pointer">
                        <Link to='/get-cash'>
                            <div className="card overflow-hidden pb-4 card-output">
                                <div className="card-header align-items-start border-0 pb-0">
                                    <div className="mr-auto">
                                        <div className="text-black mb-0 font-w600">Наличные</div>
                                       
                                    </div>
                                    <SvgIcon id='DOLLAR'/>
                                </div>
                            </div>
                        </Link>
                    </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    cardsLoading: state.wallets.cardsLoading,
    cards: state.wallets.cards,
})

const mapDispatchToProps = dispatch => {
    return{
        getWallets: () => dispatch(getWallets()),
        getCards: (id, type) => dispatch(getCards(id, type))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Output)