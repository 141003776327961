import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createCard, getBankCardsList, getCards } from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import InputMask from "react-input-mask"
import CardsList from '../../CardsList/CardsList'
import { notifyError } from '../../../../utils/notify'
import { useForm } from 'react-hook-form'
import Modal from '../../Modal/Modal'

const CardsPage = ({getBankCardsList, cards, cardsLoading, getCards, createCard}) => {
    const [card, setCard] = useState('')
    const [bank, setBank] = useState('')
    const [bankId, setBankId] = useState(1)
    const [fileName, setFileName] = useState('Файл')
    const [file, setFile] = useState()
    const [isModal, setModal] = useState(false);
    const {handleSubmit, register, reset } = useForm()
    useEffect(() => {
        getBankCardsList()
        getCards({sideFilter: 1})
    }, [])

    const handleChangeSelect = e => {
        setBank(e.target.value)
        e.target.querySelectorAll('option').forEach(option => {
            if(e.target.value == option.textContent) {
                setBankId(option.dataset.card)
            }
        })
    }

    const onSubmit = e => {
       if(e.bank != 0 && card.length > 15 && e.file.length) {
        const bankEmitent = e.bank === 'Сбербанк' ? 1 : e.bank === 'Тинькофф' ? 2 : ''
        createCard(bankEmitent, card, e.file)
        setCard('')
        setBank('')
        reset()
        setFileName('Файл')
        document.querySelector('.custom-file-input').classList.remove('file-input-done')
        return
       }

       notifyError('Не все поля заполнены')
    }

    const changeInput = e => {
        const type = e.target.files[0].type
        console.log(type, e.target)
        if(type === 'image/png' || type === 'image/jpeg') {
            e.target.classList.add('file-input-done')
            setFileName(e.target.files[0].name)
            
        } else {
            notifyError('Разрешенные форматы jpeg, jpg, png')
        }
      }

    return(
        <Fragment>
            <Modal
            isVisible={isModal}
            title="Пример фото для загрузки"
            content={<p>
                <img src="https://modelme.club/wp-content/uploads/2020/11/foto-modeli-s-pasportom.jpg" />
            </p>}
            onClose={() => setModal(false)}
            />
            <form id='card-create' onSubmit={handleSubmit(onSubmit)}>
                <div className="card p-4 mb-4">
                    <div className="card-title mb-3 tt-init">Создать карту</div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            {cardsLoading ? <Loader/> :
                            <Fragment>
                                <div className="text-black fz-22-px mb-2">Выбор банка</div>
                                <div className="form-group">
                                    <select 
                                    onChange={handleChangeSelect}
                                     {...register('bank')}
                                    className="form-control">
                                         <option 
                                            defaultValue={'Выбор'}
                                            value="0">Выбор</option>
                                    {Object.keys(cards).map(cityId => (
                                        <option data-card={cityId} key={cityId}>
                                            {`${cards[cityId]}`}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                            </Fragment>
                            }
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-black fz-22-px mb-2">Номер карты</div>
                            <div className="form-group">
                                <InputMask
                                value={card}
                                mask='9999 9999 9999 9999'
                                type="text"
                                className="form-control input-default "
                                placeholder=""
                                onChange={e => setCard(e.target.value)}
                                />
                            </div>
                        </div>
                      
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="text-black fz-22-px mb-2">Загрузить фото</div>
                            <div className="form-group">
                                <div className="custom-file support-file h-57">
                                    <input
                                    {...register('file')} 
                                    id='file'
                                    type="file" 
                                    onChange={changeInput}
                                    className="custom-file-input" />
                                    <label 
                                    htmlFor='file'
                                    className="custom-file-label support-label">{fileName.slice(0, 10)}...</label>
                                </div>
                            </div>
                            <span className='small text-promary file-info'>Загрузите фото с банковской картой</span>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-black fz-22-px mb-2">Фото карты</div>
                            <div 
                            onClick={() => {
                                console.log('ss')
                                setModal(true)
                            }}
                            className='color--blue'>Смотреть</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                        className='btn btn-primary'
                        >Создать</button>
                    </div>
                </div>
            </form>
            <div className="card p-4">
                <div className="card-title mb-3 tt-init">Ваши карты</div>
                <CardsList/>
            </div>
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => {
   return{
        getBankCardsList: () => dispatch(getBankCardsList()),
        getCards: (id, params) => dispatch(getCards(id, params)),
        createCard: (bank, number, file) => {
            dispatch(createCard(bank, number, file))
        }
   }
}

const mapStateToProps = state => ({
    cards: state.wallets.bankCardsList,
    cardsLoading: state.wallets.bankCardsListLoad
})

export default connect(mapStateToProps, mapDispatchToProps)(CardsPage)