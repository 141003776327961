import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StakingWalet from '../../StakingWalet/StakingWalet'
import {getWallets} from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import { Carousel } from 'react-responsive-carousel'

const StakingLok = ({wallets, loading, getWallets}) => {
    useEffect(() => {
        getWallets()
    }, [])
    
    if(loading) {
        return <Loader/>
    }

    return(
        <div className='top-up-page'>
           <Carousel>
               {wallets.map(item => <StakingWalet key={item.name} wallets={wallets} balance={item.balance} name={item.name} address={item.address} />)}
           </Carousel>
        </div>
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
})

const mapDispatchToProps = {
    getWallets
}

export default connect(mapStateToProps, mapDispatchToProps)(StakingLok)