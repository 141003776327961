import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { loginConfirm } from '../../../redux/actions/actions'

const GaConfirm = ({loginConfirm, gaFails}) => {
    const {handleSubmit, register} = useForm()

    const onSubmit = data  => {
        loginConfirm(data)
    }

    return(
        <form 
        className='mt-2'
        onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label className="mb-1 ">
                <strong>Введите код из Google Authenticator</strong>
                </label>
                <input
                type="text"
                id="username"
                className="form-control"
                {...register('gaCode')}
                required
                />
                {gaFails.length ? gaFails.map(item => <div className="text-danger small">{item}</div>) : ''}
            </div>
            <button 
                className="btn btn-primary"
            >Подтвердить</button>
        </form>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        loginConfirm: data => {
            dispatch(loginConfirm(data))
        }
      }
}

const mapStateToProps = state => ({
    gaFails: state.user.gaFails
})

export default connect(mapStateToProps, mapDispatchToProps)(GaConfirm)