import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {getWallets} from '../../../redux/actions/actions'
import CryptoWalletItem from '../CryptoWalletItem/CryptoWalletItem'
import Loader from '../Loader/Loader'


const CryptoWallet = ({getWallets, loading, wallets}) => {
    const [btn, setBtn] = useState('Показать все')

    useEffect(() => {
        getWallets()
    }, [])

    const handleClick = () => {
        if(btn === 'Скрыть') {
            setBtn('Показать все')
        }
        if(btn === 'Показать все') {
            setBtn('Скрыть')
        }
    }

    return(
        <div className="col-lg-4">
           <div>
                <div className="card overflow-hidden pb-4">
                    <div className="card-header border-0 pb-0 d-block">
                        <div className='d-flex mb-4 justify-content-between align-items-center'>
                            <div className='card-title tt-init'>Криптовалюта</div>
                        </div>
                        <div className={btn === 'Показать все' ? 'crypto-walets' : ''}>
                
                            {loading ? <Loader/> : wallets.map(item => <CryptoWalletItem key={item.name} wallet={item} />)}
                     
                        </div>
                       <div 
                       onClick={handleClick}
                       className="pt-1 small cursor-pointer text-center">{btn}</div>
                    </div>
                </div>
           </div>
        </div>
    )
}

const mapDispatchToProps = {
    getWallets
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
})

export default connect(mapStateToProps, mapDispatchToProps)(CryptoWallet)
