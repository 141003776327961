import React, { useEffect, useState } from 'react'
import { Dropdown } from "react-bootstrap"
import { connect, useDispatch } from 'react-redux'
import { SEND_ERROR_CLEAR, SET_OUTPUT_AMOUNT } from '../../../redux/types'

import SvgIcon from '../SvgIcon/SvgIcon'

const OutputWalletItem = ({balance, name, address, wallets, selected, commission, sendErrors, sendFirst, error, fiatOutputErrors}) => {
    const [amount, setAmount] = useState('')
    const dispatch = useDispatch()

    const handleClickDropItem = e => {
        wallets.forEach((item, index) => {
            if(e.target.textContent == item.name) {
                document.querySelector(`.dot[value="${index}"]`).click()
            }
        })
    }
    
    let timer = setTimeout(() => {
        wallets.forEach((item, index) => {
            if(item.name != 'ETH') {
                if(selected == item.name) {
                    document.querySelector(`.dot[value="${index}"]`).click()
                }
            }
        })
    }, 500)

    useEffect(() => {
        return () => {
            clearInterval(timer)
        }
    })

    const handleInputChange = e => {
        setAmount(e.target.value)
        dispatch({
            type: SEND_ERROR_CLEAR
        })
        dispatch({
            type: SET_OUTPUT_AMOUNT,
            payload: e.target.value
        })
    }

    
    return(
        <div data-type={name} className='card p-4 position-relative'>
            <div>
            <div className="position-absolute choice">Выбор кошелька</div>
            <div className="d-flex justify-content-center">
                <span className='mr-1'>{name == 'BTC' || name == 'ETH' ? balance : ((parseInt(Number(balance) * 100)) / 100)}</span>
                <span>{name}</span>
            </div>
            <div className="fz-28 d-flex justify-content-center pt-4 pb-3 mb-4">
                        <Dropdown className="dropdown custom-dropdown d-block mb-0 w-50">
                                <Dropdown.Toggle
                                    variant=""
                                    as="div"
                                    className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                                    data-toggle="dropdown"
                                >
                                    <SvgIcon id={name}/>
                                    <div className="text-left ml-3">
                                    <span className="d-block fs-16 text-black">
                                        {name}
                                    </span>
                                    </div>
                                    <i className="fa fa-angle-down scale5 ml-auto" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    alignRight={true}
                                    className="dropdown-menu dropdown-menu-right"
                                >
                                    {wallets.map(item => {
                                        if(item.name !== name) {
                                            return(
                                                <div
                                                key={item.name}
                                                onClick={handleClickDropItem}
                                                type="button"
                                                className="dropdown-item">
                                                    {item.name}
                                                </div>
                                            )
                                        }
                                    })}
                                </Dropdown.Menu>
                        </Dropdown>
            </div>
            <div className="h4 ta-l">Сумма</div>
            <div className="form-group position-relative">
                    <div className="dropdown custom-dropdown d-block position-absolute dropdown-curency">
                        <div
                            as="div"
                            className="btn  d-flex i-false align-items-center order-bg border-0 rounded p-7-px"
                        >
                            <div className="text-left ml-3 mr-2">
                                <span className="d-block fs-16 text-black">{name}</span>
                            </div>
                        </div>
                    </div>
                <input
                readOnly={sendFirst} 
                onChange={handleInputChange}
                value={amount}
                type="number" 
                className="form-control h-50-px sendAmount" />
                <div className="text-danger small ta-l">
                        {sendErrors?.amount?.map(item => item)}
                        {fiatOutputErrors?.amount?.map(item => item)}
                </div>
                <div className="d-flex justify-content-between">
                    <div
                    onClick={() => setAmount(balance)} 
                    className='ta-l cursor-pointer'>Макс.</div>
                </div>
                <div className="text-danger small ta-l">
                    {amount > Number(balance) ? 'Сумма отправки не может превышать баланс!' : ''}
                    {error ? error : ''}
                </div>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    commission: state.user.commission,
    sendErrors: state.user.sendErrors,
    sendFirst: state.user.sendFirst,
    fiatOutputErrors: state.wallets.fiatOutputErrors
})


export default connect(mapStateToProps)(OutputWalletItem)