import { Dropdown } from "react-bootstrap"
import React, { useEffect, useState } from 'react'
import {setSoldInputValue, change, getWallets, initChange, reverseChange, selectBuyCurrency, selectSoldCurrency} from '../../../../redux/actions/actions'
import { connect, useDispatch } from "react-redux"
import Loader from "../../Loader/Loader"
import SvgIcon from "../../SvgIcon/SvgIcon"
import { CHANGE_CLEAR, CHANGE_FAIL_CLEAR } from "../../../../redux/types"


const Exchange = ({
    soldInputValue, setSoldInputValue, changeAmount,
    soldBalance, selectBuyCurrency,reverseChange,
    buyCurrenciesLoading, changePriceLoad,
    initChange, soldCurrenciesLoading, soldCurrencies,
    loading, getWallets, selectSoldCurrency,
    changeСurrencies, buyCurrence, soldCurency, 
    changePrice,change, changeRequest, changeFail}) => {
    const [circleActive, setCircleActive] = useState(false)
    const dispatch = useDispatch()
    const handleCircleClick = () => {
        setCircleActive(!circleActive)
        reverseChange(soldCurency, buyCurrence)
    }

    useEffect(() => {
        getWallets('FIAT USD,FIAT RUB,USDT (ERC20),ETH,BTC,USDT (TRC20),USDR')
        initChange()
        dispatch({type: CHANGE_CLEAR})
        dispatch({type: CHANGE_FAIL_CLEAR})
    }, [])

    const handleClickMax = () => {
        setSoldInputValue(Number(document.querySelector('.sold-balance').textContent), changePrice)
    }

    const handleChangeInput = e => {
        setSoldInputValue(e.target.value, changePrice)
    }

    const handleSubmit = e => {
        e.preventDefault()
        change(soldCurency, buyCurrence, soldInputValue)
    }

    const handleClickSoldCurrency = e => {
        selectSoldCurrency(e.target.textContent, e.target.dataset.balance)
    }

    const handleClickBuyCurrency = e => {
        selectBuyCurrency(soldCurency, e.target.textContent)
    }

    if(loading) {
        return <Loader/>
    }

    return(
        <form onSubmit={handleSubmit}>
            <div className="card p-4">
                <div className="card-title mb-3">Обмен</div>
                <div className="echange d-flex justify-content-between row">
                    <div className="echange__left col-lg-5 col-sm-12">
                        {soldCurrenciesLoading ? <Loader/> :
                              <Dropdown className="dropdown custom-dropdown d-block">
                              <Dropdown.Toggle
                                  variant=""
                                  as="div"
                                  className="btn  i-false d-flex align-items-center border-0 order-bg rounded "
                                  data-toggle="dropdown"
                              >
                                 <SvgIcon id={soldCurency}/>
                                  <div className="text-left ml-3">
                                  <span className="d-block fs-16 text-black">
                                     {soldCurency}
                                  </span>
                                  </div>
                                  <i className="fa fa-angle-down scale5 ml-auto" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                  alignRight={true}
                                  className="dropdown-menu dropdown-menu-right"
                              >   {soldCurrencies.map(item => {
                                          return(
                                             <Dropdown.Item>
                                                  <div
                                                      data-balance={item.balance}
                                                      key={item.name}
                                                      onClick={handleClickSoldCurrency}
                                                      type="button"
                                                      className="dropdown-item">
                                                          {item.name}
                                                      </div>
                                             </Dropdown.Item>
                                          )
                                      
                                  })}
                                 
                              </Dropdown.Menu>
                          </Dropdown>
                        }
                        <div className="form-group">
                            <input
                            required
                            name='amount'
                            id="exchange-amount"
                            onChange={handleChangeInput}
                            value={soldInputValue}
                            type="number" className="form-control" />
                             <span className="small text-danger">
                                {changeFail?.amount?.map(item => item + ' ')}
                            </span>
                             <span className="small text-danger">
                                {changeFail?.source?.map(item => item + ' ')}
                            </span>
                        </div>
                        <div
                        className="d-flex justify-content-between"
                        >
                            <span
                            className="cursor-pointer"
                            onClick={handleClickMax}
                            >Макс.</span>
                            <span className="text-primary sold-balance">
                                {soldBalance}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-12 echange__middle">
                        <div className="d-flex justify-content-center">
                            <div onClick={handleCircleClick} className={`circle ${circleActive ? 'circle-transfotrm' : ''}`}>
                                <svg className="circle-svg" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.16667 14.6666L5.83333 14.6666L2.5 11.3333L3.44267 10.3906L5.83333 12.78L5.83333 1.33329L7.16667 1.33329L7.16667 14.6666ZM9.83333 1.33329L11.1667 1.33329L14.5 4.66663L13.5573 5.60929L11.1667 3.21862L11.1667 14.6666L9.83333 14.6666L9.83333 1.33329Z" fill="#F37032"></path></svg>
                            </div>
                        </div>
                    </div>
                    <div className="echange__right col-lg-5 col-sm-12">
                        {buyCurrenciesLoading ? <Loader/> :
                             <Dropdown className="dropdown custom-dropdown d-block">
                                <Dropdown.Toggle
                                    variant=""
                                    as="div"
                                    className="btn  d-flex i-false align-items-center order-bg border-0 rounded"
                                    data-toggle="dropdown"
                                >
                                    <SvgIcon id={buyCurrence}/>
                                    <div className="text-left ml-3">
                                    <span className="d-block fs-16 text-black">{buyCurrence}</span>
                                    </div>
                                    <i className="fa fa-angle-down scale5 ml-auto" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    alignRight={true}
                                    className="dropdown-menu dropdown-menu-right"
                                >
                                    {changeСurrencies.map(item => {
                                            return(
                                                <Dropdown.Item>
                                                    <div
                                                    key={item}
                                                    onClick={handleClickBuyCurrency}
                                                    type="button"
                                                    className="dropdown-item">
                                                        {item}
                                                    </div>
                                                </Dropdown.Item>
                                            )
                                        })}
                                </Dropdown.Menu>
                             </Dropdown>
                        }
                       
                        <div className="form-group">
                            <input value={changeAmount} disabled type="number" className="form-control" />
                            <span className="small text-danger">
                                {changeFail?.destination?.map(item => item)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card p-4">
                <div className="d-flex pb-2 exc-price align-items-center">
                    <div className="text-black mr-2">Цена</div>
                    {changePriceLoad ? <Loader/> : <div className="text-primary">{changePrice}</div>}
                </div>
                <div className="d-flex justify-content-between pt-4">
                    <div>
                        <span className="text-black mr-2">Вы получите</span>
                        <span className="text-primary">{changeAmount}</span>
                    </div>
                    <button 
                    disabled={changeRequest}
                    className="btn-primary btn">Обменять</button>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    loading: state.wallets.loading,
    changeСurrencies: state.change.buyCurrencies,
    buyCurrenciesLoading: state.change.buyCurrenciesLoading,
    buyCurrence: state.change.buyCurrency,
    soldCurency: state.change.soldCurrency,
    changePrice: state.change.changePrice,
    changeRequest: state.exchange.changeRequest,
    changeFail: state.exchange.changeFail,
    soldCurrencies: state.change.soldCurrencies,
    soldCurrenciesLoading: state.change.soldCurrenciesLoading,
    changePriceLoad: state.change.changePriceLoad,
    soldBalance: state.change.soldBalance,
    soldInputValue: state.change.soldInputValue,
    changeAmount: state.change.changeAmount,
})


const mapDispatchToProps = dispatch => {
    return{
        getWallets: wallets => {
            dispatch(getWallets(wallets))
        },
        change: (source, destination, amount) => {
            dispatch(change(source, destination, amount))
        },
        initChange: () => dispatch(initChange()),
        selectSoldCurrency: (currency, balance) => dispatch(selectSoldCurrency(currency, balance)),
        selectBuyCurrency: (soldCurrency, buyCurency) => dispatch(selectBuyCurrency(soldCurrency, buyCurency)),
        reverseChange: (soldCurrency, buyCurency) => dispatch(reverseChange(soldCurrency, buyCurency)),
        setSoldInputValue: (val, changePrice) => dispatch(setSoldInputValue(val, changePrice))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exchange)