import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Dropdown } from "react-bootstrap"
import SvgIcon from '../SvgIcon/SvgIcon'
import { notifySuccess } from '../../../utils/notify'
import { connect } from 'react-redux'
import { getCards } from '../../../redux/actions/actions'
import { useEffect } from 'react'


const WaletSlide = ({balance, name, address, wallets, cards, getCards}) => {
    useEffect(() => {
        getCards({status: 2, sideFilter: 1})
    },[])

    const handleClickCopy = e => {
        navigator.clipboard.writeText(document.querySelector('.selected .adress__symbols span').innerHTML)
        .then(() => {
            notifySuccess('Адрес скопирован')
        })
        .catch(err => {
            console.log('Something went wrong', err)
        })
    }

    const handleClickDropItem = e => {
        wallets.forEach((item, index) => {
            if(e.target.textContent == item.name) {
                document.querySelector(`.dot[value="${index}"]`).click()
            }
        })
    }

    return(
          <Fragment>
              <div className="card p-4">
              <div className="balance">
                    <div className="balance__top d-flex justify-content-center align-items-centr position-relative z-index-2">
                        <div className='d-flex align-items-center w-50'>
                        <span className='font-size-28 color-black mr-2'>Счет</span>
                        <Dropdown className="dropdown custom-dropdown d-block mb-0 w-100">
                                <Dropdown.Toggle
                                    variant=""
                                    as="div"
                                    className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                                    data-toggle="dropdown"
                                >
                                    <SvgIcon id={name}/>
                                    <div className="text-left ml-3">
                                    <span className="d-block fs-16 text-black">
                                        {name}
                                    </span>
                                    </div>
                                    <i className="fa fa-angle-down scale5 ml-auto" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    alignRight={true}
                                    className="dropdown-menu dropdown-menu-right"
                                >
                                    {wallets.map(item => {
                                        if(item.name !== name) {
                                            return(
                                                <div
                                                key={item.name}
                                                onClick={handleClickDropItem}
                                                type="button"
                                                className="dropdown-item">
                                                    {item.name}
                                                </div>
                                            )
                                        }
                                    })}
                                </Dropdown.Menu>
                        </Dropdown>
                        </div>
                    </div>

                    
                </div>
                <div className="wlaet-slide">
                    <div className="total-balance d-flex justify-content-center pt-5 pb-5">
                            <span className='mr-2 position-relative balance-numbers'>{name == 'BTC' || name == 'ETH' ? balance : ((parseInt(Number(balance) * 100)) / 100)}</span>
                            <span className='text-dark position-relative'>{name}</span>
                    </div>
                    <div className="row">
                        {name !== 'USDR' && name !== 'FIAT RUB' && name !== 'FIAT USD' ?
                        <div className="col-lg-12">
                            <div className="balance-card d-flex align-items-centr flex-column card p-3">
                             {name === 'USDT' ? 
                             <div className="balance-card__top mb-2">
                                 <div className="text-black fz-20 text-info">Убедитесь, что вы используете ERC-20 сеть</div>
                             </div> :
                             ''
                             }
                             <div className="balance-card__middle mb-2">
                                 <div className="text text-big">Адрес для пополнения</div>
                             </div>
                             <div onClick={handleClickCopy} className="balance-card__bottom">
                                 <div className="adress d-flex align-items-centr">
                                     <div className="adress__icon mr-2">
                                         <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path  d="M6 5.5C5.72386 5.5 5.5 5.72386 5.5 6V10.5C5.5 10.7761 5.72386 11 6 11H10.5C10.7761 11 11 10.7761 11 10.5V6C11 5.72386 10.7761 5.5 10.5 5.5H6ZM4.5 6C4.5 5.17157 5.17157 4.5 6 4.5H10.5C11.3284 4.5 12 5.17157 12 6V10.5C12 11.3284 11.3284 12 10.5 12H6C5.17157 12 4.5 11.3284 4.5 10.5V6Z" fill="currentColor"></path><path d="M2.5 2C2.36739 2 2.24021 2.05268 2.14645 2.14645C2.05268 2.24021 2 2.36739 2 2.5V7C2 7.13261 2.05268 7.25979 2.14645 7.35355C2.24021 7.44732 2.36739 7.5 2.5 7.5H3C3.27614 7.5 3.5 7.72386 3.5 8C3.5 8.27614 3.27614 8.5 3 8.5H2.5C2.10218 8.5 1.72064 8.34196 1.43934 8.06066C1.15804 7.77936 1 7.39782 1 7V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H7C7.39782 1 7.77936 1.15804 8.06066 1.43934C8.34196 1.72064 8.5 2.10218 8.5 2.5V3C8.5 3.27614 8.27614 3.5 8 3.5C7.72386 3.5 7.5 3.27614 7.5 3V2.5C7.5 2.36739 7.44732 2.24021 7.35355 2.14645C7.25979 2.05268 7.13261 2 7 2H2.5Z" fill="currentColor"></path></svg>
                                     </div>
                                     <div className="adress__symbols">
                                         <span>{address}</span>
                                     </div>
                                 </div>
                             </div>
                            </div>
                        </div> : 
                        ''
                        }
                        {name === 'USDR' || name === 'FIAT RUB' || name === 'FIAT USD' ?
                        <Fragment>
                        <div className="col-lg-6">
                            <Link to={`/topup-cash${name}`}>
                                <div className="card p-3 position-relative">
                                    <div className="card-title">Наличными</div>
                                    <div className="text">Покупка за наличные</div>
                                    <div className="buy-icon text-info">$</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-6">
                           {cards.length ?  <Link to={`/topup-card${name}`}>
                                <div className="card p-3 position-relative">
                                    <div className="card-title">С карты</div>
                                    <div className="text">Покупка с карты</div>
                                    <div className="buy-icon text-info">
                                        <i className="flaticon-381-id-card"></i>
                                    </div>
                                </div>
                            </Link> : <Fragment>
                                    <span>У вас нет активных карт </span>
                                    <Link class="color--blue" to='/profile#card-create'>активировать</Link>
                            </Fragment> }
                        </div>
                        </Fragment>
                         :
                        ''
                        }
                    </div>
                </div>
              </div>
          </Fragment>
    )
}

const mapStateToProps = state => ({
    cards: state.wallets.cards
})

const mapDispatchToProps = dispatch => {
    return{
        getCards: (id, type) => dispatch(getCards(id, type)),
    }
 }

export default connect(mapStateToProps, mapDispatchToProps)(WaletSlide)