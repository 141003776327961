import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { getWallets, getCardExchanger, fiatOutput } from '../../../../redux/actions/actions'
import { CLEAR_OUTPUT_ERRORS } from '../../../../redux/types'
import GetCashItem from '../../GetCashItem/GetCashItem'
import Loader from '../../Loader/Loader'
import SvgIcon from '../../SvgIcon/SvgIcon'
import Table from '../../Table/Table'
import Modal from '../../Modal/Modal'


const GetCash = ({ loading, wallets, getWallets, fiatOutputErrors, sourceBankCardId, activeCard, fiatOuput, getCardExchanger }) => {
    const [inputValue, setInputValue] = useState('')
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false);
    const [isModalCourier, setModalCourier] = useState(false);
    const [contact, setContact] = useState('');
    const [courier, setCourier] = useState('');
    const [exchangeCardId, setExchangeCardId] = useState('')
    const [currency, setCurrency] = useState('')

    useEffect(() => {
        getWallets('FIAT RUB,FIAT USD')
        getCardExchanger({ status: 2, sideFilter: 2 })
    }, [])

    const handleInput = e => {
        dispatch({ type: CLEAR_OUTPUT_ERRORS })
        setInputValue(e.target.value)
    }

    const handleClick = e => {
        e.preventDefault()
        setCurrency(document.querySelector('.slide.selected .total-balance .text-dark').textContent === 'FIAT RUB' ? 'рублей' : 'долларов')
        document.querySelector('.control-dots').style.zIndex = '0'
        sourceBankCardId.forEach(item => {
            if (item.bank === activeCard.bank) {
                setExchangeCardId(item.id)
            }
        })
        setModalCourier(true)
    }

    const handleSend = e => {
        e.preventDefault()

        const params = {
            amount: inputValue,
            currency: document.querySelector('.slide.selected .total-balance .text-dark').textContent === 'FIAT RUB' ? 103 : 104,
            method: 1,
            communicationMethod: contact,
            comment: courier,
        }
        fiatOuput(params)
        setContact('')
        setCourier('')
        setInputValue('')
        setModal(false)
    }

    return (
        <Fragment>
            <Modal
                border={false}
                isVisible={isModalCourier}
                title={`Информация`}
                content={
                    <>
                        <p className="form-group mb-3">Средства будут выданы только при предъявлении паспорта.
                            Если Вы желаете, чтобы средства получил другой человек, пожалуйста, укажите его ФИО в
                            поле ниже. Если Вы этого не сделаете, то иному лицу будет отказано в получении средств.</p>
                        <button
                            className="btn-primary btn"
                            onClick={() => {
                                setModalCourier(false)
                                setModal(true)
                            }}>Понятно</button>
                    </>
                }
                onClose={() => {
                    document.querySelector('.control-dots').style.zIndex = '2'
                    setModalCourier(false)
                    setModal(true)
                }}
            />
            <Modal
                border={false}
                isVisible={isModal}
                title={`Получение наличных ${inputValue} ${currency}`}
                content={
                    <form
                        onSubmit={handleSend}>
                        <div className="form-group mb-3">
                            <label>Опишите как с вами связаться</label>
                            <input
                                required
                                onChange={e => setContact(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={contact} />
                        </div>
                        <div className="form-group mb-3">
                            <label>Кто заберет</label>
                            <input
                                onChange={e => setCourier(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder="ФИО и паспортные данные"
                                value={courier} />
                        </div>
                        <div className="form-group mb-3">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="check1"
                                    value=""
                                    required
                                />
                                <label className="form-check-label" htmlFor="check1">Подтверждаю</label>
                            </div>
                        </div>
                        <button className="btn-primary btn">Создать заявку</button>
                    </form>
                }
                onClose={() => {
                    document.querySelector('.control-dots').style.zIndex = '1'
                    setModal(false)
                }}
            />
            <div className="get-cash-page position-relative">
                {loading ? '' : <div onClick={() => window.history.back()} className="back-btn back-btn--absolute">
                    <SvgIcon size={30} id='BACK' />
                </div>}
                {loading ? <Loader /> :
                    <Carousel emulateTouch={true}>
                        {wallets.map((item, index) =>
                            <div key={index} className="card p4">
                                <div className="total-balance d-flex justify-content-center pt-5 pb-5">
                                    <span className='mr-2 position-relative z-index-2 balance-numbers'>{Number(item.balance).toFixed(2)}</span>
                                    <span className='text-dark position-relative z-index-2'>{item.name}</span>
                                </div>
                                <form onSubmit={handleClick} className="card p-3 card-cash">
                                    <div className="form-group position-relative mb-3">
                                        <input
                                            required
                                            value={inputValue}
                                            onChange={handleInput}
                                            type="number"
                                            className="form-control h-50-px" />
                                        <div className='text-danger small'>
                                            {fiatOutputErrors?.amount?.map(item => item)}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div
                                            onClick={() => setInputValue(Number(item.balance).toFixed(3).replace(/.$/, ''))}
                                            className="text-black cursor-pointer">Макс.</div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="w-50 d-block">
                                            <button
                                                className="btn-primary btn w-100">Вывести средства</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </Carousel>
                }
            </div>
            <Table type='2' />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    fiatOutputErrors: state.wallets.fiatOutputErrors,
    sourceBankCardId: state.wallets.sourceBankCardId,
    activeCard: state.wallets.activeCard,
})

const mapDispatchToProps = dispatch => {
    return {
        getWallets: (wallets) => dispatch(getWallets(wallets)),
        fiatOuput: params => dispatch(fiatOutput(params)),
        getCardExchanger: params => dispatch(getCardExchanger(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetCash)