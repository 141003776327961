import React, { useState } from 'react'
import { connect } from 'react-redux'
import { updateProfile } from '../../../redux/actions/actions'
import InputMask from "react-input-mask"

const ProfileForm = ({user, status, updateProfile, errors}) => {
    const [lastName, setLastName] = useState(user.last_name)
    const [firstName, setFirstName] = useState(user.first_name)
    const [patronymic, setPatronymic] = useState(user.patronymic)
    const [birthdate, setBirthdate] = useState(user.birthdate)
   


    const onSubmit = e  => {
       e.preventDefault()
       updateProfile(lastName, firstName, patronymic, birthdate)
    }

    return(
        <form 
        className='mb-4'
        onSubmit={onSubmit}>
            <div className="form-row">
                <div className="form-group col-md-6 col-lg-3">
                    <label>Фамилия</label>
                    <input
                    onChange={e => setLastName(e.target.value)}
                    readOnly={status == 3 || status == 4 ? true : false}
                    value={lastName}
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                    <div className="text-danger small">{errors?.last_name?.map(item => item)}</div>
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Имя</label>
                    <input
                    onChange={e => setFirstName(e.target.value)}
                    readOnly={status == 3 || status == 4 ? true : false}
                    value={firstName} 
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                    <div className="text-danger small">{errors?.first_name?.map(item => item)}</div>
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Отчество</label>
                    <input
                     onChange={e => setPatronymic(e.target.value)}
                    readOnly={status == 3 || status == 4 ? true : false}
                     value={patronymic} 
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                    <div className="text-danger small">{errors?.patronymic?.map(item => item)}</div>
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Дата рождения</label>
                    <InputMask
                     readOnly={status == 3 || status == 4 ? true : false}
                    onChange={e => setBirthdate(e.target.value)}
                    value={birthdate}
                    className='form-control'
                    mask='99.99.9999'
                    />
                    <div className="text-danger small">{errors?.birthdate?.map(item => item)}</div>
                </div>
            </div>
            <button 
            disabled={status == 3 || status == 4 ? true : false}
            className="btn btn-primary">Сохранить</button>
        </form>
    )
}

const mapStateToProps = state => ({
    status: state.user.profile.verifyStatus,
    loading: state.user.loading,
    errors: state.user.updateProfileErrors
})

const mapDispatchToProps = dispatch => {
    return{
        updateProfile: (last_name, first_name, patronymic, birthdate) => {
        dispatch(updateProfile(last_name, first_name, patronymic, birthdate))
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)