import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {getWallets} from '../../../redux/actions/actions'
import CryptoWalletItem from '../CryptoWalletItem/CryptoWalletItem'
import Loader from '../Loader/Loader'


const FiatWallets = ({getWallets, loading, wallets}) => {
    useEffect(() => {
        getWallets('FIAT USD,FIAT RUB')
    }, [])

    return(
        <div className="col-lg-4">
            <div>
                <div className="card overflow-hidden pb-4 mh_210">
                    <div className="card-header border-0 pb-0 d-block">
                        <div className='d-flex mb-4 justify-content-between align-items-center'>
                            <div className='card-title tt-init'>Фиатные счета</div>
                        </div>
                        <div className="pr-4px">
                            {loading ? <Loader/> : wallets.map(item => <CryptoWalletItem key={item.name} wallet={item} />)}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        getWallets: names => {
            dispatch(getWallets(names))
        }
    }
}

const mapStateToProps = state => ({
    wallets: state.wallets.walletsFiat,
    loading: state.wallets.loadingFiat,
})

export default connect(mapStateToProps, mapDispatchToProps)(FiatWallets)
