import { CLEAR_OUTPUT_ERRORS, FIAT_INPUT_ERROR, FIAT_INPUT_SUCCESS, FIAT_OUTPUT_ERROR, FIAT_OUTPUT_SUCCESS, GET_BANK_CARDS_LIST, GET_BANK_CARDS_LIST_REQUEST, GET_CARDS_REQUEST, GET_CARDS_SUCCESS, GET_FIAT_WALLETS_FAIL, GET_FIAT_WALLETS_REQUEST, GET_FIAT_WALLETS_SUCCESS, GET_WALLETS, GET_WALLETS_LOAD, SET_ACTIVE_CARD, GET_TOTAL_WALLETS_SUCCES, GET_TOTAL_BALANCE_SUCCESS, GET_TOTAL_BALANCE_REQEST, GET_TOTAL_WALLETS_REQUEST, SET_ADDRESS, FIAT_INPUT_CLEAR_ERRORS, SET_OUTPUT_AMOUNT, SET_ACTIVE_CARD_EXCHANGER, SET_ACTIVE_CARD_EXCHANGER_LOAD } from "../types"

const inintialState = {
    wallets: [],
    loading: false,
    walletsFiat: [],
    loadingFiat: false,
    failsFiat: {},
    fiatInputErrors: {},
    fiatOutputErrors: {},
    bankCardsList: {},
    bankCardsListLoad: false,
    cards: [],
    cardsLoading: false,
    activeCard: 'Выберите карту',
    totalBalance: null,
    totalWallets: [],
    totalWalletsLoading: false,
    totalWalletsFail: '',
    toBack: null,
    address: '',
    outputAmount: 0,
    sourceBankCardId: [],
    sourceBankCardIdLoad: false
}

export const waletsReducer = (state = inintialState, action) => {
    const {type, payload} = action
    
    switch(type) {
        case SET_ADDRESS:
            return{
                ...state,
                address: payload
            }
        case GET_WALLETS_LOAD:
            return{
                ...state,
                loading: true
            }
        case GET_WALLETS:
            return{
                ...state,
                loading: false,
                wallets: payload
            }
        case GET_FIAT_WALLETS_REQUEST:
            return{
                ...state,
                loadingFiat: true,
            }
        case GET_FIAT_WALLETS_SUCCESS:
            return{
                ...state,
                loadingFiat: false,
                walletsFiat: payload,
                failsFiat: {}
            }
        case GET_FIAT_WALLETS_FAIL:
            return{
                ...state,
                loadingFiat: false,
                failsFiat: payload
            }
        case FIAT_INPUT_ERROR:
            return{
                ...state,
                fiatInputErrors: payload
            }
        case FIAT_INPUT_SUCCESS:
            return{
                ...state,
                fiatInputErrors: {}
            }
        case FIAT_OUTPUT_ERROR:
            return{
                ...state,
                fiatOutputErrors: payload
            }
        case FIAT_INPUT_CLEAR_ERRORS:
            return{
                ...state,
                fiatInputErrors: {}
            }
        case FIAT_OUTPUT_SUCCESS:
            return{
                ...state,
                fiatOutputErrors: {}
            }
        case GET_BANK_CARDS_LIST:
            return{
                ...state,
                bankCardsList: payload,
                bankCardsListLoad: false
            }
        case GET_BANK_CARDS_LIST_REQUEST:
            return{
                ...state,
                bankCardsListLoad: true
            }
        case GET_CARDS_REQUEST:
            return{
                ...state,
                cardsLoading: true,
            }
        case GET_CARDS_SUCCESS:
            return{
                ...state,
                cardsLoading: false,
                cards: payload
            }
        case SET_ACTIVE_CARD:
            return{
                ...state,
                activeCard: payload
            }
        case CLEAR_OUTPUT_ERRORS:
            return{
                ...state,
                fiatOutputErrors: {},
            }
        case GET_TOTAL_WALLETS_REQUEST:
            return{
                ...state,
                totalWalletsLoading: true,
            }
        case GET_TOTAL_WALLETS_SUCCES:
            return{
                ...state,
                totalWallets: payload,
                totalWalletsLoading: false,
            }
        case GET_TOTAL_BALANCE_SUCCESS:
            return{
                ...state,
                totalWalletsLoading: false,
                totalBalance: payload
            }
        case GET_TOTAL_BALANCE_REQEST:
            return{
                ...state,
                totalWalletsLoading: true,
            }
        case SET_OUTPUT_AMOUNT:
            return{
                ...state,
                outputAmount: payload
            }
        case SET_ACTIVE_CARD_EXCHANGER:
            return{
                ...state,
                sourceBankCardId: payload,
                sourceBankCardIdLoad: false
            }
        case SET_ACTIVE_CARD_EXCHANGER_LOAD:
            return{
                ...state,
                sourceBankCardIdLoad: true
            }
        default: return state
    }
}