import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
/// images
import darkLogo from "../../../images/rsi-imgs/logo.svg"
import whiteLogo from "../../../images/rsi-imgs/logo-white.svg"
import darkLogoSmall from "../../../images/rsi-imgs/rsi-black.svg"
import whiteLogoSmall from "../../../images/rsi-imgs/rsi-white.svg"

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  const windowWidth = window.screen.width

  if(windowWidth < 600) {
    return (
      
        <div className="nav-header">
          <Link to="/" className={`brand-logo`}>
            {background.value === "dark" || navigationHader !== "color_1" ? (
              <Fragment>
                <img className="logo-img-small" src={whiteLogoSmall} alt="Логотип RSI" />
                <img className="logo-img" src={whiteLogo} alt="Логотип RSI" />
              </Fragment>
            ) : (
              <Fragment>
                  <img className="logo-img-small" src={darkLogoSmall} alt="Логотип RSI" />
                  <img className="logo-img" src={darkLogo} alt="Логотип RSI" />
              </Fragment>
            )}
          </Link>
    
          <div
            className="nav-control"
            onClick={() => {
              setToggle(!toggle);
              openMenuToggle();
            }}
          >
            <div className={`hamburger`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </div>
    )
  } else {
    return (
      <div className="nav-header">
        <Link to="/" className={`brand-logo ${toggle ? 'toggle' : ''}`}>
          {background.value === "dark" || navigationHader !== "color_1" ? (
            <Fragment>
              <img className="logo-img-small" src={whiteLogoSmall} alt="Логотип RSI" />
              <img className="logo-img" src={whiteLogo} alt="Логотип RSI" />
            </Fragment>
          ) : (
            <Fragment>
                <img className="logo-img-small" src={darkLogoSmall} alt="Логотип RSI" />
                <img className="logo-img" src={darkLogo} alt="Логотип RSI" />
            </Fragment>
          )}
        </Link>
  
        <div
          className="nav-control"
          onClick={() => {
            setToggle(!toggle);
            openMenuToggle();
          }}
        >
          <div className={`hamburger ${toggle ? "is-active" : ""}`}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
    );
  }

 
};

export default NavHader;
