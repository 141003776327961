import React, { useState } from 'react'
import { notifyError } from '../../../../utils/notify'
import passport from '../../../../images/rsi-imgs/passport.jpg'
import { useForm } from 'react-hook-form'
import { uploadPassport } from '../../../../redux/actions/actions'
import { connect } from 'react-redux'


const VerifyPage = ({uploadPassport}) => {
    const {handleSubmit, register} = useForm()
    const [scanFirst, setScanFirst] = useState('Скан первой страницы')
    const [scanSecond, setScanSecond] = useState('Скан страницы прописки')
    const [scanSelfie, setScanSelfie] = useState('Селфи с расскрытым паспортом')
   
    const handleChange = e => {
        const type = e.target.files[0].type
        if(type === 'image/png' || type === 'image/jpeg') {
            e.target.classList.add('file-input-done')
            if(e.target.id == 'passport1') {
                setScanFirst(e.target.files[0].name.slice(0,10) + '...')
            }
            if(e.target.id == 'passport2') {
                setScanSecond(e.target.files[0].name.slice(0,10) + '...')
            }
            if(e.target.id == 'selfie') {
                setScanSelfie(e.target.files[0].name.slice(0,10) + '...')
            }
        } else {
            notifyError('Разрешенные форматы jpeg, jpg, png')
        }
    }

    const onSubmit = data => {
        uploadPassport(data)
    }

    return(
        <div className="card p-4">
            <div className="row">
                <div className="col-lg-6 col-md-12 verify-img-wrap">
                    <img className='w-100' src={passport} />
                </div>
                <div className="col-lg-6 col-md-12">
                    <form
                    onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="input-group mb-4">
                            <div className="custom-file">
                                <input 
                                 {...register('passport1')} 
                                required
                                id='passport1'
                                onChange={handleChange}
                                type="file" 
                                className="custom-file-input" />
                                <label htmlFor='passport1' className="custom-file-label label-passport">{scanFirst}</label>
                            </div>
                        </div>
                        <div className="input-group mb-4">
                            <div className="custom-file">
                                <input 
                                {...register('passport2')} 
                                required
                                id='passport2'
                                onChange={handleChange}
                                type="file" 
                                className="custom-file-input" />
                                <label 
                                htmlFor='passport2'
                                className="custom-file-label label-passport">{scanSecond}</label>
                            </div>
                        </div>
                        <div className="input-group mb-4">
                            <div className="custom-file">
                                <input 
                                {...register('selfie')} 
                                required
                                id='selfie'
                                onChange={handleChange}
                                type="file" 
                                className="custom-file-input" />
                                <label 
                                htmlFor='selfie'
                                className="custom-file-label label-passport">{scanSelfie}</label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button className='btn btn-primary small-btn p-2'>Отправить на проверку</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
      uploadPassport: data => {
        dispatch(uploadPassport(data))
      }
    }
}

export default connect(null, mapDispatchToProps)(VerifyPage)