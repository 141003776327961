import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { updatePasFirst} from '../../../redux/actions/actions'
import ConfirmChangePass from '../ConfirmChangePass/ConfirmChangePass'

const ChangePasswordForm = ({updatePasFirst, newPass, errors}) => {
    const {handleSubmit, register} = useForm()
    const [error, setError] = useState('')


    const onSubmit = data  => {
        if(data.password !== data.repeatPassword) {
            setError('Пароли должны совпадать!')
        }
        if(data.password === data.repeatPassword) {
            setError('')
            updatePasFirst(data.password)
        }
    }
    
        return(
            <Fragment>
                 {!newPass ? 
                    <form 
                    className='mb-4'
                    onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-md-6 col-lg-5">
                                <label>Новый пароль</label>
                                <input
                                required
                                {...register('password')} 
                                type="password" 
                                className="form-control" 
                                placeholder=""/>
                                <div className="text-danger small">
                                    {error && error}
                                    {errors?.password?.map(item => item)}
                                </div>
                            </div>
                            <div className="form-group col-md-6 col-lg-5">
                                <label>Подтверждение нового пароля</label>
                                <input
                                required
                                {...register('repeatPassword')} 
                                type="password" 
                                className="form-control" 
                                placeholder=""/>
                                <div className="text-danger small">
                                    {error && error}
                                    {errors?.password?.map(item => item)}
                                </div>
                            </div>
                        </div>
                        <button 
                                className="btn btn-primary">Отправить пин-код</button>
                    </form> : '' 
                }
                {newPass ? <ConfirmChangePass/> : ''}
            </Fragment>
           
        )
}


const mapStateToProps = state => ({
    newPass: state.user.newPass,
    errors: state.user.updatePasswordErrors
})

const mapDispatchToProps = {
    updatePasFirst: updatePasFirst,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)