import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getExchange } from '../../../redux/actions/actions'
import ExchangeItem from '../ExchangeItem/ExchangeItem'
import Loader from '../Loader/Loader'

const ExchangeList = ({getExchange, loading, exchangeList}) => {
    useEffect(() => {
        getExchange()
    }, [])

    return(
        <ul className="history__list">
            {loading ? <Loader/> : exchangeList.map((item, index) => <ExchangeItem key={index} rate={item.rate} pair={item.pair} />)}
        </ul>
    )
}

const mapStateToProps = state =>  ({
    loading: state.exchange.loading,
    exchangeList: state.exchange.data
})

const mapDispatchToProps = {
    getExchange: getExchange
}
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeList)