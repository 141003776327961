import { SET_CHANGE_AMOUNT, GET_BUY_CURRENCIES_REQUEST, GET_BUY_CURRENCIES_SUCCESS, GET_CHANGE_PRICE, GET_CHANGE_PRICE_LOAD, GET_SOLD_CURRENCIES_REQUEST, GET_SOLD_CURRENCIES_SUCCESS, SET_BUY_CURRENCY, SET_SOLD_BALANCE, SET_SOLD_CURRENCY, SET_SOLD_INPUT_VALUE, CHANGE_CLEAR, AMOUNT_CLEAR } from "../types"

const inintialState = {
    soldCurrencies: [],
    buyCurrencies: [],
    soldCurrenciesLoading: false,
    buyCurrenciesLoading: false,
    changePriceLoad: false,
    changePrice: null,
    soldCurrency: null,
    buyCurrency: null,
    soldBalance: '',
    changeAmount: '',
    soldInputValue: ''
}

export const changeReducer = (state = inintialState, action) => {
    const {type, payload} = action

    switch(type) {
        case GET_SOLD_CURRENCIES_REQUEST:
            return{
                ...state,
                soldCurrenciesLoading: true,
            }
        case GET_SOLD_CURRENCIES_SUCCESS:
            return{
                ...state,
                soldCurrenciesLoading: false,
                soldCurrencies: payload
            }
        case SET_SOLD_CURRENCY:
            return{
                ...state,
                soldCurrency: payload,
            }
        case GET_BUY_CURRENCIES_REQUEST:
            return{
                ...state,
                buyCurrenciesLoading: true,
            }
        case GET_BUY_CURRENCIES_SUCCESS:
            return{
                ...state,
                buyCurrenciesLoading: false,
                buyCurrencies: payload
            }
        case SET_BUY_CURRENCY:
            return{
                ...state,
                buyCurrency: payload
            }
        case GET_CHANGE_PRICE_LOAD:
            return{
                ...state,
                changePriceLoad: true,
            }
        case GET_CHANGE_PRICE:
            return{
                ...state,
                changePriceLoad: false,
                changePrice: payload
            }
        case SET_SOLD_BALANCE:
            return{
                ...state,
                soldBalance: payload
            }
        case SET_SOLD_INPUT_VALUE:
            return{
                ...state,
                soldInputValue: payload
            }
        case SET_CHANGE_AMOUNT:
            return{
                ...state,
                changeAmount: payload
            }
        case CHANGE_CLEAR:
            return{
                ...state,
                changeAmount: '',
                soldInputValue: ''
            }
        case AMOUNT_CLEAR:
            return{
                ...state,
                changeAmount: ''
            }
        default: return state
    }
}