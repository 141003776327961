import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { loginConfirm, updatePasSecond } from '../../../redux/actions/actions'
import { ToastContainer } from "react-toastify"

const LoginConfirm = ({loginNeedConfirm, loginNeedConfirmData, loginConfirm}) => {
    const {handleSubmit, register} = useForm()

    const onSubmit = data  => {
        loginConfirm(data)         
    }

    return(
        <form
        className='mt-2'
        onSubmit={handleSubmit(onSubmit)}
        >
             <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="form-row">
                {!loginNeedConfirmData?.phoneAccepted ?
                    <div className="form-group col-md-12 col-lg-12">
                        <label>Код из смс</label>
                        <input
                        required
                        {...register('code')} 
                        type="text" 
                        className="form-control" 
                        placeholder=""/>
                        <div className="text-danger small">
                        
                        </div>
                    </div> : ''
                }
                 
                {!loginNeedConfirmData?.emailAccepted  ?
                <div className="form-group col-md-12 col-lg-12">
                     <label>Код с почты</label>
                     <input
                     required
                      {...register('emailCode')} 
                     type="text" 
                     className="form-control" 
                     placeholder=""/>
                     <div className="text-danger small">
                    
                     </div>
                    
                 </div> : ''
                }

                
             </div> 
             <button 
                className="btn btn-primary"
            >Подтвердить</button>
        </form>
    )
}

const mapStateToProps = state => ({
    loginNeedConfirmData: state.user.loginNeedConfirmData,
})

const mapDispatchToProps = dispatch => {
    return{
        loginConfirm: data => {
            dispatch(loginConfirm(data))
        }
      }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginConfirm)