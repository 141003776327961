import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form'
// image
import logo from "../../images/rsi-imgs/logo.svg"
// actions
import { login } from "../../redux/actions/actions"
import { connect } from 'react-redux'
import Loader from "../components/Loader/Loader"
import LoginConfirm from "../components/LoginConfirm/LoginConfirm"
import GaConfirm from "../components/GaConfirm/GaConfirm"

const Login = ({login, loading,  loginNeedConfirm, needGa}) => {
  const {handleSubmit, register} = useForm()
  const passwordErrors = useSelector(state => state.user.passwordError)

  const onSubmit = data  => {
    const {username, password} = data

    login(username, password)
  }


  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <img className="w-50" src={logo} alt="" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Email или телефон</strong>
                        </label>
                        <input
                          readOnly={loginNeedConfirm || needGa}
                          type="text"
                          id="username"
                          className="form-control"
                          {...register('username')}
                        />
                        {passwordErrors.length ? passwordErrors.map(item => <span className="text-danger">{item}</span>) : ''}
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Пароль</strong>
                        </label>
                        <input
                          readOnly={loginNeedConfirm || needGa}
                          id="login-password"
                          type="password"
                          className="form-control"
                          {...register('password')}
                        />
                         {passwordErrors.length ? passwordErrors.map(item => <span className="text-danger">{item}</span>) : ''}
                      </div>
                      <div className="form-check mb-2">
                        <input
                          readOnly={loginNeedConfirm}
                          type="checkbox"
                          className="form-check-input"
                          id="check1"
                          value=""
                          required
                        />
                        <label className="form-check-label" htmlFor="check1">
                        Ознакомлен с <a className="text-info" target='_blank' href="https://new.rsi.capital/static/media/terms.59e729ef76701d3798c0.pdf" rel="noreferrer">пользовательским соглашением</a>
                        </label>
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <Link className="" to="/page-forgot-password">
                            Забыли пароль?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        {loading ?
                          <Loader/> 
                         :  <button
                         disabled={loginNeedConfirm || needGa}
                         type="submit"
                         className="btn btn-primary btn-block"
                         >
                         Вход
                         </button> 
                        }
                      </div>
                    </form>
                    {loginNeedConfirm ? <LoginConfirm/> : ''}
                    {needGa ? <GaConfirm/> : ''}
                    <div className="new-account mt-3">
                      <div className="">
                        Нет аккаунта?
                        <Link className="ml-2 text-primary" to="/page-register">
                          Зарегистрироваться
                        </Link>
                      </div>
                      <div className="">
                        Забыли пароль?
                        <Link className="ml-2 text-primary" to="/forgot-password">
                          Напомнить
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  login: login
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  loginNeedConfirm: state.user.loginNeedConfirm,
  needGa: state.user.loginNeedGa
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
