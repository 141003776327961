import React, {Fragment, useState} from 'react'
import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { fiatOutput, getCardExchanger } from '../../../redux/actions/actions'
import { CLEAR_OUTPUT_ERRORS } from '../../../redux/types'
import Modal from '../Modal/Modal'

const GetCashItem = ({balance, name, address, fiatOuput, fiatOutputErrors, getCardExchanger, sourceBankCardId, activeCard}) => {
    const [inputValue, setInputValue] = useState('')
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false);
    const [contact, setContact] = useState('');
    const [exchangeCardId, setExchangeCardId] = useState('')

    useEffect(() => {
        getCardExchanger({status: 2, sideFilter: 2})
    }, [])
    const handleInput = e => {
        dispatch({type: CLEAR_OUTPUT_ERRORS})
        setInputValue(e.target.value)
    }

    const handleClick = e => {
        e.preventDefault()
        document.querySelector('.control-dots').style.zIndex = '0'
        sourceBankCardId.forEach(item => {
            if(item.bank === activeCard.bank) {
                setExchangeCardId(item.id)
            }
        })
        setModal(true)
    }

    const handleSend = e => {
        e.preventDefault()

        const params = {
            amount: inputValue,
            currency: name === 'FIAT RUB' ? 103 : 104,
            method: 1,
            communicationMethod: contact
        }
        fiatOuput(params)
        setContact('')
        setInputValue('')
        setModal(false)
    }

    return(
        <Fragment>
             <Modal
                border={false}
                isVisible={isModal}
                title={`Получение наличных`}
                content={
                    <form 
                    onSubmit={handleSend}>
                        <div className="form-group mb-3">
                            <input 
                            required
                            onChange={e => setContact(e.target.value)}
                            type="text" 
                            className="form-control" 
                            placeholder="Telegramm или телефон"
                            value={contact}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-between form-group mb-3">
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="check1"
                                value=""
                                required
                                />
                                <label className="form-check-label" htmlFor="check1">Подтверждаю</label>
                            </div>
                            <button className="btn-primary btn">Создать заявку</button>
                        </div>
                    </form>
                }
                onClose={() => {
                    document.querySelector('.control-dots').style.zIndex = '1'
                    setModal(false)
                }}
            />
            <div className="card p4">
                <div className="total-balance d-flex justify-content-center pt-5 pb-5">
                    <span className='mr-2 position-relative z-index-2 balance-numbers'>{Number(balance).toFixed(2)}</span>
                    <span className='text-dark position-relative z-index-2'>{name}</span>
                </div>
                <form onSubmit={handleClick} className="card p-3 card-cash">
                    <div className="form-group position-relative mb-3">
                        <input 
                        required
                        value={inputValue}
                        onChange={handleInput}
                        type="number" 
                        className="form-control h-50-px" />
                        <div className='text-danger small'>
                            {fiatOutputErrors?.amount?.map(item => item)}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <div 
                        onClick={() => setInputValue(Number(balance).toFixed(3).replace(/.$/,''))}
                        className="text-black cursor-pointer">Макс.</div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="w-50 d-block">
                            <button 
                            className="btn-primary btn w-100">Вывести средства</button>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    fiatOutputErrors: state.wallets.fiatOutputErrors,
    sourceBankCardId: state.wallets.sourceBankCardId,
    activeCard: state.wallets.activeCard,
})

const mapDispatchToProps = dispatch => {
    return{
        fiatOuput: params => dispatch(fiatOutput(params)),
        getCardExchanger: params => dispatch(getCardExchanger(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetCashItem)