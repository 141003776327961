import React, { Fragment, useEffect, useState } from 'react'
import SvgIcon from '../../SvgIcon/SvgIcon'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {fiatInput} from '../../../../redux/actions/actions'
import {connect, useDispatch} from 'react-redux'
import Table from '../../Table/Table'
import { FIAT_INPUT_CLEAR_ERRORS } from '../../../../redux/types'
import Modal from '../../Modal/Modal'


const TopupCash = ({fiatInput, fiatInputErrors}) => {
    const [isModal, setModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [contact, setContact] = useState('');
    const dispatch = useDispatch()
    let {id} = useParams()
    useEffect(() => {
        console.log(`-${id}-`)
        localStorage.setItem('toBack', id);
        dispatch({type: FIAT_INPUT_CLEAR_ERRORS})
    }, [])


    const onSubmit = e => {
        e.preventDefault()

        setModal(true)
    }

    const handleSend = e => {
        e.preventDefault()

         const params = {
            amount: amount,
            currency: id == 'FIAT RUB' ? 103 : 104,
            method: 1,
            communicationMethod: contact
        }
        fiatInput(params)

        setModal(false)
        setAmount('')
        setContact('')
    }

    return(
        <Fragment>
            <Modal
                border={false}
                isVisible={isModal}
                title={`Пополнение наличными на сумму ${amount} ${id == 'FIAT RUB' ? 'рублей' : 'долларов'}`}
                content={
                    <form 
                    className=''
                    onSubmit={handleSend}>
                        <div className="form-group mb-3">
                            <label>Опишите как с вами связаться</label>
                            <input 
                            required
                            onChange={e => setContact(e.target.value)}
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            value={contact}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-between form-group mb-3">
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="check1"
                                value=""
                                required
                                />
                                <label className="form-check-label" htmlFor="check1">Подтверждаю</label>
                            </div>
                            <button className="btn-primary btn">Создать заявку</button>
                        </div>
                    </form>
                }
                onClose={() => setModal(false)}
            />
            <form
            onSubmit={onSubmit}
            className="card p-4">
                <div className="text-black fz-22-px mb-2">Сумма</div>
                <div className="form-group position-relative">
                    <div className="dropdown custom-dropdown d-block position-absolute dropdown-curency">
                        <div
                            as="div"
                            className="btn  d-flex i-false align-items-center order-bg border-0 rounded p-7-px"
                        >
                        </div>
                    </div>
                            <input 
                            onChange={e => setAmount(e.target.value)}
                            value={amount}
                            type="number" 
                            className="form-control h-50-px" />
                             <div className="error">
                                {fiatInputErrors?.amount?.map(error => <p className='text-danger fz-12'>{error}</p>)}
                            </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <div onClick={() => window.history.back()} className="back-btn">
                            <SvgIcon size={30} id='BACK'/>
                        </div>
                        <button className="btn-primary btn">Создать заявку</button>
                    </div>
            </form>
            <Table type='1' />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    fiatInputErrors: state.wallets.fiatInputErrors
})

const mapDispatchToProps = dispatch => {
    return{
        fiatInput: data => {
            dispatch(fiatInput(data))
        }
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopupCash)