import React from "react";
import ReactDOM from "react-dom";
import App from "./App";


import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import { Provider } from "react-redux";
import { store } from "./redux";

if(localStorage.getItem('theme') == undefined) {
  localStorage.setItem('theme', 'light')
}


ReactDOM.render(
  <Provider store={store}>
    <SimpleReactLightbox>
        <App />
    </SimpleReactLightbox>
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();