import React from 'react'
import HistoryItem from '../HisoryItem/HisoryItem'


const HistoryList = ({list}) => {
    return(
        <ul className="history__list">
            {list.length === 0 ? 'История операций пуста' : list.map(item =>  <HistoryItem key={item.id} data={item} />)}
        </ul>
    )
}

export default HistoryList