import React, { useState, useContext, useEffect } from "react"
import { ThemeContext } from "../../../context/ThemeContext"
import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import { connect } from "react-redux"
import { getProfile } from "../../../redux/actions/actions"
import avatar from '../../../images/rsi-imgs/ava.svg'
import SvgIcon from "../../components/SvgIcon/SvgIcon"


const Header = ({ profile, loading, getProfile }) => {
  const [dark, setDark] = useState(false)
  const {changeBackground} = useContext(ThemeContext)
  const theme = localStorage.getItem('theme')



  useEffect(() => {
    if(theme === 'dark') {
      setDark(true)
      changeBackground({ value: "dark", label: "Dark" });
    }

    if(theme === 'light') {
      setDark(false)
      changeBackground({ value: "light", label: "Light" });
    }
  }, [dark])

  useEffect(() => {
    getProfile()
  },[])

  const handleDarkMode = ({ target: { checked } }) => {
    setDark(checked)
    localStorage.setItem('theme', checked ? 'dark' : 'light')

    if(!dark) {
      changeBackground({ value: "dark", label: "Dark" });
    } else  {
      changeBackground({ value: "light", label: "Light" });
    }
  }

  const handleTouchMove = () => {
    document.getElementById('customSwitch11').click()
  }

  const handleLogOut = e => {
    e.preventDefault()

    localStorage.clear()

    window.location.reload()
  }

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  // console.log(finalName.join(" "));
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar tt-init"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Главная"
                  : finalName.join(" ") === "exchange"
                  ? "Обмен"
                  : finalName.join(" ") === "topup"
                  ? "Пополнение"
                  : window.location.pathname === "/topup-cash"
                  ? "Пополнение наличными"
                  : window.location.pathname === "/topup-card"
                  ? "Пополнение картой"
                  : window.location.pathname === "/output" || window.location.pathname.includes('output')
                  ? "Вывод"
                  : window.location.pathname === "/transfer" || window.location.pathname.includes('transfer')
                  ? "Перевод"
                  : window.location.pathname === "/get-cash"
                  ? "Получение наличных"
                  : window.location.pathname === "/address/history"
                  ? "История операций"
                  : window.location.pathname === "/staking"
                  ? "Стейкинг"
                  : window.location.pathname === "/staking-lok"
                  ? "Стейкинг локированный"
                  : window.location.pathname === "/ref-page"
                  ? "Реферальная программа"
                  : window.location.pathname === "/profile"
                  ? "Профиль"
                  : window.location.pathname === "/verify"
                  ? "Верификация личности"
                  : window.location.pathname === "/support"
                  ? "Поддержка"
                  : window.location.pathname === "/output-card"
                  ? "Вывод на карту"
                  : window.location.pathname === "/output-crypto" || window.location.pathname === "/output-cryptoUSDT" || window.location.pathname === '/output-cryptoBTC' || window.location.pathname === '/output-cryptoUSDT%20(TRC20)' || window.location.pathname === '/output-cryptoUSDT%20(ERC20)' || window.location.pathname === '/output-cryptoETH'
                  ? "Вывод на крипто кошелёк"
                  : window.location.pathname === '/topup-cashFIAT%20RUB' || window.location.pathname === '/topup-cashFIAT%20USD'
                  ? 'Пополнение наличными'
                  : window.location.pathname === '/topup-cardFIAT%20RUB' || window.location.pathname === '/topup-cardFIAT%20USD'
                  ? 'Пополнение с карты'
                  : finalName.join(" ")
                  }
              </div>
            </div>

           

            <ul className="navbar-nav header-right">
              <li className="nav-item">
              <div 
              className="custom-control custom-switch toggle-switch text-right mr-4 mb-2">
              <input
                checked={dark} 
                onChange={handleDarkMode}
                type="checkbox"
                className="custom-control-input"
                id="customSwitch11"
              />
              <label
                className="custom-control-label fs-14 text-black pr-2 label-header-btn"
                htmlFor="customSwitch11"
              >
              </label>
            </div>
              </li>
              <li className="nav-item">
                <div className="custom-control custom-switch toggle-switch text-right pr-0 mb-2">
                    <Link className="nav-link i-false c-pointer" to='/ref-page'>
                    <svg className="ref-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"><path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 000 7.071 4.983 4.983 0 003.535 1.462A4.982 4.982 0 0012 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 01-4.243 0 3.005 3.005 0 010-4.243l2.122-2.121z"></path><path d="M12 4.929l-.707.707 1.414 1.414.707-.707a3.007 3.007 0 014.243 0 3.005 3.005 0 010 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 000-7.071 5.006 5.006 0 00-7.071 0z"></path></svg>
                    </Link>
                </div>
              </li>
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  // href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info d-flex align-items-center">
                    <span 
                    style={{
                      backgroundImage: `url(${profile?.avatar == null ? avatar : profile.avatar})`,
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                    }}
                    className="avatar-pic">
                      {/* <img src={profile?.avatar == null ? avatar : profile.avatar} className="avatar-img" /> */}
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-2">
                  <Dropdown.Item>
                    <Link to="/profile" className="dropdown-item ai-icon">
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ml-2">Профиль</span>
                    </Link>
                  </Dropdown.Item>
                  {/* <Dropdown.Item>
                    <Link to="cards-page" className="dropdown-item ai-icon">
                        <SvgIcon id={'CARD'} size={20} color='#07bc0c' />
                        <span className="ml-2">Карты</span>
                    </Link>
                  </Dropdown.Item> */}
                  <Dropdown.Item>
                    <Link 
                    onClick={handleLogOut}
                    to="/page-login" className="dropdown-item ai-icon">
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Выйти</span>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  profile: state.user.profile,
  loading: state.user.loading
})

const mapDispatchToProps = {
  getProfile: getProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)