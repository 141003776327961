import React from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'

const ExchangeItem = ({pair, rate}) => {
    return(
    <li className="history__item mb-2">
        <div className="operation d-flex justify-content-between">
            <div className="operation__left d-flex">
                <div className="operation-title-block">
                    <div className="operation__time">{pair}</div>
                </div>
            </div>
            <div className="operation__right">
                <div className="operation-quality">{Number(rate).toFixed(2)}</div>
            </div>
        </div>
    </li>   
)}
export default ExchangeItem