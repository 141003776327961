import { GET_HISTORY, GET_HISTORY_APPLICATIONS_ERROR, GET_HISTORY_APPLICATIONS_REQUEST, GET_HISTORY_APPLICATIONS_SUCCESS, GET_HISTORY_ERROR, GET_HISTORY_LAST_PAGE, GET_HISTORY_LOAD, GET_HISTORY_SET_CURRENT_PAGE, GET_HISTORY_SET_ICON, GET_WALET_LOAD, SEARCH_HISTORY_SUCCESS } from "../types"

const inintialState = {
    data: [],
    loading: false,
    error: '',
    lastPage: '',
    currentPage: 1,
    icon: '',
    historyApplications: [],
    historyApplicationsLoad: false,
    historyApplicationsFails: {},
}

export const historyReducer = (state = inintialState, action) => {
    const {type, payload} = action

    switch(type) {
        case GET_HISTORY_LOAD:
            return{
                ...state,
                loading: true
            }
        case GET_HISTORY:
            return{
                ...state,
                loading: false,
                data: payload || [],
            }
        case GET_HISTORY_LAST_PAGE:
            return{
                ...state,
                loading: false,
                lastPage: payload
            }
        case GET_HISTORY_SET_CURRENT_PAGE:
            return{
                ...state,
                currentPage: payload
            }
        case GET_HISTORY_ERROR:
            return{
                ...state,
                loading: false,
                error: payload
            }
        case GET_HISTORY_SET_ICON:
            return{
                ...state,
                icon: payload
            }
        case GET_HISTORY_APPLICATIONS_REQUEST:
            return{
                ...state,
                historyApplicationsLoad: true
            }
        case GET_HISTORY_APPLICATIONS_SUCCESS:
            return{
                ...state,
                historyApplicationsLoad: false,
                historyApplications: payload
            }
        case GET_HISTORY_APPLICATIONS_ERROR:
            return{
                ...state,
                historyApplicationsLoad: false,
                historyApplicationsFails: payload
            }
        default: return state
    }
}