import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getApplication } from '../../../redux/actions/actions'
import Loader from '../Loader/Loader'

const Table = ({loading, data, errors, getApplication, type}) => {
    useEffect(() => {
        getApplication(type)
    }, [])

    if(loading) return <Loader/>

    return(
        <div className='card p-4 '>
            <div className="h4 ta-l">Список заявок</div>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Тип</th>
                        <th scope="col">Сумма</th>
                        <th scope="col">Описание</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Время</th>
                        <th scope="col">Кошелек</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => 
                         <tr key={item.id}>
                            <th scope="row">{item.id}</th>
                            <td>{item.typeText}</td>
                            <td>{item.currencyText != 'FIAT USD' && item.currencyText != 'FIAT RUB' ? item.amount : Number(item.amount).toFixed(2)}</td>
                            <td>{`${item.methodText} ${item.bankCard != undefined ? item.bankCard.bankText : ''} ${item.bankCard != undefined ? item.bankCard.number.substr(item.bankCard.number.length - 4, item.bankCard.number.length) : ''}`}</td>
                            <td>{item.statusText}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.currencyText}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.history.historyApplicationsLoad,
    data: state.history.historyApplications,
    errors: state.history.historyApplicationsFails
})

const mapDispatchToProps = dispatch => {
    return{
        getApplication: data => dispatch(getApplication(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)