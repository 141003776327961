import React, { Fragment, useState, useEffect } from 'react'
import SvgIcon from '../../SvgIcon/SvgIcon'
import { Dropdown } from "react-bootstrap"
import VerifyAlert from '../../VerifyAlert/VerifyAlert'
import CryptoWallet from '../../CryptoWallet/CryptoWallet'
import { connect } from 'react-redux'
import HistoryList from '../../HistoryList/HistoryList'
import { getHistory, getWallets, getWallet, getTotalBalance, getTotalWallets} from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import ExchangeList from '../../ExchangeList/ExchangeList'
import { Link } from 'react-router-dom'
import FiatWallets from '../../FiatWallets/FiatWallets'


const Home = ({getHistory, history, historyLoad, totalWallets, getTotalBalance,
    usdrBalance, userLoad, status, statusText, totalWalletsLoading, totalBalance,
    getWallet, wallet, walletLoad, wallets, walletsLoading, getTotalWallets}) => {
    const [activeHistoryBtn, setActiveHistoryBtn] = useState('ETH')
   

    const handleClick = e => {
        if(e.target.textContent == 'USD') {
            getWallet('USDR')
            getTotalBalance('USDR')
        } else  if(e.target.textContent == 'RUB') {
            getWallet('FIAT RUB')
            getTotalBalance('FIAT RUB')
        } else {
            getWallet(e.target.textContent)
            getTotalBalance(e.target.textContent)
        }
    }

    useEffect(() => {
        getHistory({name: 'ETH', limit: 10})
        getWallet('USDR')
        getTotalWallets('FIAT RUB,ETH,BTC,FIAT USD')
        getTotalBalance('USDR')
    }, [])

    const hadnleClickInHistoryBtn = e => {
        setActiveHistoryBtn(e.target.textContent)
        getHistory({name: e.target.textContent, limit: 10})
    }

    return(
        <Fragment>
            {status != 4 ? <VerifyAlert status={status} statusText={statusText} /> : ''}

            <div className="row">
                <div className="col-lg-4">
                    <div>
                        <div className="card pb-4 card-mobile">
                            <div className="card-header border-0 pb-0 d-block">
                                <div className='d-flex mb-4 justify-content-between align-items-center'>
                                    <div className='card-title tt-init'>Общий счет в</div>
                                    <Dropdown>
                                        <Dropdown.Toggle className='btn-main-balance'>
                                            {wallet.name == 'USDR' ? 'USD' : wallet.name == 'FIAT RUB' ? 'RUB' : wallet.name}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {totalWalletsLoading ? <Loader/> :
                                                totalWallets.map(item => {
                                                    return(
                                                        <Dropdown.Item
                                                        key={item.name}
                                                        onClick={handleClick}
                                                        >{item.name == 'FIAT USD' ? 'USD' : item.name == 'FIAT RUB' ? 'RUB' : item.name}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="pr-4px">
                                <div className='align-items-center  d-flex'>
                                   {totalWalletsLoading ? <Loader/> :
                                        <Fragment>
                                            <div className="mr-auto">
                                                <h2 className="card-title tt-init fz_14">
                                                    <span>{totalBalance}</span>
                                                </h2>
                                            </div>
                                            <SvgIcon id={wallet?.name == 'USDR' ? 'DOLLAR' : wallet?.name}/>
                                        </Fragment>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <FiatWallets/>
                <CryptoWallet/>
            </div>
            <div className="card p-4">
                <div className="row no-wrap">
                    <div className="col-lg-6 col-sm-6">
                        <div className="card-title mb-3 tt-init">История операций</div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end align-items-center history-more-link col-sm-6">
                        <Link to='/address/history?name=ETH' >подробнее</Link>
                    </div>
                </div>
               
                <div className="mt-2 mb-4 hisory-btns">
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'ETH' ? 'active' : ''}`}>ETH</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'BTC' ? 'active' : ''}`}>BTC</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary mr-2 ${activeHistoryBtn === 'USDR' ? 'active' : ''}`}>USDR</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary  mr-2 ${activeHistoryBtn === 'USDT (ERC20)' ? 'active' : ''}`}>USDT (ERC20)</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary  mr-2 ${activeHistoryBtn === 'USDT (TRC20)' ? 'active' : ''}`}>USDT (TRC20)</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary  mr-2 ${activeHistoryBtn === 'FIAT USD' ? 'active' : ''}`}>FIAT USD</button>
                    <button
                    onClick={hadnleClickInHistoryBtn} 
                    className={`btn btn-primary ${activeHistoryBtn === 'FIAT RUB' ? 'active' : ''}`}>FIAT RUB</button>
                </div>
                {historyLoad ? <Loader/> : <HistoryList list={history} />}
            </div>
            <div className="card p-4">
                <div className="card-title mb-3">Курсы валют</div>
                {/* <ExchangeList/> */}
            </div>
        </Fragment>
       
    )
}

const mapDispatchToProps = dispatch => {
    return{
        getHistory: name => {
          dispatch(getHistory(name))
        },
        getWallet: name => {
            dispatch(getWallet(name))
        },
        getTotalWallets: name => {
            dispatch(getTotalWallets(name))
        },
        getTotalBalance: currency => {
            dispatch(getTotalBalance(currency))
        }
      }
}


const mapStateToProps = state => ({
    history: state.history.data,
    historyLoad: state.history.loading,
    usdrBalance: state.user.profile.balance,
    userLoad: state.user.loading,
    status: state.user.profile.verifyStatus,
    statusText: state.user.profile.verifyStatusText,
    wallet: state.user.wallet,
    wallets: state.wallets.wallets,
    totalWalletsLoading: state.wallets.totalWalletsLoading,
    totalWallets: state.wallets.totalWallets,
    getTotalBalance: state.wallets.getTotalBalance,
    walletsLoading: state.wallets.loading,
    totalBalance: state.wallets.totalBalance,
    walletLoad: state.user.walletLoad,
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)