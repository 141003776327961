import { combineReducers } from "redux";
import { changeReducer } from "./changeReducer";
import { exchangeReducer } from "./exchangeReducer";
import { historyReducer } from "./historyReducer";
import { supportReducer } from "./supportReducer";
import { userReducer } from "./userReducers";
import { waletsReducer } from "./waletsReducer";

export const rootReducer = combineReducers({
    user: userReducer,
    wallets: waletsReducer,
    history: historyReducer,
    exchange: exchangeReducer,
    support: supportReducer,
    change: changeReducer,
})