import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { sendConfirm } from '../../../redux/actions/actions'


const ConfirmForm = ({data, sendConfirm}) => {
    const {handleSubmit, register} = useForm()

    const onSubmit = data  => {
        const {code, emailCode, gaCode} = data
        let name = document.querySelector('li.selected .card').dataset.type
        let amount = document.querySelector('li.selected .sendAmount').value
        let to = document.getElementById('toCryptoAddress').value

        console.log(name, amount, to, emailCode, code)

        sendConfirm(name, amount, to, emailCode, code, gaCode)
    }

    return(
        <form
        className='mt-2'
        onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                 {data.smsCode ?
                    <div className="form-group col-md-6 col-lg-6">
                        <label>Код из смс</label>
                        <input
                        required
                        {...register('code')} 
                        type="text" 
                        className="form-control" 
                        placeholder=""/>
                    </div> :
                    '' }
                 {data.emailCode ? 
                    <div className="form-group col-md-6 col-lg-6">
                        <label>Код с почты</label>
                        <input
                        required
                        {...register('emailCode')} 
                        type="text" 
                        className="form-control" 
                        placeholder=""/>
                    </div> :
                    ''}
                 {data.gaCode ? 
                    <div className="form-group col-md-6 col-lg-6">
                        <label>Код с google authenticator</label>
                        <input
                        required
                        {...register('gaCode')} 
                        type="text" 
                        className="form-control" 
                        placeholder=""/>
                    </div> :
                    ''}
             </div> 
             <div className="d-flex justify-content-end">
                <button 
                    className="btn btn-primary"
                >Подтвердить</button>
             </div>
        </form>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        sendConfirm: (name, amount, to, emailCode, smsCode, gaCode) => {
            dispatch(sendConfirm(name, amount, to, emailCode, smsCode, gaCode))
        }
    }
}

export default connect(null, mapDispatchToProps)(ConfirmForm)