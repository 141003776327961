import {EXCHANGE_LOAD, EXCHANGE, GET_CHANGE_REQUEST, GET_CHANGE_ERROR, GET_CHANGE_SUCCESS, CHANGE_BUY_CURRENCY, CHANGE_SOLD_CURRENCY, GET_CHANGE_PRICE_ERROR, GET_CHANGE_PRICE_REQUEST, GET_CHANGE_PRICE_SUCCESS, CHANGE_REVERSE, EXCHANGE_RESULT, EXCHANGE_RESULT_CLEAR, CHANGE_REQUEST, CHANGE_SUCCESS, CHANGE_ERROR, SET_EXCHANGE_INPUT, CLEAR_EXCHANGE_RESULT, CHANGE_FAIL_CLEAR} from '../types'

const inintialState = {
    loading: false,
    data: [],
    soldValue: 0,
    changeСurrencies: [],
    changeLoad: false,
    changeError: '',
    buyCurrence: '',
    soldCurency: 'ETH',
    changePrice: null,
    changePriceLoad: false,
    changePriceError: '',
    exchangeResult: '',
    changeRequest: false,
    changeFail: {},
    exchangeInput: ''
}

export const exchangeReducer = (state = inintialState, action) => {
    const {type, payload} = action

    switch(type) {
        case EXCHANGE_LOAD:
            return{
                ...state,
                loading: true
            }
        case EXCHANGE:
            return{
                ...state,
                loading: false,
                data: payload
            }
        case GET_CHANGE_REQUEST:
            return{
                ...state,
                changeLoad: true
            }
        case GET_CHANGE_ERROR:
            return{
                ...state,
                changeLoad: false,
                changeError: payload
            }
        case GET_CHANGE_SUCCESS:
            return{
                ...state,
                changeLoad: false,
                changeError: '',
                changeСurrencies: payload,
                buyCurrence: payload[0]
            }
        case CHANGE_BUY_CURRENCY:
            return{
                ...state,
                buyCurrence: payload
            }
        case CHANGE_SOLD_CURRENCY:
            return{
                ...state,
                soldCurency: payload
            }
        case GET_CHANGE_PRICE_ERROR:
            return{
                ...state,
                changePriceLoad: false,
                changePriceError: payload,
            }
        case GET_CHANGE_PRICE_REQUEST:
            return{
                ...state,
                changePriceLoad: true,
                changePriceError: '',
            }
        case GET_CHANGE_PRICE_SUCCESS:
            return{
                ...state,
                changePriceLoad: false,
                changePriceError: '',
                changePrice: payload
            }
        case CHANGE_REVERSE:
            return{
                ...state,
                buyCurrence: payload.soldCurrency,
                soldCurency: payload.buyCurency
            }
        case EXCHANGE_RESULT:
            return{
                ...state,
                exchangeResult: payload
            }
        case EXCHANGE_RESULT_CLEAR:
            return{
                ...state,
                exchangeResult: ''
            }
        case CHANGE_REQUEST:
            return{
                ...state,
                changeRequest: true
            }
        case CHANGE_SUCCESS:
            return{
                ...state,
                changeRequest: false
            }
        case CHANGE_ERROR:
            return{
                ...state,
                changeFail: payload,
                changeRequest: false
            }
        case CHANGE_FAIL_CLEAR:
            return{
                changeFail: {},
            }
        case SET_EXCHANGE_INPUT:
            return{
                ...state,
                exchangeInput: payload
            }
        case CLEAR_EXCHANGE_RESULT:
            return{
                ...state,
                exchangeResult: ''
            }
        default: return state
    }
}