import React, { Fragment, useEffect, useState } from 'react'
import { notifyError, notifySuccess } from "../../../../utils/notify"
import { connect, useDispatch } from 'react-redux'
import OutputWalletItem from '../../OutputWalletItem/OutputWalletItem'
import { getWallets, getCards, setActiveCard } from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import { Carousel } from 'react-responsive-carousel'
import { useParams } from 'react-router-dom'
import InputMask from "react-input-mask"
import SvgIcon from '../../SvgIcon/SvgIcon'
import { CLEAR_OUTPUT_ERRORS, CHANGE_SUCCESS } from '../../../../redux/types'
const baseUrl = process.env.REACT_APP_API_URL
const tokken = localStorage.getItem('tokken')

const Transfer = ({ wallets, loading, getWallets, activeCard, setActiveCard, fiatOutput, outputAmout }) => {
    // let { id } = useParams()
    const [outputError, setOutputError] = useState('')
    const [currency, setCurrency] = useState('')

    const[totalBalance, setTotalBalance] = useState(false)

    const [userId, setUserId] = useState('')
    const [userInfo, setUserInfo] = useState('')
    const [sum, setSum] = useState(0)

    const [emailCode, setEmailCode] = useState('')
    const [smsCode, setSmsCode] = useState('')
    const [gaCode, setGaCode] = useState('')

    const [emailCodeInfo, setEmailCodeInfo] = useState(false)
    const [smsCodeInfo, setSmsCodeInfo] = useState(false)
    const [gaCodeInfo, setGaCodeInfo] = useState(false)


    const dispatch = useDispatch()

    useEffect(() => {
        getWallets('FIAT RUB,FIAT USD')
        dispatch({ type: CLEAR_OUTPUT_ERRORS })
    }, [totalBalance])


    const infoUser = id => {
        try {
            fetch(`${baseUrl}/user/user-info-by-id?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            })
                .then(res => res.json())
                .then(data => {
                    if (!data.error) {
                        console.log(data.data)
                        setUserInfo(data.data)
                    }
                    if (data.error) {
                        return
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    const transferFiat = (userId, sum, emailCode, smsCode, gaCode) => {
        const data = {}

        if (!document.querySelector('li.selected .sendAmount').value || Number(document.querySelector('li.selected .sendAmount').value) <= 0) {
            setOutputError('Заполните поле корректно')
        }
        else {
            setCurrency(document.querySelector('li.selected .card').dataset.type)
            setSum(Number(document.querySelector('li.selected .sendAmount').value))
            data.recipient_id = userId
            data.sum = Number(document.querySelector('li.selected .sendAmount').value)
        }

        if(emailCode !== ''){
            data.emailCode = emailCode
        }

        if(smsCode !== ''){
            data.smsCode = smsCode
        }   

        if(gaCode !== ''){
            data.gaCode = gaCode
        }

        try {
            fetch(`${baseUrl}/balance/send?wallet=${document.querySelector('li.selected .card').dataset.type}`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(data => {
                    if (!data.error) {
                        console.log(data)

                        if (data.data.emailCode) {
                            setEmailCodeInfo(true)
                        }

                        if (data.data.smsCode) {
                            setSmsCodeInfo(true)
                        }

                        if (data.data.gaCode) {
                            setGaCodeInfo(true)
                        }

                        if(data.data === true){
                            notifySuccess('Успешно')
                            document.querySelector('li.selected .sendAmount').value = ''
                            setUserInfo('')
                            setUserId('')
                            setSum('')
                            setEmailCode('')
                            setSmsCode('')
                            setGaCode('')
                            setEmailCodeInfo('')
                            setSmsCodeInfo('')
                            setGaCodeInfo('')
                            setTotalBalance(true)
                        }
                    }

                    if (data.error) {
                        if( typeof(data.data) ==='string'){
                            notifyError(data.data)
                        }
                        else{
                            Object.values(data.data).map(item => item.map(txt => notifyError(txt)))
                        }
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <Fragment>
            <form className='top-up-page'>
                <Carousel emulateTouch={true}>
                    {wallets.map(item => <OutputWalletItem error={outputError} key={item.name} wallets={wallets} balance={Number(item.balance).toFixed(2)} name={item.name} address={item.address} />)}
                </Carousel>
                <div className="card p-4 mt-minus">
                    {emailCodeInfo
                        ? <>
                            <div className="h4 ta-l">Код с почты</div>
                            <div className="form-group">
                                <InputMask
                                    value={emailCode}
                                    onChange={e => {
                                        setEmailCode(e.target.value)
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="" />

                            </div>
                        </>
                        : <></>
                    }
                    {smsCodeInfo
                        ? <>
                            <div className="h4 ta-l">Код из sms</div>
                            <div className="form-group">
                                <InputMask
                                    value={smsCode}
                                    onChange={e => {
                                        setSmsCode(e.target.value)
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="" />

                            </div>
                        </>
                        : <></>
                    }
                    {gaCodeInfo
                        ? <>
                            <div className="h4 ta-l">Код из Google Authenticator</div>
                            <div className="form-group">
                                <InputMask
                                    value={gaCode}
                                    onChange={e => {
                                        setGaCode(e.target.value)
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="" />

                            </div>
                        </>
                        : <></>
                    }
                    <div className="h4 ta-l">ID пользователя</div>
                    <div className="form-group">
                        <InputMask
                            value={userId}
                            onChange={e => {
                                infoUser(e.target.value)
                                setUserId(e.target.value)
                            }}
                            type="text"
                            className="form-control"
                            placeholder="" />
                        {userInfo ? <div>{userInfo}</div> : ''}

                    </div>
                    <div className="d-flex justify-content-between mt-2 align-items-center">
                        <div onClick={() => window.history.back()} className="back-btn">
                            <SvgIcon size={30} id='BACK' />
                        </div>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                transferFiat(userId, sum, emailCode, smsCode, gaCode)
                            }}
                            className="btn btn-primary">Перевести средства</button>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    cardsLoading: state.wallets.cardsLoading,
    cards: state.wallets.cards,
    activeCard: state.wallets.activeCard,
    outputAmout: state.wallets.outputAmount
})

const mapDispatchToProps = dispatch => {
    return {
        getWallets: wallets => dispatch(getWallets(wallets)),
        getCards: (id, type) => dispatch(getCards(id, type)),
        setActiveCard: card => dispatch(setActiveCard(card)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfer)