import React, {Fragment, useEffect, useState} from 'react'
import { Dropdown } from "react-bootstrap"
import { connect, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {getWallets, getCards, setActiveCard, fiatInput, filterActiveCard, getCardExchanger} from '../../../../redux/actions/actions'
import { FIAT_INPUT_CLEAR_ERRORS } from '../../../../redux/types'
import Loader from '../../Loader/Loader'
import Modal from '../../Modal/Modal'
import SvgIcon from '../../SvgIcon/SvgIcon'
import Table from '../../Table/Table'

const BuyCard = ({sourceBankCardId, getCardExchanger,status, wallets, getWallets, loading, cardsLoading, cards, getCards, setActiveCard, activeCard, fiatInput, filterActiveCard, fiatInputErrors}) => {
    let {id} = useParams()
    const [currency, setCurrency] = useState(id == 'FIAT RUB' ? 'Рубли' : 'Доллары')
    const [cryproCurrency, setCryproCurrency] = useState(id)
    const [address, setAddress] = useState('')
    const [bank, setBank] = useState('')
    const [amount, setAmount] = useState('')
    const [amountTop, setAmountTop] = useState('')
    const dispatch = useDispatch()
    const [isModal, setModal] = useState(false);
    const [isModalInfo, setModalInfo] = useState(false);
    const [exchangeCardId, setExchangeCardId] = useState('')
    const [exchangeCard, setExchangeCard] = useState({})

    useEffect(() => {
        getWallets('FIAT USD,FIAT RUB')
        getCards({status: 2, sideFilter: 1})
        localStorage.setItem('toBack', id);
        dispatch({type: FIAT_INPUT_CLEAR_ERRORS})
        getCardExchanger({status: 2, sideFilter: 2})
    }, [])

    const handleChangeSelect = e => {
        setBank(e.target.value)
    }

    const handleCurrencyClick = e => {
        setCurrency(e.target.textContent)
        setCryproCurrency(e.target.textContent === 'Рубли' ? 'FIAT RUB' : 'FIAT USD')
    }

    const handleCryptoCurrencyClick = e => {
        setCryproCurrency(e.target.textContent)
        setAddress(e.target.dataset.address)
    }

    const handleSubmit = e => {
        e.preventDefault()
        sourceBankCardId.forEach(item => {
            if(item.bank === activeCard.bank) {
                setExchangeCardId(item.id)
                setExchangeCard(item)
                console.log(item)
            }
        })
        setModal(true)

    }

    const handleClick = () => {
        setModalInfo(false)
        fiatInput({
            amount: amount,
            currency: currency === 'Рубли' ? 103 : 104,
            method: 2,
            bankCardId: exchangeCardId,
            sourceBankCardId: activeCard.id
        })
        setAmount('')
    }

    const handleSend = e => {
        e.preventDefault()
        setAmountTop(amount)
        setModal(false)
        setModalInfo(true)
    }

    if(loading) {
        return <Loader/>
    }

    return(
        <Fragment>
            <Modal
                border={false}
                isVisible={isModal}
                title={`Пополнение баланса с карты ${activeCard.bankText} **** **** **** ${String(activeCard.number).substr(String(activeCard.number).length - 4, String(activeCard.number).length)} на сумму ${amount} ${currency === 'Рубли' ? 'рублей' : 'долларов'}.`}
                content={
                    <form 
                    className='d-flex align-items-center justify-content-between'
                    onSubmit={handleSend}>
                        <div className="form-check mb-2">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id="check1"
                            value=""
                            required
                            />
                            <label className="form-check-label" htmlFor="check1">Подтверждаю</label>
                        </div>
                        <button className="btn-primary btn">Создать заявку</button>
                    </form>
                }
                onClose={() => setModal(false)}
            />
            <Modal
                border={false}
                isVisible={isModalInfo}
                title={`Пополнените карту ${exchangeCard.bankText}`}
                content={
                    <div>
                         <p>
                           Номер карты: <b>{exchangeCard.number}</b>
                         </p>
                         <p>
                           Cумма: <b>{amountTop}</b>
                         </p>
                        <button 
                        onClick={handleClick}
                        className="btn-primary btn">Ок</button>
                    </div>
                }
                onClose={() => setModalInfo(false)}
            />
            <form onSubmit={handleSubmit}>
                <div className="card p-4">
                    <div className="buy-card">
                        <div className="buy-card__top mb-3">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="text-primary">У вас есть</div>
                                    <div className="form-group position-relative">
                                        <Dropdown className="dropdown custom-dropdown d-block position-absolute dropdown-curency">
                                            <Dropdown.Toggle
                                                variant=""
                                                as="div"
                                                className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                                                data-toggle="dropdown"
                                            >
                                                <div className="text-left ml-3">
                                                <span className="d-block fs-16 text-black pr-10-px">
                                                    {currency}
                                                </span>
                                                </div>
                                                <i className="fa fa-angle-down scale5 ml-auto" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                alignRight={true}
                                                className="dropdown-menu dropdown-menu-right"
                                            >
                                                <Dropdown.Item
                                                onClick={handleCurrencyClick}
                                                type="button"
                                                className="dropdown-item">
                                                Рубли
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                onClick={handleCurrencyClick} 
                                                type="button"
                                                className="dropdown-item">
                                                Доллары
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <input 
                                        onChange={e => setAmount(e.target.value)}
                                        required
                                        value={amount}
                                        type="number" 
                                        className="form-control h-50-px" />
                                        {fiatInputErrors?.amount?.map(item => <span className='text-danger small'>{item}</span>)}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <svg className='arrow-svg' width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6422 4.31563L9.07969 1.06563C9.03296 1.00629 8.97339 0.958321 8.90547 0.925306C8.83755 0.892291 8.76302 0.875092 8.6875 0.875H7.675C7.57031 0.875 7.5125 0.995312 7.57656 1.07812L9.83125 3.9375H0.375C0.30625 3.9375 0.25 3.99375 0.25 4.0625V5C0.25 5.06875 0.30625 5.125 0.375 5.125H11.2484C11.6672 5.125 11.9 4.64375 11.6422 4.31563Z" fill="currentColor"></path></svg>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                <div className="text-dark opacity-0">У вас есть</div>
                                    <div className="form-group position-relative">
                                        <span className='position-absolute input-currency'>{cryproCurrency}</span>
                                        <input
                                        value={amount}
                                        readOnly
                                        type="number" 

                                        className="form-control h-50-px input-read-only" />
                                        {/* <div className="text-dark mt-1 small">1 USDT = 0.97 EUR</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buy-card__middle">
                            <div className="form-group">
                                {cardsLoading ? <Loader/> :
                                <Fragment>
                                    <div className="text-primary mb-2">Выбор карты</div>
                                    <Dropdown className="dropdown custom-dropdown d-block mb-0 w-100">
                                        <Dropdown.Toggle
                                            variant=""
                                            as="div"
                                            className="btn  i-false d-flex align-items-center border-0 order-bg rounded p-7-px"
                                            data-toggle="dropdown"
                                        >
                                            {`${activeCard?.bankText} **** **** **** ${String(activeCard?.number).substr(String(activeCard.number).length - 4, String(activeCard.number).length)}`}
                                            <i className="fa fa-angle-down scale5 ml-auto" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            alignRight={true}
                                            className="dropdown-menu dropdown-menu-right"
                                        >
                                            {cards.map(card => 
                                            <Dropdown.Item 
                                                onClick={() => setActiveCard(card)}
                                                key={card.id}
                                                data-bank-id={card.id}>
                                                {`${card.bankText} ${`**** **** **** ${card.number.substr(card.number.length - 4, card.number.length)}`}`}
                                            </Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Fragment>
                                }
                            </div>
                            <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
                                <div onClick={() => window.history.back()} className="back-btn">
                                    <SvgIcon size={30} id='BACK'/>
                                </div>
                                <button className="btn-primary btn">Пополнить</button>
                            </div>
                        </  div>
                        <div className="buy-card__bottom">
                            {status != 4 ?
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="text-primary">Для покупки с карты нужно пройти верификацию </div>
                                <Link to='verify' className="btn btn-outline-success">Подтвердить</Link>
                            </div>
                            : ''
                            }
                        </div>
                    </div>
                </div>
                <Table type="1"/>
            </form>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    status: state.user.profile.verifyStatus,
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    cards: state.wallets.cards,
    activeCard: state.wallets.activeCard,
    cardsLoading: state.wallets.cardsLoading,
    fiatInputErrors: state.wallets.fiatInputErrors,
    sourceBankCardId: state.wallets.sourceBankCardId
})

const mapDispatchToProps = dispatch => {
   return{
    getCardExchanger: params => dispatch(getCardExchanger(params)),
    getWallets: () => dispatch(getWallets()),
    getCards: (id, type) => dispatch(getCards(id, type)),
    setActiveCard: card => {
        dispatch(setActiveCard(card))
    },
    fiatInput: data => {
        dispatch(fiatInput(data))
    }
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(BuyCard)