import React from 'react' 
import { connect } from 'react-redux'
import SvgIcon from '../SvgIcon/SvgIcon'

const HistoryItem = ({data, icon}) => {
    const {comment, createdAt, amount} = data
    const numbers = Number(amount)
    
    return(
        <li className="history__item mb-2">
            <div className="operation d-flex justify-content-between">
            <div className="operation__left d-flex">
                <div className="operation__icon mr-2">
                  <SvgIcon id={icon} />
                </div>
                <div className="operation-title-block">
                    <div className="operation__title">{comment}</div>
                    <div className="operation__time">{createdAt}</div>
                </div>
            </div>
            <div className="operation__right">
                <div className="operation-quality">{icon == 'BTC' || icon == 'ETH' ? numbers.toFixed(10) : numbers.toFixed(2)}</div>
            </div>
            </div>
        </li>
    )
}

const mapStateToProps = state => ({
    icon: state.history.icon
})

export default connect(mapStateToProps)(HistoryItem)