import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {connect} from 'react-redux'
import { notifyError } from '../../../utils/notify'
import {createSupportMessage} from '../../../redux/actions/actions'

const SupportForm = ({createSupportMessage}) => {
    const {handleSubmit, register, reset } = useForm()
    const [fileName, setFileName] = useState('Файл')

    const onSubmit = data => {
      console.log(data)
      createSupportMessage(data)
      reset()
      setFileName('Файл')
      document.querySelector('.custom-file-input').classList.remove('file-input-done')
    }

    const changeInput = e => {
      const type = e.target.files[0].type

        if(type === 'image/png' || type === 'image/jpeg') {
            e.target.classList.add('file-input-done')
            setFileName(e.target.files[0].name)
        } else {
            notifyError('Разрешенные форматы jpeg, jpg, png')
        }
    }

    return(
        <div className="card p-4">
            <div className="card-title mb-3">Создание обращения</div>
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-group mb-4">
                    <select
                     {...register('subject')}
                     required
                      className="form-control form-control-lg"
                    >
                      <option 
                      defaultValue={'Выбор'}
                      value="0">Выбор</option>
                      <option value="1">Техническая проблема на сайте</option>
                      <option value="2">Проблема с выводом средств</option>
                      <option value="3">Проблема с пополнением средств</option>
                      <option value="4">Проблема с начислением дивидендов и вознаграждений</option>
                      <option value="5">Пожелание</option>
                      <option value="6">Вопрос</option>
                      <option value="7">Другое</option>
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <textarea
                      required
                       {...register('text')} 
                      className="form-control"
                      rows="4"
                      id="comment"
                    ></textarea>
                  </div>
                  <div className="input-group mb-4">
                        <div className="custom-file support-file">
                            <input
                            {...register('file')} 
                            id='file'
                            type="file" 
                            onChange={changeInput}
                            className="custom-file-input" />
                            <label 
                            htmlFor='file'
                            className="custom-file-label support-label">{fileName.slice(0, 10)}...</label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className='btn btn-primary'>Отправить</button>
                    </div>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
  return{
      createSupportMessage: data => {
        dispatch(createSupportMessage(data))
      }
    }
}

export default connect(null, mapDispatchToProps)(SupportForm)