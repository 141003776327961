import React from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'

const CryptoWalletItem = ({wallet}) => {
    return(
        <div className="d-flex align-items-start justify-content-between mb-4 position-relative">
            {/* <div className="position-absolute small bottom_0">{wallet.name == 'USDT (ERC20)' ? '(ERC20)' : wallet.name == 'USDT (TRC20)' ? '(TRC20)' : ''}</div> */}
            <div className="mr-auto">
                <h2 className="card-title tt-init">{wallet.name === 'ETH' || wallet.name === 'BTC' ? Number(wallet.balance).toFixed(10) : Number(wallet.balance).toFixed(2)}</h2>
            </div>
            <SvgIcon id={wallet.name}/>
        </div>
    )
}

export default CryptoWalletItem