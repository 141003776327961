import { OPEN_TOGGLE_MENU, UPDATE_PASSWORD_ERROR, GET_PROFILE, GET_PROFILE_LOAD, LOGIN, LOGIN_ERROR, LOGIN_LOADER, LOGOUT, PASSWORD_ERROR, SET_CURRENT_BALANCE, UPDATE_PASSWORD_FIRST, UPDATE_PASSWORD_SECOND, UPDATE_SOCIAL_ERROR, UPDATE_PROFILE_ERROR, UPDATE_PROFILE_SUCCESS, GET_COMMISSION_SUCCESS, SEND_ERROR, SEND_SUCCESS, SEND_ERROR_CLEAR, SEND_CONFIRM, SIGNUP_ERROR, SIGNUP_SUCCESS_FIRST, SIGNUP_SUCCESS_SECOND, GET_WALET, GET_WALET_LOAD, FORGOT_PASSWORD_FIRST, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SECOND, LOGIN_NEED_CONFIRM, SEND_CLEAR, NEED_GA, GA_FAIL, CLEAR_COMMISION } from "../types"

const initialState = {
    profile:{},
    passwordError: [],
    loginError: [],
    loading: false,
    currentBalance: 'BTC',
    newPass: '',
    updataPasSecond: false,
    updateSocialErrors: {},
    updatePasswordErrors: {},
    updateProfileErrors: {},
    commission:{},
    sendErrors: {},
    sendFirst: false,
    snedData: {},
    signupErrors: {},
    signupFirst: false,
    signupData: {},
    params: {},
    wallet: {},
    walletLoad: false,
    updatePasData: {},
    openMenu: false,
    forgotPasswordData: {},
    forgotPasswordErrors: {},
    forgotPasswordFirst: false,
    loginNeedConfirm: false,
    loginNeedConfirmData: {},
    loginNeedGa: false,
    gaFails: []
}

export const userReducer = (state = initialState, action) => {
    const {type, payload} = action

    switch(type){
        case LOGIN_LOADER:
            return{
                ...state,
                loading: true
            }
        case LOGIN:
            return{
                ...state,
                loading: false
            }
        case LOGIN_ERROR:
            return{
                ...state,
                loginError: payload,
                loading: false
            }
        case PASSWORD_ERROR:
            return{
                ...state,
                passwordError: payload,
                loading: false
            }
        case GET_PROFILE:
            return{
                ...state,
                profile: payload,
                loading: false
            }
        case GET_PROFILE_LOAD: 
            return{
                ...state,
                loading: true
            }
        case SET_CURRENT_BALANCE:
            return{
                ...state,
                loading: false,
                currentBalance: payload
            }
        case UPDATE_PASSWORD_FIRST:
            return{
                ...state,
               newPass: payload.pas,
               updatePasData: payload.data
            }
        case UPDATE_PASSWORD_SECOND:
            return{
                ...state,
               updataPasSecond: true
            }
        case UPDATE_SOCIAL_ERROR:
            return{
                ...state,
                updateSocialErrors: payload
            }
        case UPDATE_PASSWORD_ERROR:
            return{
                ...state,
                updatePasswordErrors: payload
            }
        case UPDATE_PROFILE_ERROR:
            return{
                ...state,
                updateProfileErrors: payload
            }
        case UPDATE_PROFILE_SUCCESS:
            return{
                ...state,
                updateProfileErrors: {}
            }
        case GET_COMMISSION_SUCCESS:
            return{
                ...state,
                commission: payload
            }
        case SEND_ERROR:
            return{
                ...state,
                sendErrors: payload
            }
        case SEND_SUCCESS:
            return{
                ...state,
                sendErrors: {},
                sendFirst: true,
                snedData: payload
            }
        case SEND_CLEAR:
            return{
                ...state,
                snedData: {},
                sendFirst: false,
            }
        case SEND_CONFIRM:
            return{
                ...state,
                sendFirst: false,
                sendErrors: {}
            }
        case SEND_ERROR_CLEAR:
            return{
                ...state,
                sendErrors: {}
            }
        case SIGNUP_ERROR:
            return{
                ...state,
                loading: false,
                signupErrors: payload
            }
        case SIGNUP_SUCCESS_FIRST:
            return{
                ...state,
                signupErrors: {},
                signupFirst: true,
                signupData: payload.data,
                params: payload.params
            }
        case SIGNUP_SUCCESS_SECOND:
            return{
                ...state,
                loading: false,
                profile: payload
            }
        case FORGOT_PASSWORD_FIRST:
            return{
                ...state,
                loading: false,
                forgotPasswordErrors: {},
                forgotPasswordFirst: true,
                forgotPasswordData: payload
            }
        case FORGOT_PASSWORD_ERROR:
            return{
                ...state,
                loading: false,
                forgotPasswordErrors: payload
            }
        case FORGOT_PASSWORD_SECOND:
            return{
                ...state,
                loading: false,
            }
        case GET_WALET:
            return{
                ...state,
                wallet: payload,
                walletLoad: false
            }
        case GET_WALET_LOAD:
            return{
                ...state,
                walletLoad: true
            }
        case OPEN_TOGGLE_MENU:
            return{
                ...state,
                openMenu: !state.openMenu
            }
        case LOGIN_NEED_CONFIRM:
            return{
                ...state,
                loading: false,
                loginNeedConfirm: true,
                loginNeedConfirmData: payload,
            }
        case NEED_GA:
            return{
                ...state,
                loginNeedGa: true,
                loading: false
            }
        case GA_FAIL:
            return{
                ...state,
                loading: false,
                gaFails: payload
            }
        default: return state
    }
}