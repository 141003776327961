import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { getHistorySupport } from '../../../../redux/actions/actions'
import HistorySuport from '../../HIstorySuport/HIstorySuport'
import Loader from '../../Loader/Loader'
import SupportForm from '../../SupportForm/SupportForm'

const Support = ({loading, data, getHistorySupport}) => {
    useEffect(() => {
        getHistorySupport()
    }, [])

    return(
        <Fragment>
            <SupportForm/>
            {
            data.length > 0 ?
            <HistorySuport data={data} loading={loading} /> :
            loading ? 
            <Loader/> :
            ''
            }
            
        </Fragment>
    )
}

const mapStateToProps = state => ({
    loading: state.support.loading,
    data: state.support.data
})

const mapDispatchToProps = {
    getHistorySupport: getHistorySupport
}

export default connect(mapStateToProps, mapDispatchToProps)(Support)