import React from 'react'

const HistorySupportItem = ({data}) => {
    return(
        <tr>
            <th scope="row">{data.id}</th>
            <td>{data.subjectText}</td>
            <td>{data.createdAt}</td>
            <td>{data.lastMessageTime}</td>
            <td>{data.statusText}</td>
        </tr>
    )
}

export default HistorySupportItem