import { notifyError, notifyInfo, notifySuccess } from "../../utils/notify"
import { getQueryStringFromObject } from "../../utils/setUrlParams"
import { validateEmail } from "../../utils/validateemail"
import { GET_HISTORY_SUPPORT_LAST_PAGE, GET_HISTORY_SUPPORT_SET_CURRENT_PAGE, GET_HISTORY_LOAD, GET_HISTORY, GET_PROFILE, GET_PROFILE_LOAD, GET_WALLETS, GET_WALLETS_LOAD, LOGIN, LOGIN_LOADER, PASSWORD_ERROR, EXCHANGE_LOAD, EXCHANGE, GET_HISTORY_LAST_PAGE, GET_HISTORY_SET_CURRENT_PAGE, GET_HISTORY_ERROR, GET_HISTORY_SET_ICON, UPDATE_PASSWORD_FIRST, UPDATE_PASSWORD_SECOND, UPDATE_AVATAR, UPDATE_SOCIAL_ERROR, GET_HISTORY_SUPPORT, GET_HISTORY_SUPPORT_ERROR, UPDATE_PASSWORD_ERROR, UPDATE_PROFILE_ERROR, UPDATE_PROFILE_SUCCESS, GET_COMMISSION_SUCCESS, SEND_ERROR, SEND_SUCCESS, SEND_CONFIRM, SIGNUP_ERROR, SIGNUP_SUCCESS_FIRST, SIGNUP_SUCCESS_SECOND, GET_WALET, GET_WALET_LOAD, FORGOT_PASSWORD_FIRST, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_SECOND, GET_CHANGE_REQUEST, GET_CHANGE_SUCCESS, GET_CHANGE_ERROR, CHANGE_BUY_CURRENCY, CHANGE_SOLD_CURRENCY, GET_CHANGE_PRICE_REQUEST, GET_CHANGE_PRICE_ERROR, GET_CHANGE_PRICE_SUCCESS, CHANGE_REVERSE, CHANGE_SUCCESS, CHANGE_ERROR, CHANGE_REQUEST, EXCHANGE_RESULT_CLEAR, GET_FIAT_WALLETS_SUCCESS, GET_FIAT_WALLETS_REQUEST, LOGIN_NEED_CONFIRM, FIAT_INPUT_ERROR, FIAT_INPUT_SUCCESS, GET_BANK_CARDS_LIST, GET_BANK_CARDS_LIST_REQUEST, GET_CARDS_REQUEST, GET_CARDS_SUCCESS, SET_ACTIVE_CARD, FIAT_OUTPUT_SUCCESS, FIAT_OUTPUT_ERROR, GET_HISTORY_APPLICATIONS_REQUEST, GET_HISTORY_APPLICATIONS_SUCCESS, GET_HISTORY_APPLICATIONS_ERROR, SET_EXCHANGE_INPUT, GET_TOTAL_WALLETS_SUCCES, GET_TOTAL_BALANCE_REQEST, GET_TOTAL_BALANCE_SUCCESS, GET_TOTAL_WALLETS_REQUEST, GET_SOLD_CURRENCIES_SUCCESS, GET_SOLD_CURRENCIES_REQUEST, SET_SOLD_CURRENCY, GET_BUY_CURRENCIES_REQUEST, GET_BUY_CURRENCIES_SUCCESS, SET_BUY_CURRENCY, GET_CHANGE_PRICE_LOAD, GET_CHANGE_PRICE, SET_SOLD_BALANCE, SET_SOLD_INPUT_VALUE, SET_CHANGE_AMOUNT, CHANGE_CLEAR, NEED_GA, GA_FAIL, CLEAR_COMMISION, SET_ADDRESS, SET_ACTIVE_CARD_EXCHANGER_LOAD, SET_ACTIVE_CARD_EXCHANGER } from "../types"
import {store} from '../index'
const baseUrl = process.env.REACT_APP_API_URL
const tokken = localStorage.getItem('tokken')


export const signup = (username, password, ref) => {
    const params = {}

    if(!validateEmail(username)) {
        params.username_phone = username
    }

    params.password = password

    if(validateEmail(username)) {
        params.username = username
    }
    
    if(ref != undefined) {
        params.ref = ref
    }
    return dispatch => {
        dispatch({
            type: LOGIN_LOADER
        })

        try {
            fetch(`${baseUrl}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            })
            .then(res => res.json())
            .then(data => {
                if(data.error) {
                    dispatch({
                        type: SIGNUP_ERROR,
                        payload: data.data
                    })
                }
                if(!data.error) {
                    notifyInfo('Подтвердите регистрацию')
                    dispatch({
                        type: SIGNUP_SUCCESS_FIRST,
                        payload: {
                            data: data.data,
                            params: params
                        }
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
   
}
export const signupConfirm = (data, pevParams) => {
    const params = {}

    if(data.code != undefined) {
        params.code = data.code
    }
    if(data.emailCode != undefined) {
        params.emailCode = data.emailCode
    }

    const paramsObj = {
        ...params,
        ...pevParams,
    }


    return dispatch => {
        dispatch({
            type: LOGIN_LOADER
        })

        try {
            fetch(`${baseUrl}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paramsObj)
            })
            .then(res => res.json())
            .then(data => {
                if(data.error) {
                    dispatch({
                        type: SIGNUP_ERROR,
                        payload: data.data
                    })
                }
                if(!data.error) {
                    localStorage.setItem('tokken', data.data.access_token)
                    dispatch({
                        type: SIGNUP_SUCCESS_SECOND,
                        payload: data.data.profile
                    })
                    window.location.reload()
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
   
}

export const login = (username, password) => {
    const params = {}

    if(validateEmail(username)) {
        params.username = username
    }
    if(!validateEmail(username)) {
        params.username_phone = username
    }
    params.password = password

    return dispatch => {
        dispatch({
            type: LOGIN_LOADER
        })

        try {
            fetch(`${baseUrl}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            })
            .then(res => res.json())
            .then(json => {
                if(json.error) {
                    const {password} = json.data.data
                    if(json.data.data != undefined && json.data.data.password != undefined) {
                        dispatch({
                            type: PASSWORD_ERROR,
                            payload: password
                        })
                    }
                    if((json.data.phoneAccepted != null && json.data.emailAccepted != null) && (!json.data.phoneAccepted || !json.data.emailAccepted) && json.data.data.code.length == undefined) {
                        dispatch({
                            type: LOGIN_NEED_CONFIRM,
                            payload: {
                                emailAccepted: json.data.emailAccepted,
                                phoneAccepted: json.data.phoneAccepted
                            }
                        })
                    }
                    if(json.data.data.gaCode != undefined) {
                        dispatch({type: NEED_GA})
                    }
                    if(json.data.data.code != undefined) {
                        dispatch({
                            type: LOGIN_NEED_CONFIRM,
                            payload: {
                                emailAccepted: json.data.emailAccepted,
                                phoneAccepted: !json.data.phoneAccepted
                            }
                        })
                    }
                }
    
                if(!json.error) {
                    if(json.data.emailAccepted && json.data.phoneAccepted) {
                        localStorage.setItem('tokken', json.data.data.access_token)
                        dispatch({
                            type: LOGIN,
                            payload: json.data.data
                        })
                        window.location.reload()
                    } 
                  
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export const loginConfirm = data => {
    const params = {}

    if(data.gaCode != undefined) {
        params.gaCode = data.gaCode
    }
    if(data.emailCode != undefined) {
        params.emailCode = data.emailCode
    }
    if(data.smsCode != undefined) {
        params.smsCode = data.smsCode
    }
    if(data.code != undefined) {
        params.code = data.code
    }
    if(validateEmail(document.querySelector('#username').value)) {
        params.username = document.querySelector('#username').value
    }
    if(!validateEmail(document.querySelector('#username').value)) {
        params.username_phone = document.querySelector('#username').value
    }
    params.checkbox = true
    params.password = document.querySelector('#login-password').value

    return dispatch => {
        dispatch({
            type: LOGIN_LOADER
        })

        try {
            fetch(`${baseUrl}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            })
            .then(res => res.json())
            .then(json => {
                if(json.error) {
                    const {password, gaCode} = json.data.data
                    if(password != undefined) {
                        dispatch({
                            type: PASSWORD_ERROR,
                            payload: password
                        })
                    }
                    if(gaCode != undefined) {
                        dispatch({
                            type: GA_FAIL,
                            payload: gaCode
                        })
                    }
                }
    
                if(!json.error) {
                    console.log(json)
                    localStorage.setItem('tokken', json.data.data.access_token)
                    dispatch({
                        type: LOGIN,
                        payload: json.data.data
                    })
                    window.location.reload()
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export const getProfile = () => {
    return dispatch => {
       dispatch({
           type: GET_PROFILE_LOAD
       })
       fetch(`${baseUrl}/profile`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokken}`
            }
       })
       .then(res => res.json())
       .then(data => {
            if(data.name == 'Unauthorized') {
                localStorage.clear()

                window.location.reload()
            }
           if(!data.error) {
               dispatch({
                   type: GET_PROFILE,
                   payload: data.data
               })
           }
       })
    }
}

export const getHistory = (params) => {
    let page = 1
    if(params.page !== undefined) {
        page = params.page
    }
    let queryString = getQueryStringFromObject(params)
    if(queryString.indexOf('%2B')) {
        queryString = queryString.replace('%2B', '+')
    }

    return dispatch => {
        dispatch({
            type: GET_HISTORY_SET_CURRENT_PAGE,
            payload: page
        })

        dispatch({
            type:GET_HISTORY_LOAD,
        })
       fetch(`${baseUrl}/address/history?${queryString}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokken}`
            }
       })
       .then(res => res.json())
       .then(data => {
            if(data.name == 'Unauthorized') {
                localStorage.clear()

                window.location.reload()
            }
           if(!data.error) {
               dispatch({
                   type: GET_HISTORY,
                   payload: data.data.data
               })
               dispatch({
                   type: GET_HISTORY_LAST_PAGE,
                   payload: data.data.lastPage
               })
               dispatch({
                   type: GET_HISTORY_SET_ICON,
                   payload: params.name
               })
           }
           if(data.error) {
                dispatch({
                    type: GET_HISTORY_ERROR,
                    payload: data.data
                })
                notifyError(data.data)
           }
       })
    }
}

export const getWallet = name => {
    return dispatch => {
        dispatch({
            type: GET_WALET_LOAD
        })
       fetch(`${baseUrl}/address?names=${name}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokken}`
            }
       })
       .then(res => res.json())
       .then(data => {
           if(!data.error) {
                dispatch({
                    type: GET_WALET,
                    payload: data.data[0]
                })
           }
           if(data.error) {
                console.log(data.error)
           }
       })
    }
}
export const getWallets = (names = '', back) => {
    return dispatch => {
        if(names == 'FIAT USD,FIAT RUB') {
            dispatch({
                type: GET_FIAT_WALLETS_REQUEST
            })
        } else {
            dispatch({
                type: GET_WALLETS_LOAD
            })
        }
       
       fetch(`${baseUrl}/address?names=${names}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokken}`
            }
       })
       .then(res => res.json())
       .then(data => {
           if(!data.error) {
               if(names == 'FIAT USD,FIAT RUB') {
                    dispatch({
                        type: GET_FIAT_WALLETS_SUCCESS,
                        payload: data.data
                    })
               } else {
                    dispatch({
                        type: GET_WALLETS,
                        payload: data.data
                    })
               }
               if(back != undefined) {
                   if(back == 'FIAT RUB') {
                    document.querySelector(`.dot[value="4"]`).click()
                   }
                   if(back == 'FIAT USD') {
                    document.querySelector(`.dot[value="5"]`).click()
                   }
                   localStorage.removeItem('toBack')
               }
           }
       })
    }
}

export const getExchange = () => {
    return dispatch => {
       dispatch({
           type: EXCHANGE_LOAD
       })
       fetch(`${baseUrl}/exchange`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokken}`
            }
       })
       .then(res => res.json())
       .then(data => {
           if(!data.error) {
               dispatch({
                   type: EXCHANGE,
                   payload: data.data
               })
           }
       })
    }
}

export const changeSoldCurrency = currency => {
    return dispatch => {
        dispatch({
            type: CHANGE_SOLD_CURRENCY,
            payload: currency
        })
    }
}

export const updatePasFirst = password => {
    return dispatch => {
        fetch(`${baseUrl}/user/update-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
              },
            body: JSON.stringify({password: password})
        })
            .then(res => res.json())
            .then(json => {
                if(!json.error) {
                    dispatch({
                        type: UPDATE_PASSWORD_FIRST,
                        payload: {
                            pas: password,
                            data: json.data
                        }
                    })
                    notifyInfo('Подтвердите изменения пароля')
                }

                if(json.error) {
                   dispatch({
                       type: UPDATE_PASSWORD_ERROR,
                       payload: json.data
                   })
                }
            })
    }
}

export const updatePasSecond = (code, emailCode, password ) => {
    return dispatch => {
        fetch(`${baseUrl}/user/update-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
              },
            body: JSON.stringify({
                password: password,
                code: code,
                emailCode: emailCode
            })
        })
            .then(res => res.json())
            .then(json => {
                if(!json.error) {
                    dispatch({
                        type: UPDATE_PASSWORD_SECOND,
                    })
                    notifySuccess('Пароль изменен')
                    dispatch({
                        type: UPDATE_PASSWORD_FIRST,
                        payload: password
                    })
                }

                if(json.error) {
                    dispatch({
                        type: UPDATE_PASSWORD_ERROR,
                        payload: json.data
                    })
                }
            })
    }
}

export const forgotPassword = (username, password) => {
    const params = {}

    if(validateEmail(username)) {
        params.email = username
    }
    if(!validateEmail(username)) {
        params.phone = username
    }
    params.password = password
    return dispatch => {
        dispatch({
            type: LOGIN_LOADER
        })
        fetch(`${baseUrl}/auth/recovery-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(params)
        })
            .then(res => res.json())
            .then(json => {
                if(!json.error) {
                    dispatch({
                        type: FORGOT_PASSWORD_FIRST,
                        payload: {
                            pas: password,
                            data: json.data,
                            username: username
                        }
                    })
                    notifyInfo('Подтвердите изменения пароля')
                }

                if(json.error) {
                   dispatch({
                       type: FORGOT_PASSWORD_ERROR,
                       payload: json.data
                   })
                }
            })
    }
}

export const forgotPasswordConfirm = (password, username, code, emailCode) => {
    const params = {}
    if(code != undefined) {
        params.code = code
    }
    if(emailCode != undefined) {
        params.emailCode = emailCode
    }

    if(validateEmail(username)) {
        params.email = username
    }

    if(!validateEmail(username)) {
        params.phone = username
    }

    params.password = password
    return dispatch => {
        dispatch({
            type: LOGIN_LOADER
        })
        fetch(`${baseUrl}/auth/recovery-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify(params)
        })
            .then(res => res.json())
            .then(json => {
                if(!json.error) {
                   dispatch({
                       type: FORGOT_PASSWORD_SECOND
                   })
                   notifySuccess('Пароль изменен авторизуйтесь')
                }

                if(json.error) {
                    dispatch({
                       type: FORGOT_PASSWORD_ERROR,
                       payload: json.data
                    })
                }
            })
    }
}

export const updateProfile = (last_name, first_name, patronymic, birthdate ) => {
    return dispatch => {
        fetch(`${baseUrl}/profile/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
              },
            body: JSON.stringify({last_name, first_name, patronymic, birthdate })
        })
            .then(res => res.json())
            .then(json => {
                if(!json.error) {
                    dispatch(getProfile())
                    dispatch({
                        type: UPDATE_PROFILE_SUCCESS
                    })
                    notifySuccess('Профиль изменен')
                }

                if(json.error) {
                   dispatch({
                       type: UPDATE_PROFILE_ERROR,
                       payload: json.data
                   })
                }
            })
    }
}

export const updateAvatar = file => {
    return dispatch => {
        const formData = new FormData()
        formData.append('file', file)

        try {
            fetch(`${baseUrl}/profile/upload-avatar`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`
                },
                method: 'POST',
                body: formData
            })
            .then(res => res.json())
            .then(json => {
                if(!json.error) {
                    dispatch(getProfile())
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateSocial = params => {
    return dispatch => {
        try {
            fetch(`${baseUrl}/profile/update-social`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(params)
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                    dispatch(getProfile())
                    notifySuccess('Контактные данные обновлены')
                }
                if(data.error) {
                    dispatch({
                        type: UPDATE_SOCIAL_ERROR,
                        payload: data.data
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const uploadPassport = data => {
    const formData = new FormData()
    formData.append('passport1', data.passport1[0])
    formData.append('passport2', data.passport2[0])
    formData.append('selfie', data.selfie[0])

    return dispatch => {
        try {
            fetch(`${baseUrl}/profile/upload-passport`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                },
                method: 'POST',
                body: formData
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                    notifyInfo('Ваши данные отправлены на проверку!')
                    dispatch(getProfile())
                }

                if(data.error) {
                    notifyError(data.data)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const getHistorySupport = (page = 1) => {
    return dispatch => {
        dispatch({
            type: GET_HISTORY_SUPPORT_SET_CURRENT_PAGE,
            payload: page
        })

        fetch(`${baseUrl}/support?page=${page}&limit=10`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
            }
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                dispatch({
                    type: GET_HISTORY_SUPPORT,
                    payload: data.data.models
                })
                dispatch({
                    type: GET_HISTORY_SUPPORT_LAST_PAGE,
                    payload: data.data.lastPage
                })
            }
            if(data.error) {
                dispatch({
                    type: GET_HISTORY_SUPPORT_ERROR,
                    payload: data.data
                })
            }
        })
    }
}

export const createSupportMessage = data => {
    const formData = new FormData()
    formData.append('file', data.file[0])
    
    formData.append('subject', data.subject)
    formData.append('text', data.text)

    return dispatch => {
        try {
            fetch(`${baseUrl}/support/create`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                },
                method: 'POST',
                body: formData
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                    notifySuccess('Ваше обращение отправлено!')
                    dispatch(getHistorySupport())
                }
                if(data.error) {
                    notifyError(data.data.subject[0])
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const send = (name, amount, to) => {
    return dispatch => {
        try {
            fetch(`${baseUrl}/address/send?name=${name}`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    to: to,
                    amount: amount
                })
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                   if(data.data !== true) {
                        notifyInfo('Подтвердите перевод')
                        dispatch({
                            type: SEND_SUCCESS,
                            payload: data.data
                        })
                   }
                   if(data.data === true) {
                        notifySuccess('Пeревод выполнен')
                        dispatch(getWallets())
                        dispatch({
                            type: SET_ADDRESS,
                            payload: ''
                        })
                   }
                }
                if(data.error) {
                    dispatch({
                        type: SEND_ERROR,
                        payload: data.data
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const sendConfirm = (name, amount, to, emailCode, smsCode, gaCode) => {

    return dispatch => {
        try {
            fetch(`${baseUrl}/address/send?name=${name}`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    to: to,
                    amount: amount,
                    smsCode: smsCode,
                    emailCode: emailCode,
                    gaCode: gaCode
                })
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                   dispatch({
                       type: SEND_CONFIRM
                   })
                   notifyInfo('Перевод выполнен')
                   dispatch(getWallets())
                    dispatch({
                        type: SET_ADDRESS,
                        payload: ''
                    })
                }
                if(data.error) {
                    if(data.data == 'Один из кодов введен неверно') {
                        notifyError('Один из кодов введен неверно')
                    }
                    dispatch({
                        type: SEND_ERROR,
                        payload: data.data
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const commissions = address => {
    return dispatch => {
        fetch(`${baseUrl}/commissions?to=${address}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
            },
        })
        .then(res => res.json())
        .then(data => {
            dispatch({
                type: GET_COMMISSION_SUCCESS,
                payload: data.data
            })
        })
    }
}

export const searchHistory = (params) => {
    let queryString = getQueryStringFromObject(params)
    if(queryString.indexOf('%2B')) {
        queryString = queryString.replace('%2B', '+')
    }

    return dispatch => {
        dispatch({
            type: GET_HISTORY_LOAD
        })
        fetch(`${baseUrl}/address/history?${queryString}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(!data.error) {
                   dispatch({
                       type: GET_HISTORY,
                       payload: data.data.data
                   })
               }
           })
    }
}

export const change = (source, destination, amount) => {
    const soldAmount = store.getState().change.soldBalance
    console.log(source, destination, amount)
    return dispatch => {
        dispatch({
            type: CHANGE_REQUEST
        })
        fetch(`${baseUrl}/change/change`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                source: source,
                destination: destination,
                amount: amount
            })
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                dispatch({
                    type: CHANGE_SUCCESS
                })
                notifySuccess('Обмен заверщен')
                if(source == 'BTC' || source == 'ETH') {
                    dispatch({type: SET_SOLD_BALANCE, payload: String(soldAmount - amount).slice(0, 12)})
                } else {
                    dispatch({type: SET_SOLD_BALANCE, payload: (parseInt((soldAmount - amount) * 100)) / 100})
                }
                
                dispatch({
                    type: EXCHANGE_RESULT_CLEAR
                })
                dispatch({
                    type: CHANGE_CLEAR
                })
                dispatch(getWallets('FIAT USD,FIAT RUB,USDT (ERC20),ETH,BTC,USDT (TRC20)'))
            }
            if(data.error) {
                dispatch({
                    type: CHANGE_ERROR,
                    payload: data.data
                })
                notifyError(data.data)
            }
        })
    }
}

export const fiatInput = data => {
    const params = {}

    params.amount = data.amount
    params.currency = data.currency
    params.method = data.method
    if(data.bankCardId != undefined) {
        params.bankCardId = data.bankCardId
    }
    if(data.sourceBankCardId != undefined) {
        params.sourceBankCardId = data.sourceBankCardId
    }
    console.log('params', params)
    console.log('data', data)

    return dispatch => {
        try {
            fetch(`${baseUrl}/fiat-bid/input`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(params)
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                  notifySuccess('Заявка создана')
                  dispatch({
                      type: FIAT_INPUT_SUCCESS
                  })
                  dispatch(getApplication(1))
                }
                if(data.data == 'Выбрана недопустимая карта, на которую будет выполнен перевод') {
                    console.log('sss')
                    notifyError('Выбрана недопустимая карта, на которую будет выполнен перевод')
                }
                if(data.error) {
                    dispatch({
                        type: FIAT_INPUT_ERROR,
                        payload: data.data
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const fiatOutput = data => {
    const params = {}

    params.amount = data.amount
    params.currency = data.currency
    params.method = data.method

    if(data.bankCardId != undefined) {
        params.bankCardId = String(data.bankCardId)
    }
    if(data.communicationMethod != undefined) {
        params.communicationMethod = String(data.communicationMethod)
    }
    if(data.comment != undefined) {
        params.comment = String(data.comment)
    }
    return dispatch => {
        try {
            fetch(`${baseUrl}/fiat-bid/output`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(params)
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                  notifySuccess('Заявка создана')
                  dispatch({
                      type: FIAT_OUTPUT_SUCCESS
                  })
                  
                  dispatch(getApplication(2))
                }
               
                if(data.error) {
                    dispatch({
                        type: FIAT_OUTPUT_ERROR,
                        payload: data.data
                    })
                    if(data.data === 'Выбрана недопустимая карта'){
                        notifyError('Выбрана недопустимая карта')
                    }
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const getBankCardsList = () => {
    return dispatch => {
        dispatch({
            type: GET_BANK_CARDS_LIST_REQUEST
        })
        try {
            fetch(`${baseUrl}/bank-card/bank-list`, {
                headers: {
                    'Authorization': `Bearer ${tokken}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if(!data.error) {
                    dispatch({
                        type: GET_BANK_CARDS_LIST,
                        payload: data.data
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export const getCards = (params, id) => {
    let queryString = getQueryStringFromObject(params)
    console.log(params, id)
   
    return dispatch => {
        dispatch({type: GET_CARDS_REQUEST})
        fetch(`${baseUrl}/bank-card?${queryString}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            },
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                if(id == undefined) {
                    dispatch({
                        type: GET_CARDS_SUCCESS,
                        payload: data.data.models
                    })
                    dispatch({
                        type: SET_ACTIVE_CARD,
                        payload: data.data.models[0]
                    })
                }
                if(id != undefined) {
                    dispatch({
                        type: GET_CARDS_SUCCESS,
                        payload: data.data.models.filter(item => item.bank == id)
                    })
                    dispatch({
                        type: SET_ACTIVE_CARD,
                        payload: data.data.models.filter(item => item.bank == id)[0]
                    })
                }
            }
            if(data.error){
                notifyError(data.data)
            }
        })
    }
}

export const getCardExchanger = (params) => {
    let queryString = getQueryStringFromObject(params)
   
    return dispatch => {
        dispatch({type: SET_ACTIVE_CARD_EXCHANGER_LOAD})
        fetch(`${baseUrl}/bank-card?${queryString}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            },
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                dispatch({
                    type: SET_ACTIVE_CARD_EXCHANGER,
                    payload: data.data.models
                })       
            }
            if(data.error){
                notifyError(data.data)
            }
        })
    }
}

export const createCard = (bank, number, file) => {
    console.log(file)
    const formData = new FormData()
    formData.append('file', file[0])
    formData.append('bank', bank)
    formData.append('number', number)
    
    return dispatch => {
        fetch(`${baseUrl}/bank-card/create`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
            },
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                notifyInfo('Карта проходит верификацию')
                dispatch(getCards({sideFilter: 1}))
            }
        })
    }
}

export const deleteCard = id => {
    return dispatch => {
        fetch(`${baseUrl}/bank-card/delete?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST'
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                notifySuccess('Карта удалена')
                dispatch(getCards())
            }
        })
    }
}

export const setActiveCard = card => {
    return dispatch => {
        dispatch({
            type: SET_ACTIVE_CARD,
            payload: card
        })
    }
}

export const getApplication = params => {
    return dispatch => {
        dispatch({type: GET_HISTORY_APPLICATIONS_REQUEST})
        fetch(`${baseUrl}/fiat-bid${params == 1 ? '?type=1' : params == 2 ? '?type=2' : ''}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                dispatch({
                    type: GET_HISTORY_APPLICATIONS_SUCCESS,
                    payload: data.data.models
                })
            }
            if(data.error) {
                dispatch({
                    type: GET_HISTORY_APPLICATIONS_ERROR,
                    payload: data.data
                })
            }
        })
    }
}

export const setExchangeInput = val => {
    return dispatch => {
        dispatch({
            type: SET_EXCHANGE_INPUT,
            payload: val
        })
    }
}
export const getTotalBalance = currency => {
    return dispatch => {
        dispatch({type: GET_TOTAL_BALANCE_REQEST})
        fetch(`${baseUrl}/balance/total?withFiat=1&currency=${currency}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.error){
                notifyError(res.data)
                console.log(res.data)
            }
            if(!res.error){
                if(currency == 'BTC' || currency == 'ETH') {
                    dispatch({type: GET_TOTAL_BALANCE_SUCCESS, payload: String(res.data).slice(0, 12)})
                } else {
                    dispatch({type: GET_TOTAL_BALANCE_SUCCESS, payload: (parseInt(res.data * 100)) / 100})
                }
            }
        })
    }
}
export const getTotalWallets = name => {
    return dispatch => {
        dispatch({type: GET_TOTAL_WALLETS_REQUEST})
        fetch(`${baseUrl}/address?names=${name}`, {
            headers: {
                'Authorization': `Bearer ${tokken}`,
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res.error){
                notifyError(res.data)
                console.log(res.data)
            }
            if(!res.error){
               dispatch({type: GET_TOTAL_WALLETS_SUCCES, payload: res.data})
               dispatch(getTotalBalance('USDR'))
            }
        })
    }
}

export const getBuyCurrencies = currency => {
    let source = store.getState().change.soldCurrency
    return dispatch => {
        dispatch({type: GET_BUY_CURRENCIES_REQUEST})
        fetch(`${baseUrl}/change?source=${currency}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(!data.error){
                dispatch({type: GET_BUY_CURRENCIES_SUCCESS, payload: data.data})
                dispatch({type: SET_BUY_CURRENCY, payload: data.data[0]})
                dispatch(getChangePrice(source, data.data[0]))
               }
               if(data.error) {
                   console.log(data)
               }
           })
    }
}

export const getChangePrice = (currencySold, currencyBuy) => {
    return dispatch => {
        dispatch({type: GET_CHANGE_PRICE_LOAD})
        fetch(`${baseUrl}/change/rate?source=${currencySold}&destination=${currencyBuy}&amount=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(!data.error) {
                if(currencyBuy == 'BTC' || currencyBuy == 'ETH') {
                    dispatch({type: GET_CHANGE_PRICE, payload: String(data.data).slice(0, 12)})
                } else {
                    dispatch({type: GET_CHANGE_PRICE, payload: (parseInt(data.data * 100)) / 100})
                }
               }
           })
    }
}

export const initChange = () => {
    return dispatch => {
        dispatch({type: GET_SOLD_CURRENCIES_REQUEST})
        fetch(`${baseUrl}/address?names=FIAT USD,FIAT RUB,USDT (ERC20),ETH,BTC,USDT (TRC20)`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(!data.error) {
                   console.log(data)
                    dispatch({type: GET_SOLD_CURRENCIES_SUCCESS, payload: data.data})
                    dispatch({type: SET_SOLD_CURRENCY, payload: data.data[0].name})
                    if(data.data[0].name == 'ETH' || data.data[0].name == 'BTC') {
                        dispatch({type: SET_SOLD_BALANCE, payload: String(data.data[0].balance).slice(0, 12)})
                    } else {
                        dispatch({type: SET_SOLD_BALANCE, payload: (parseInt(data.data[0].balance * 100)) / 100})
                    }
                    dispatch(getBuyCurrencies(data.data[0].name))
               }
           })
    }
}

export const selectSoldCurrency = (currency, balance) => {
    return dispatch => {
        dispatch({type: CHANGE_CLEAR})
        dispatch({type: SET_SOLD_CURRENCY, payload: currency})
        if(currency == 'BTC' || currency == 'ETH') {
            dispatch({type: SET_SOLD_BALANCE, payload: String(balance).slice(0, 12)})
        } else {
            dispatch({type: SET_SOLD_BALANCE, payload: (parseInt(balance * 100)) / 100})
        }
        fetch(`${baseUrl}/change?source=${currency}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(data.error) {
                   console.log(data.error)
               }
               if(!data.error) {
                    dispatch({type: GET_BUY_CURRENCIES_SUCCESS, payload: data.data})
                    dispatch({type: SET_BUY_CURRENCY, payload: data.data[0]})
                    dispatch(getChangePrice(currency, data.data[0]))
               }
           })
    }
}

export const selectBuyCurrency = (soldCurrency, buyCurency) => {
    return dispatch => {
        dispatch({type: CHANGE_CLEAR})
        fetch(`${baseUrl}/change/rate?source=${soldCurrency}&destination=${buyCurency}&amount=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(!data.error) {
                    dispatch(getChangePrice(soldCurrency, buyCurency))
                    dispatch({type: SET_BUY_CURRENCY, payload: buyCurency})
               }
               if(data.error) {
                   notifyError(data.data)
               }
           })
    }
}

export const reverseChange = (soldCurrency, buyCurency) => {
    let wallets = store.getState().wallets.wallets
    
    return dispatch => {
        dispatch({type: CHANGE_CLEAR})
        fetch(`${baseUrl}/change?source=${buyCurency}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokken}`
                }
           })
           .then(res => res.json())
           .then(data => {
               if(data.error) {
                   console.log(data)
               }
               if(!data.error) {
                    let noChange = true
                    data.data.forEach(item => {
                       if(item == soldCurrency) {
                           wallets.forEach(item => {
                               if(item.name == buyCurency) {
                                   if(buyCurency == 'ETH' || buyCurency == 'BTC') {
                                        dispatch({type: SET_SOLD_BALANCE, payload: String(item.balance).slice(0, 12)})
                                   } else{
                                        dispatch({type: SET_SOLD_BALANCE, payload: (parseInt(item.balance * 100)) / 100})
                                   }
                               }
                           })
                           noChange = false
                           dispatch(getChangePrice(buyCurency, soldCurrency))
                           dispatch({type: SET_BUY_CURRENCY, payload: soldCurrency})
                           dispatch({type: SET_SOLD_CURRENCY, payload: buyCurency})
                       }
                    })
                    if(noChange) {
                        notifyError('Не допустимая пара для обмена')
                        initChange()
                    }
               }
           })
    }
}

export const setSoldInputValue = (val, changePrice) => {
    const buyCurrence = store.getState().change.buyCurrency
    console.log(val)
    return dispatch => {
        dispatch({type: SET_SOLD_INPUT_VALUE, payload: val})
        if(buyCurrence == 'BTC' || buyCurrence == 'ETH') {
            dispatch({type: SET_CHANGE_AMOUNT, payload: String(Number(val) * Number(changePrice)).slice(0, 12)})
        } else {
            dispatch({type: SET_CHANGE_AMOUNT, payload: (Number(val) * Number(changePrice)).toFixed(3).replace(/.$/,'')})
        }
        
    }
}

export const setAddress = val => {
    return dispatch => {
        dispatch({
            type: SET_ADDRESS,
            payload: val
        })
    }
}