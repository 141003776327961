import React from 'react'
import { connect } from 'react-redux'
import HistorySupportItem from '../HistorySupportItem/HistorySupportItem'
import Loader from '../Loader/Loader'
import {getHistorySupport} from '../../../redux/actions/actions'

const HistorySuport = ({data, loading, lastPage, currentPage, getHistorySupport}) => {
    let pages = []

    for(let i = 1; i <= lastPage; i++) {
        pages.push(i)
    }

    const handlePrevBtn = e => {
        getHistorySupport(currentPage - 1)
    }
    const handleNextBtn = e => {
        getHistorySupport(currentPage + 1)
    }
    const handlePageBtn = e => {
        getHistorySupport(Number(e.target.textContent))
    }
    
    return(
        <div className="card p-4">
            <div className="card-title mb-3">История обращений</div>
            <div className="table-wrap">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">ТЕМА</th>
                            <th scope="col">ВРЕМЯ СОЗДАНИЯ</th>
                            <th scope="col">ВРЕМЯ ПОСЛЕДНЕГО СООБЩЕНИЯ</th>
                            <th scope="col">СТАТУС</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? <Loader/> : data.map(item => <HistorySupportItem key={item.id} data={item} />)}
                    </tbody>
                </table>
            </div>
            {lastPage > 1 ?
                <div className='pagination-nav d-flex justify-content-end'>
                    {currentPage !== 1 ? 
                        <span
                        onClick={handlePrevBtn}
                        className='pagination-nav__circle'>
                            <i className="la la-angle-left" />
                        </span>
                        : ''
                    }
                    {pages.map(item =>  
                        <span 
                        onClick={handlePageBtn}
                        className={`${currentPage == item ? 'pagination-nav__circle--active' : ''} pagination-nav__circle`}>{item}</span>)}
                         {currentPage === lastPage ?
                         ''
                        : <span
                        onClick={handleNextBtn}
                        className='pagination-nav__circle'>
                            <i className="la la-angle-right" />
                        </span>
                    }
                </div> : ''
                    }
        </div>
    )
}

const mapStateToProps = state => ({
    lastPage: state.support.lastPage,
    currentPage: state.support.currentPage
})

const mapDispatchToProps = dispatch => {
    return{
        getHistorySupport: page => {
          dispatch(getHistorySupport(page))
        }
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistorySuport)