import React, { Fragment, useEffect} from 'react'
import {connect} from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import Loader from '../../Loader/Loader'
import { getWallets, getCards } from '../../../../redux/actions/actions'
import WaletSlide from '../../WaletSlide/WaletSlide'


const TopUp = ({wallets, loading, getWallets}) => {
    const back = localStorage.getItem('toBack')

    useEffect(() => {
        getCards({status: 2, sideFilter: 1})
        getWallets('USDT (ERC20),USDT (TRC20),ETH,BTC,FIAT RUB,FIAT USD', back)
    }, [])

    
    return(
        <Fragment>
            <div className='top-up-page'>
                <Carousel emulateTouch={true}>
                    {loading ? <Loader/> : wallets.map(item => <WaletSlide key={item.name} wallets={wallets} balance={item.name == 'ETH' || item.name == 'BTC' ? String(item.balance).slice(0, 12) : String(item.balance).slice(0, 5)} name={item.name} address={item.address} />)}
               </Carousel>
            </div>
        </Fragment>
        
       
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    toBack: state.wallets.toBack,
})

const mapDispatchToProps = dispatch => {
    return{
        getWallets: (names, back) => dispatch(getWallets(names, back)),
        getCards: (id, type) => dispatch(getCards(id, type)),
    }
 }

export default connect(mapStateToProps, mapDispatchToProps)(TopUp)