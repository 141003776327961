import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import ChangePasswordForm from '../../ChangePasswordForm/ChangePasswordForm'
import ContactsForm from '../../ContactsForm/ContactsForm'
import ProfileForm from '../../ProfileForm/ProfileForm'
import avatar from '../../../../images/rsi-imgs/ava.svg'
import { updateAvatar } from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import CardsPage from '../CardsPage/CardsPage'


const Profile = ({user, updateAvatar, loading}) => {
    const {verifyStatus, verifyComment} = user
    const handleChangeInput = e => {
        updateAvatar(e.target.files[0])
    }

    return(
        <Fragment>
            <div className="card p-4 mb-4">
                <div className="user-block d-flex mb-4">
                    <div>
                        <label 
                         style={{
                            backgroundImage: `url(${user.avatar == null ? avatar : user.avatar})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                          }}
                        htmlFor='avatar' 
                        className="avatar-pic mr-4 cursor-pointer">
                            <input 
                            onChange={handleChangeInput}
                            className='d-none' id="avatar" type="file" />
                            {/* <img src={user.avatar == null ? avatar : user.avatar} className="avatar-img" /> */}
                        </label>
                    </div>
                    <div className="user-info">
                         <ul className="user-info__list">
                             <li className="user-info__item">
                                 <span className="text-black mr-2">ID:</span>
                                 <span>{user.user_id}</span>
                             </li>
                             <li className="user-info__item">
                                 <span className="text-black mr-2">Статус:</span>
                                 <span>{user.verifyStatusText == null ? 'Не верифицирован' : user.verifyStatusText}</span>
                             </li>
                             <li className="user-info__item">
                                 <span className="text-black mr-2">Email:</span>
                                 <span>{user.email}</span>
                             </li>
                             <li className="user-info__item">
                                 <span className="text-black mr-2">Телефон:</span>
                                 <span>{user.phone}</span>
                             </li>
                             {
                                verifyStatus === 5 ?
                                <li className="user-info__item">
                                 <span className="text-black mr-2">Комментарий:</span>
                                 <span>{verifyComment}</span>
                                </li> :
                                ''
                             }
                         </ul>
                    </div>
                </div>
                {loading ? <Loader/> : <ProfileForm user={user}/>}
            </div>
            <div className="card p-4">
                {loading ? <Loader/> : <ContactsForm/>}
            </div>
            <div className="card p-4">
                <ChangePasswordForm/>
            </div>
            <CardsPage/>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.user.profile,
    loading: state.user.loading
})

const mapDispatchToProps = dispatch => {
    return{
        updateAvatar: file => {
            dispatch(updateAvatar(file))
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)