import React from 'react'
import { useSelector } from 'react-redux'
import { notifyInfo } from '../../../../utils/notify'

const RefPage = () => {
    const ref = useSelector(state => state.user.profile.ref)

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://new.rsi.capital/fast-launch/step-1?referral=${ref}`)
        notifyInfo('Реферальная ссылка скопирована')
    }

    return(
        <div className="card p-4">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, quam doloremque fuga impedit qui magni numquam eveniet obcaecati? A, nihil? Rem quibusdam delectus deserunt magnam veniam sit, maxime deleniti illum obcaecati voluptates dolorum cumque neque autem, nihil ab corrupti adipisci quia voluptatibus reiciendis? Consequuntur sint temporibus ea ad, molestiae cumque.</p>
            <div className="d-flex justify-content-end">
                <button 
                onClick={handleCopy}
                className="btn btn-primary">Скопировать реф. ссылку</button>
            </div>
        </div>
    )
}

export default RefPage