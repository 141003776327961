import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from '../jsx/components/pages/Home/Home'
import Exchange from '../jsx/components/pages/Exchange/Exchange'
import HistoryPage from '../jsx/components/pages/HistoryPage/HistoryPage'
import Output from '../jsx/components/pages/Output/Output'
import Staking from '../jsx/components/pages/Staking/Staking'
import Support from '../jsx/components/pages/Support/Support'
import TopUp from '../jsx/components/pages/TopUp/TopUp'
import Login from '../jsx/pages/Login'
import BuyCard from '../jsx/components/pages/BuyCard/BuyCard'
import TopupCash from '../jsx/components/pages/TopupCash/TopupCash'
import GetCash from '../jsx/components/pages/GetCash/GetCash'
import StakingLok from '../jsx/components/pages/StakingLok/StakingLok'
import RefPage from '../jsx/components/pages/RefPage/RefPage'
import Register from '../jsx/pages/Registration'
import ForgotPassword from '../jsx/pages/ForgotPassword'
import Profile from '../jsx/components/pages/Profile/Profile'
import VerifyPage from '../jsx/components/pages/VerifyPage/VerifyPage'
import OutputCard from '../jsx/components/pages/OutputCard/OutputCard'
import OutputCrypto from '../jsx/components/pages/OutputCrypto/OutputCrypto'
import CardsPage from '../jsx/components/pages/CardsPage/CardsPage'
import Transfer from '../jsx/components/pages/Transfer/Transfer'



export const useRoutes = isAuthenficated => {
    if (isAuthenficated) {
        return(
            <Switch>
                <Route
                    exact
                    component={Home}
                    title='Главная'
                    path='/'
                />
                <Route
                    exact
                    component={Exchange}
                    title='Обмен'
                    path='/exchange'
                />
                <Route
                    exact
                    component={TopUp}
                    title='Пополнение'
                    path='/topup'
                />
                <Route
                    exact
                    component={BuyCard}
                    title='Пополнение картой'
                    path='/topup-card:id'
                />
                <Route
                    exact
                    component={TopupCash}
                    title='Пополнение наличными'
                    path='/topup-cash:id'
                />
                <Route
                    exact
                    component={Output}
                    title='Вывод'
                    path='/output'
                />
                <Route
                    exact
                    component={Transfer}
                    title='Перевод'
                    path='/transfer'
                />
                <Route
                    component={HistoryPage}
                    title='История операций'
                    path='/address/history'
                />
                <Route
                    exact
                    component={Staking}
                    title='Стейкинг'
                    path='/staking'
                />
                <Route
                    exact
                    component={StakingLok}
                    title='Стейкинг'
                    path='/staking-lok'
                />
                <Route
                    exact
                    component={Support}
                    title='Поддержка'
                    path='/support'
                />
                <Route
                    exact
                    component={GetCash}
                    title='Получение наличных'
                    path='/get-cash'
                />
                <Route
                    exact
                    component={RefPage}
                    title='Реферальная программа'
                    path='/ref-page'
                />
                <Route
                    exact
                    component={Profile}
                    title='Реферальная программа'
                    path='/profile'
                />
                <Route
                    exact
                    component={VerifyPage}
                    title='Верефикация личности'
                    path='/verify'
                />
                <Route
                    exact
                    component={OutputCard}
                    title='Верефикация личности'
                    path='/output-card:id'
                />
                 <Route
                    exact
                    component={CardsPage}
                    title='Карты'
                    path='/cards-page'
                />
                <Route
                    exact
                    component={OutputCrypto}
                    title='Верефикация личности'
                    path='/output-crypto:id'
                />
                <Redirect 
                from="*" 
                to="/" 
                />
            </Switch>
        )
    }

    return(
        <Switch>
            <Route
                exact
                component={Login}
                path='/page-login'
            />
            <Route
                exact
                component={Register}
                path='/page-register'
            />
            <Route
                exact
                component={ForgotPassword}
                path='/forgot-password'
            />
            <Redirect 
                from="*" to="/page-login"
             />
        </Switch>
    )
}