import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { updateSocial } from '../../../redux/actions/actions'

const ContactsForm = ({user, updateSocial, errors}) => {
    const {handleSubmit, register} = useForm()
    const {vk, tg, sk, trading_view, fb} = user


    const onSubmit = data  => {
        for (let prop in data) {
            if(data[prop].length === 0) {
                delete data[prop]
            }
        }
        updateSocial(data)
    }
    
    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="card-title mb-2">Контактные данные</h4>
            <div className="form-row">
                <div className="form-group col-md-6 col-lg-3">
                    <label>Trading View</label>
                    <input
                     defaultValue={trading_view}
                    {...register('trading_view')} 
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                     {errors?.trading_view?.length ? 
                        errors.trading_view.map(item => <div className='text-danger small'>{item}</div>)
                     :
                     ''}
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Skype</label>
                    <input
                     defaultValue={sk} 
                     {...register('sk')} 
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                    {errors?.sk?.length ? 
                        errors.sk.map(item => <div className='text-danger small'>{item}</div>)
                     :
                     ''}
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Telegram</label>
                    <input
                     defaultValue={tg} 
                     {...register('tg')} 
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                     {errors?.tg?.length ? 
                        errors.tg.map(item => <div className='text-danger small'>{item}</div>)
                     :
                     ''}
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Вконтакте</label>
                    <input
                     defaultValue={vk} 
                     {...register('vk')} 
                    type="text" 
                    className="form-control"/>
                    {errors?.vk?.length ? 
                        errors.vk.map(item => <div className='text-danger small'>{item}</div>)
                     :
                     ''}
                </div>
                <div className="form-group col-md-6 col-lg-3">
                    <label>Facebook</label>
                    <input
                     defaultValue={fb} 
                     {...register('fb')} 
                    type="text" 
                    className="form-control"/>
                     {errors?.fb?.length ? 
                        errors.fb.map(item => <div className='text-danger small'>{item}</div>)
                     :
                     ''}
                </div>
            </div>
            <button className="btn btn-primary">Сохранить</button>
        </form>
    )
}

const mapStateToProps = state => ({
    user: state.user.profile,
    errors: state.user.updateSocialErrors
})

const mapDispatchToProps = dispatch => {
    return{
      updateSocial: params => {
        dispatch(updateSocial(params))
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsForm)