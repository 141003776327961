import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import OutputWalletItem from '../../OutputWalletItem/OutputWalletItem'
import {getWallets, commissions, send, setAddress} from '../../../../redux/actions/actions'
import Loader from '../../Loader/Loader'
import { Carousel } from 'react-responsive-carousel'
import InputMask from "react-input-mask"
import { useParams } from 'react-router-dom'
import { SEND_CLEAR, SEND_ERROR_CLEAR } from '../../../../redux/types'
import ConfirmForm from '../../ConfirmForm/ConfirmForm'
import SvgIcon from '../../SvgIcon/SvgIcon'

const OutputCrypto = ({wallets, loading, getWallets, commissions, send, sendErrors, sendFirst, snedData, setAddress, address}) => {
    const {id} = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        getWallets('USDT (ERC20),USDT (TRC20),BTC,ETH,USDR')
        dispatch({type: SEND_CLEAR})
        dispatch({type: SEND_ERROR_CLEAR})
        setAddress('')
    }, [])
    
    const handleChange = e => {
        dispatch({
            type: SEND_ERROR_CLEAR
        })
        if(e.target.value.length > 25) {
            commissions(e.target.value)
        }
        setAddress(e.target.value)
    }

    const handleSend = e => {
        let name = document.querySelector('li.selected .card').dataset.type
        let amount = document.querySelector('li.selected .sendAmount').value
        
        send(name, amount, address)
    }

    if(loading) {
        return <Loader/>
    }

    return(
        <div className='top-up-page'>
           <Carousel
           showArrows={false}
           emulateTouch={true}
           >
               {wallets.map(item => <OutputWalletItem key={item.name} selected={id}  wallets={wallets} balance={item.name == 'BTC' || item.name == 'ETH' ? String(item.balance).slice(0, 12) : String(item.balance).slice(0, 5)} name={item.name} address={item.address} />)}
           </Carousel>
           <div className="card p-4 mt-minus">
               <div className="h4 ta-l">Адрес перевода</div>
               <div className="form-group">
                    <InputMask
                    readOnly={sendFirst}
                    value={address}
                    id='toCryptoAddress'
                    onChange={handleChange}
                    type="text" 
                    className="form-control" 
                    placeholder=""/>
                    <div className="text-danger small ta-l">
                        <div>{sendErrors?.to?.map(item => item)}</div>
                        <div>{address ? 'Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от цены и обьема "газа". Окончательная цена будет скорректирована после совершения транзакции.' : ''}</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2 align-items-center">
                    <div onClick={() => window.history.back()} className="back-btn">
                        <SvgIcon size={30} id='BACK'/>
                    </div>
                    <button
                    disabled={sendFirst}
                    onClick={handleSend} 
                    className="btn btn-primary">Вывести средства</button>
                </div>
                <div>
                    {sendFirst ? <ConfirmForm data={snedData} /> : ''}
                </div>
                
           </div>
        </div>
    )
}

const mapStateToProps = state => ({
    wallets: state.wallets.wallets,
    loading: state.wallets.loading,
    sendErrors: state.user.sendErrors,
    sendFirst: state.user.sendFirst,
    snedData: state.user.snedData,
    address: state.wallets.address
})

const mapDispatchToProps = dispatch => {
    return{
        getWallets: (names) => {
            dispatch(getWallets(names))
        },
        commissions: address => {
            dispatch(commissions(address))
        },
        send: (name, amount, to) => {
            dispatch(send(name, amount, to))
        },
        setAddress: val => dispatch(setAddress(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutputCrypto)