import React from 'react'
import { connect } from 'react-redux'
import { deleteCard } from '../../../redux/actions/actions'
import Loader from '../Loader/Loader'

const CardsList = ({loading, cards, deleteCard}) => {
    const handleDeleteClick = e => {
        deleteCard(e.target.dataset.id)
    }

    if(!cards.length) {
        return 'У вас нет активных карт'
    }

    if(loading) {
        return <Loader/>
    }

    return(
        <ul className="cards-list">
            {cards.map(item => <li key={item.id} className="cards__item d-flex justify-content-between align-items-center">
                <div className="card__left">
                    <span className='text-primary fz-18 mr-2'>{item.bankText}</span>
                    <span>{`**** **** **** ${String(item.number).slice(String(item.number).length - 4, String(item.number).length)}`}</span>
                    <span className='card-status'>
                        <span className="status-desc small text-danger">{item.rejection_comment !== null ? item.rejection_comment : ''}</span>
                        <span className="status-text">{item.statusText}</span>
                    </span>
                </div>
                <div className="card__right">
                    <i 
                    onClick={handleDeleteClick}
                    data-id={item.id}
                    className="fa fa-trash delete-btn cursor-pointer"></i>
                </div>
            </li>)}
        </ul>
    )
}

const mapStateToProps = state => ({
    loading: state.wallets.cardsLoading,
    cards: state.wallets.cards,
})

const mapDispatchToProps = dispatch => {
    return{
        deleteCard: id => {
            dispatch(deleteCard(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsList)