import React from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { signupConfirm } from '../../../redux/actions/actions'


const ConfirmSignUp = ({params, signupData, signupConfirm}) => {
    const {handleSubmit, register} = useForm()

    const onSubmit = data  => {
        signupConfirm(data, params)
    }

    return(
        <form
        className='mt-2'
        onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                    {signupData?.username_phone != null ?
                        <div className="form-group col-sm-12 col-lg-12">
                            <label>Код из смс</label>
                            <input
                            required
                            {...register('code')} 
                            type="text" 
                            className="form-control" 
                            placeholder=""/>
                        </div> : ''
                    } 
                    
                    {signupData?.username != null ? 
                        <div className="form-group col-sm-12 col-lg-12">
                            <label>Код с почты</label>
                            <input
                            required
                            {...register('emailCode')} 
                            type="text" 
                            className="form-control" 
                            placeholder=""/>
                        </div> : ''
                    }
                    
             </div> 
             <div className="d-flex justify-content-end">
                <button 
                    className="btn btn-primary"
                >Подтвердить</button>
             </div>
        </form>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        signupConfirm: (data, params) => {
            dispatch(signupConfirm(data, params))
        }
    }
}

const mapStateToProps = state => ({
    signupData: state.user.signupData,
    params: state.user.params,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSignUp)